import { createMuiTheme } from '@material-ui/core';

import typography from './typography';
import overrides from './overrides';

const theme = palette =>
  createMuiTheme({
    palette,
    typography: typography(palette),
    overrides: overrides(palette),
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  });

export default theme;
