import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Power, PowerOff, Delete, Bolt, Edit } from '@material-ui/icons';
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { forceRunConnection } from '../../Services/ConnectionService';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const XERO = 'xero';

const ConnectionsTable = props => {
  const {
    className,
    connections,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    handleClickRun,
    handleClickChange,
    handleClickActivate,
    handleClickDeactivate,
    handleDelete,
    ...rest
  } = props;

  const classes = useStyles();

  const handleRunConnectionModule = async (connectionId) => {
    const payload = {
      connectionId,
      parameters: {},
    };
    try {
      await forceRunConnection(payload);
    } catch (e) {
      console.log(e);
    }
  };

  const renderActivationButtons = connection => {
    if (connection.active === true) {
      return (
        <TableCell>
          <Tooltip title="Deactivate Connection? (currently active)">
            <Link href="#" onClick={() => handleClickDeactivate(connection)} disabled={false}>
              <PowerOff />
            </Link>
          </Tooltip>
        </TableCell>
      );
    }
    return (
      <TableCell>
        <Tooltip title="Activate Connection? (currently inactive)">
          <Link href="#" onClick={() => handleClickActivate(connection)} disabled={false}>
            <Power />
          </Link>
        </Tooltip>
      </TableCell>
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="connections.connectionName" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.numberOfLogMessages" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.packages" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.organization" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.reseller" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.createdAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="button.change" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="button.run" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="button.activate" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="button.delete" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow className={classes.tableRow} key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  connections.map(connection => (
                    <TableRow className={classes.tableRow} hover key={connection.connectionId}>
                      <TableCell>
                        <Link href={`/connection/${connection.connectionId}`}>
                          {connection.connectionName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={connection.unresolvedLogsCount}
                          color={connection.unresolvedLogsCount > 0 ? 'primary' : 'default'}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        {connection.packages.map((p, index) => (
                          <Chip key={index} label={<FormattedMessage id={`packages.type.${p.packageType}`} />} />
                        ))}
                      </TableCell>
                      <TableCell>{connection.organization.organizationName}</TableCell>
                      <TableCell>
                        {connection.organization.reseller && connection.organization.reseller.organizationName}
                      </TableCell>
                      <TableCell>{moment(connection.createdAt).format('L')}</TableCell>
                      <TableCell>
                        <Link href="#" onClick={() => handleClickChange(connection)} disabled={false}>
                          <Edit />
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          href="#"
                          onClick={() => handleRunConnectionModule(connection.connectionId)}
                        >
                          <Bolt />
                        </Link>
                      </TableCell>
                      {renderActivationButtons(connection)}
                      <TableCell>
                        <Link href="#" onClick={() => handleDelete(connection)} disabled={false}>
                          <Delete />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          showFirstButton
          showLastButton
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

ConnectionsTable.propTypes = {
  className: PropTypes.string,
  connections: PropTypes.array.isRequired,
};

export default ConnectionsTable;
