import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import FormikModal from '../../../../components/FormikModal';
import { deleteAccount } from '../../Services/AccountService';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import { normalizeErrors } from '../../../../utils/dataAccess';
import DeleteAccountFrom from '../../Forms/DeleteAccountForm';

const DeleteAccount = ({ showDeleteAccountModal, setDeleteAccountModalOpen }) => {
  const { user } = useSelector(state => state.AppReducer);
  const dispatch = useDispatch();

  const [deleteAccountInitialValues] = useState({
    userId: user.userId,
    password: '',
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(),
  });

  const handleDelete = async (values, form) => {
    try {
      await deleteAccount(values);
      dispatch(AppActions.logout());
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);
        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);
      }
    }
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={deleteAccountInitialValues} onSubmit={handleDelete}>
      <FormikModal
        show={showDeleteAccountModal}
        onHide={() => setDeleteAccountModalOpen(false)}
        title={<FormattedMessage id="account.confirmDeleteTitle" />}
      >
        <FormattedMessage id="account.confirmDeleteMessage" />
        <DeleteAccountFrom />
      </FormikModal>
    </Formik>
  );
};

export default DeleteAccount;
