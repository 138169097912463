import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { isEmpty, startCase } from 'lodash';
import { Field, Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { INSURANCE_BRYDGE } from '../../OnboardingWizard/constants';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '35%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UpdateConnectionCredentialForm = ({ formRef, packageCredentials, loading, initialValues, onSubmit }) => {
  const classes = useStyles();
  const initialFormValues = isEmpty(initialValues) ? { credentials: {} } : initialValues;

  const gridItems = () => {
    const packageTypes = Object.keys(packageCredentials);
    return packageTypes.map(packageType => {
      return (
        <React.Fragment key={packageType}>
          <Box width="100%" display={packageType === INSURANCE_BRYDGE ? 'none' : 'block'}>
            <Grid item xs={12}>
              <Typography variant="h4">{startCase(packageType.replace('-', ' '))}</Typography>
              <Field
                color="secondary"
                component={TextField}
                select
                name={`credentials.${packageType}`}
                label={<FormattedMessage id="onboarding.credentials.credential" />}
                fullWidth
              >
                {packageCredentials[packageType].map(credential => (
                  <MenuItem key={credential.credentialId} value={credential.credentialId}>
                    {credential.name}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Box py={3}>
                <Divider />
              </Box>
            </Grid>
          </Box>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          {!loading && <LinearProgress />}
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialFormValues}>
              <Form ref={formRef}>
                <Grid container className={classes.root}>
                  <Grid item xs={7}>
                    {gridItems()}
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
};

UpdateConnectionCredentialForm.propTypes = {
  formRef: PropTypes.object.isRequired,
  packageCredentials: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

UpdateConnectionCredentialForm.defaultProps = {
  packageCredentials: {},
};

export default UpdateConnectionCredentialForm;
