import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { without, isEmpty } from 'lodash';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import OnboardingPackageSelectionForm from '../Forms/OnboardingPackageSelectionForm/OnboardingPackageSelectionForm';
import {
  getAvailablePackages,
  getOrganizations,
  getResellers,
  onboardNewOrganization,
} from '../../Services/OnboardingService';
import { NEW_ORGANIZATION_VALUE } from './constants';
import { normalizeErrors } from '../../../../utils/dataAccess';
import { actions as OnboardingActions } from '../../Ducks/Onboarding.duck';
import { getOrganization } from '../../../Organizations/Services/OrganizationService';

const OnboardingPackageSelectionStep = ({ formRef, onSubmit, initialValues }) => {
  const [loading, setLoading] = useState(true);
  const [resellers, setResellers] = useState(null);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [newOrganizationForm, setNewOrganizationForm] = useState(
    initialValues.organizationId === NEW_ORGANIZATION_VALUE,
  );
  const [availablePackages, setAvailablePackages] = useState(null);
  const [selectedPackages, setSelectedPackages] = useState(initialValues.packages ? initialValues.packages : []);
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.AppReducer);

  const loadResellers = async () => {
    const response = await getResellers();

    const resellersArray = response.data['hydra:member'];

    user.accessToOrganizations.forEach(orgAccess => {
      const { reseller } = orgAccess.organization;

      if (!reseller) {
        return;
      }

      const indexOfExistingReseller = resellersArray.findIndex(
        organization => organization.organizationId === reseller.organizationId,
      );

      if (indexOfExistingReseller === -1) {
        resellersArray.push(reseller);
      }
    });

    setResellers(resellersArray);
  };

  const loadAvailablePackages = async packages => {
    setLoading(true);
    const response = await getAvailablePackages(packages);
    setAvailablePackages(response.data['hydra:member']);
    setLoading(false);
  };

  const loadOrganizations = async resellerId => {
    setLoading(true);
    const response = await getOrganizations(resellerId);
    setOrganizations(response.data['hydra:member']);
    setLoading(false);
  };

  const setValidations = () => {
    setLoading(true);
    let initialSchema = Yup.object().shape({});
    const connectionNameValidation = Yup.object({
      connectionName: Yup.string()
        .trim()
        .strict()
        .required(),
    });
    initialSchema = initialSchema.concat(connectionNameValidation);
    const resellerValidation = Yup.object({
      resellerId: Yup.string()
        .ensure()
        .required('The field is required'),
    });
    initialSchema = initialSchema.concat(resellerValidation);
    const organizationIdValidation = Yup.object({
      organizationId: Yup.string()
        .ensure()
        .required(),
      organizationName: Yup.string()
        .trim()
        .strict()
        .when('organizationId', {
          is: NEW_ORGANIZATION_VALUE,
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
      organizationCode: Yup.number().when('organizationId', {
        is: NEW_ORGANIZATION_VALUE,
        then: Yup.number()
          .typeError('The value must be a number')
          .required(),
        otherwise: Yup.number().typeError('The value must be a number'),
      }),
    });
    initialSchema = initialSchema.concat(organizationIdValidation);
    const packageTypeValidation = Yup.object({
      hidden: Yup.number(),
      packages: Yup.string()
        .required('Please select at least two packages')
        .when('hidden', {
          is: x => x < 2,
          then: Yup.string()
            .required('Please select at least two packages')
            .matches(/(' {2}')/, { message: 'Please select at least two packages' }),
        }),
    });
    initialSchema = initialSchema.concat(packageTypeValidation);
    setValidationSchema(initialSchema);
    setLoading(false);
  };

  useEffect(() => {
    setValidations();
    if (!isEmpty(initialValues)) {
      setSelectedReseller(initialValues.resellerId);
    }
    loadResellers();
    loadAvailablePackages(selectedPackages);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (resellers && availablePackages) {
      setLoading(false);
    }
  }, [resellers, availablePackages]);

  useEffect(() => {
    if (selectedReseller) {
      loadOrganizations(selectedReseller.organizationId);
    }
  }, [selectedReseller]);

  useEffect(() => {
    loadAvailablePackages(selectedPackages);
  }, [selectedPackages]);

  const handleSelectReseller = resellerId => {
    if (resellerId.organizationId) {
      setSelectedReseller(resellerId);
    }
  };

  const handleSelectPackage = (packageType, setFieldValue) => {
    setFieldValue(`hidden`, selectedPackages.length + 1);
    dispatch(OnboardingActions.SetSelectedPackage(packageType));
    setSelectedPackages([...new Set([...selectedPackages, packageType])]);
  };

  const handleRemoveSelectedPackage = (packageType, setFieldValue) => {
    setFieldValue(`hidden`, selectedPackages.length - 1);
    setSelectedPackages(without(selectedPackages, packageType));
    dispatch(OnboardingActions.RemovePackage(packageType));
  };

  const onSelectOrganization = value => {
    if (value && value === NEW_ORGANIZATION_VALUE) {
      setNewOrganizationForm(true);
    } else {
      setNewOrganizationForm(false);
    }
  };

  const createOrganization = async (values, form) => {
    const organizationValues = {
      organizationId: v4(),
      organizationCode: values.organizationCode,
      organizationName: values.organizationName,
      resellerId: values.resellerId,
    };
    try {
      return await onboardNewOrganization(organizationValues);
    } catch (err) {
      if (err.response.status === 400) {
        const errors = normalizeErrors(err.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);
      }
    }
  };

  const onSubmitForm = async (values, form) => {
    values.packages = selectedPackages;
    dispatch(OnboardingActions.SetSelectedReseller(selectedReseller));
    values.resellerId = selectedReseller.organizationId;
    if (values.organizationId.organizationId) {
      dispatch(OnboardingActions.SetSelectedOrganization(values.organizationId));
      values.organizationId = values.organizationId.organizationId;
    }
    if (values.organizationId === NEW_ORGANIZATION_VALUE) {
      const response = await createOrganization(values, form);
      values.organizationId = response.data.organizationId;
      values.organizationCode = '';
      values.organizationName = '';
      const response2 = await getOrganization(response.data.organizationId);
      dispatch(OnboardingActions.SetSelectedOrganization(response2.data));
    }
    onSubmit(values);
  };

  return (
    <OnboardingPackageSelectionForm
      formRef={formRef}
      loading={loading}
      initialValues={initialValues}
      resellers={resellers}
      organizations={organizations}
      onSelectOrganization={onSelectOrganization}
      newOrganizationForm={newOrganizationForm}
      availablePackages={availablePackages}
      selectedPackages={selectedPackages}
      onSelectReseller={handleSelectReseller}
      onSelectPackage={handleSelectPackage}
      onRemoveSelectedPackage={handleRemoveSelectedPackage}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
    />
  );
};

OnboardingPackageSelectionStep.propTypes = {
  formRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OnboardingPackageSelectionStep;
