import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';

const MultiSelectFieldComponent = ({ packageType, disabled, field }) => {
  const { setFieldValue, values } = useFormikContext();
  const fieldValue = !isEmpty(values) ? values.settings[packageType][field.fieldName] : [];
  const orderDescending = "orderDescending" in field.fieldOptions ? field.fieldOptions.orderDescending : false;

  const fieldOptions = field.fieldOptions.options;
  const fieldOptionsKeys = orderDescending ? Object.keys(fieldOptions).sort().reverse() : Object.keys(fieldOptions);

  return (
    <Grid key={field.fieldName} item xs={12}>
      <Autocomplete
        multiple
        disabled={disabled}
        getOptionLabel={option => (option ? fieldOptions[option] : '')}
        options={fieldOptionsKeys}
        color="secondary"
        defaultValue={fieldValue}
        onChange={(event, newValue) => {
          setFieldValue(`settings.${packageType}.${field.fieldName}`, newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            color="secondary"
            fullWidth
            name={`onboarding.settings.${field.fieldName}`}
            label={<FormattedMessage id={`onboarding.settings.${field.fieldName}`} />}
          />
        )}
      />
    </Grid>
  );
};

export default MultiSelectFieldComponent;
