import React from 'react';
import { Field, Form, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  updatePasswordButton: {
    margin: theme.spacing(1),
  },
}));

const SettingsPasswordForm = () => {
  const classes = useStyles();
  const { isSubmitting, submitForm, isValid } = useFormikContext();

  return (
    <Form>
      <Card>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <Field
            required
            color="secondary"
            component={TextField}
            type="password"
            name="oldPassword"
            label={<FormattedMessage id="account.oldPassword" />}
            fullWidth
          />
          <Field
            required
            color="secondary"
            component={TextField}
            type="password"
            name="newPassword"
            label={<FormattedMessage id="account.newPassword" />}
            fullWidth
          />
          <Field
            required
            color="secondary"
            component={TextField}
            type="password"
            name="newPasswordConfirmation"
            label={<FormattedMessage id="account.newPasswordConfirmation" />}
            fullWidth
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            className={classes.updatePasswordButton}
            onClick={submitForm}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || !isValid}
          >
            <FormattedMessage id="button.updatePassword" />
          </Button>
        </Box>
      </Card>
    </Form>
  );
};

export default SettingsPasswordForm;
