import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { MenuItem } from '@material-ui/core';
import StringMapFieldComponent from '../StringMapFieldComponent';
import TagsFieldComponent from '../TagsFieldComponent';
import TableFieldComponent from '../TableFieldComponent';
import FormikTextField from '../../../../../components/FormikTextField';
import MultiSelectFieldComponent from '../MultiSelectFieldComponent';
import SearchableSelectFieldComponent from '../SearchableSelectFieldComponent';

const RenderFieldComponent = ({ path, field, packageType, disabled, usedValues = [] }) => {
  const orderDescending = "orderDescending" in field.fieldOptions ? field.fieldOptions.orderDescending : false;
  const options = field.fieldOptions?.options ? Object.keys(field.fieldOptions.options) : [];
  const sortedOptions = orderDescending ? options.sort().reverse() : options;

  if (field.fieldType === 'stringMap') {
    return (
      <StringMapFieldComponent
        path={path}
        key={field.fieldName}
        packageType={packageType}
        field={field}
        disabled={disabled || field.fieldOptions.disabled}
      />
    );
  }

  if (field.fieldType === 'tags') {
    return (
      <TagsFieldComponent
        path={path}
        key={field.fieldName}
        packageType={packageType}
        field={field}
        disabled={disabled || field.fieldOptions.disabled}
      />
    );
  }

  if (field.fieldType === 'multiSelect') {
    return (
      <MultiSelectFieldComponent
        path={path}
        key={field.fieldName}
        packageType={packageType}
        field={field}
        disabled={disabled || field.fieldOptions.disabled}
      />
    );
  }

  if (field.fieldType === 'searchableSelect') {
    return (
      <SearchableSelectFieldComponent
        path={path}
        key={field.fieldName}
        packageType={packageType}
        field={field}
        disabled={disabled || field.fieldOptions.disabled}
      />
    );
  }

  if (field.fieldType === 'table') {
    return (
      <TableFieldComponent
        path={path}
        key={field.fieldName}
        packageType={packageType}
        field={field}
        disabled={disabled || field.fieldOptions.disabled}
      />
    );
  }

  if (field.fieldType === 'select') {
    return (
      <Grid key={field.fieldName} item xs={12}>
        <Field
          color="secondary"
          component={TextField}
          name={path}
          label={<FormattedMessage id={`onboarding.settings.${field.fieldName}`} />}
          fullWidth
          select
          disabled={disabled || field.fieldOptions.disabled}
        >
          {sortedOptions.map(id => (
            <MenuItem key={id} value={id} disabled={usedValues.includes(id)}>
              {field.fieldOptions.options[id]}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    );
  }

  if (field.fieldType === 'selectWithTranslations') {
    return (
        <Grid key={field.fieldName} item xs={12}>
          <Field
              color="secondary"
              component={TextField}
              name={path}
              label={<FormattedMessage id={`onboarding.settings.${field.fieldName}`} />}
              fullWidth
              select
              disabled={disabled || field.fieldOptions.disabled}
          >
            {orderDescending ?
                Object.keys(field.fieldOptions.options).sort().reverse().map(id => (
                    <MenuItem key={id} value={id}>
                      <FormattedMessage id={`onboarding.settings.${field.fieldOptions.options[id]}`}/>
                    </MenuItem>
                )) :
                Object.keys(field.fieldOptions.options).map(id => (
                    <MenuItem key={id} value={id}>
                      <FormattedMessage id={`onboarding.settings.${field.fieldOptions.options[id]}`}/>
                    </MenuItem>
                ))
            }
          </Field>
        </Grid>
    );
  }

  const fieldType = field.fieldType === 'float' ? 'number' : field.fieldType;
  return (
    <Grid key={field.fieldName} item xs={12}>
      <FormikTextField
        disabled={disabled || field.fieldOptions.disabled}
        label={<FormattedMessage id={`onboarding.settings.${field.fieldName}`} />}
        name={path}
        fieldType={`${fieldType}`}
      />
    </Grid>
  );
};

export default RenderFieldComponent;
