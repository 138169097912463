import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import {
  deleteCredential,
  getCredential,
  getCredentialConnections,
  getCredentials,
} from '../Services/CredentialService';
import { getNewCredentialDetails } from '../../Connections/Services/OnboardingService';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  InitializeCredentialState: '[Credentials] Initialize',
  RequestData: '[Credentials] Request',
  FulfilledTable: '[Credentials] Fulfilled',
  SearchTable: '[Credentials] Search',
  ChangePage: '[Credentials] change page',
  SetPageSize: '[Credentials] set page size',
  SortTable: '[Credentials] Sort',
  ApplyFilter: '[Credentials] Apply filter',
  RemoveFilter: '[Credentials] Remove filter',
  SetCredentialId: '[Credentials] Set credential Id',
  RequestConnections: '[Credentials] Request connections',
  SetConnections: '[Credentials] Set connections',
  OauthStatus: '[Credentials] Oauth status',
  RemoveOauthStatus: '[Credentials] Remove Oauth status',
  RequestCredential: '[Credentials] Request credential id',
  FulfilledCredential: '[Credentials] Fulfilled new credential',
  SetSelectedPackage: '[Credentials] Set Selected Package',
  setSelectedCredentialType: '[Credentials] Set Selected credential type',
  RequestNewCredentialDetails: '[Credentials] Request new credential details',
  FulfilledNewCredentialDetails: '[Credentials] Fulfilled new credential details',
  SetSelectedRow: '[Credentials] Set selected row',
  DeleteOneCredential: '[Credentials] Delete a credential',
  ClearAll: '[Credentials] Clear all',
};

const initialState = {
  ...tableInitialState,
  credentialId: null,
  credentialAccess: null,
  loadedCredential: false,
  oauthStatus: null,
  credential: null,
  packageType: null,
  credentialType: null,
  credentialDetails: null,
  loading: false,
};

export const persistConfig = { storage, key: 'credential', whitelist: ['oauthStatus'] };

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.InitializeCredentialState: {
      return { ...initialState };
    }
    case actionTypes.SetCredentialId: {
      return {
        ...state,
        credentialId: action.credentialId,
        credentialAccess: null,
      };
    }
    case actionTypes.RequestCredential: {
      return {
        ...state,
      };
    }
    case actionTypes.FulfilledCredential: {
      return {
        ...state,
        credential: action.credential,
      };
    }
    case actionTypes.RequestNewCredentialDetails: {
      return {
        ...state,
      };
    }
    case actionTypes.FulfilledNewCredentialDetails: {
      return {
        ...state,
        loadedCredential: true,
        credentialDetails: action.credentialDetails,
      };
    }
    case actionTypes.SetSelectedPackage: {
      return {
        ...state,
        packageType: action.packageType,
      };
    }
    case actionTypes.setSelectedCredentialType: {
      return {
        ...state,
        credentialType: action.credentialType,
      };
    }
    case actionTypes.ClearAll: {
      return {
        ...state,
        packageType: null,
        credentialDetails: null,
      };
    }
    case actionTypes.RequestConnections: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.SetConnections: {
      return {
        ...state,
        credentialAccess: action.credentialAccess,
        loading: false,
      };
    }
    case actionTypes.OauthStatus: {
      const status = { ...state.oauthStatus };
      // eslint-disable-next-line array-callback-return
      Object.keys(action.status).map(key => {
        status[key] = action.status[key];
      });
      return {
        ...state,
        oauthStatus: status,
      };
    }
    case actionTypes.RemoveOauthStatus: {
      return {
        ...state,
        oauthStatus: {},
      };
    }
    case actionTypes.SetSelectedRow: {
      return {
        ...state,
        selectedRow: action.selectedRow,
      };
    }
    case actionTypes.DeleteOneCredential: {
      return { ...state };
    }
    default:
      return newState;
  }
});

export const actions = {
  ...tableActions(actionTypes),
  initializeCredentialsState: () => ({
    type: actionTypes.InitializeCredentialState,
  }),
  setCredentialId: credentialId => ({
    type: actionTypes.SetCredentialId,
    credentialId,
  }),
  requestCredential: oldCredential => ({
    type: actionTypes.RequestCredential,
    oldCredential,
  }),
  fulfilledCredential: credential => ({
    type: actionTypes.FulfilledCredential,
    credential,
  }),
  requestNewCredentialDetails: packageType => ({
    type: actionTypes.RequestNewCredentialDetails,
    packageType,
  }),
  fulfilledNewCredentialDetails: credentialDetails => ({
    type: actionTypes.FulfilledNewCredentialDetails,
    credentialDetails,
  }),
  setSelectedPackage: packageType => ({
    type: actionTypes.SetSelectedPackage,
    packageType,
  }),
  setSelectedCredentialType: credentialType => ({
    type: actionTypes.setSelectedCredentialType,
    credentialType,
  }),
  clearAll: () => ({
    type: actionTypes.ClearAll,
  }),
  requestConnections: credentialId => ({
    type: actionTypes.RequestConnections,
    credentialId,
  }),
  setConnections: credentialAccess => ({
    type: actionTypes.SetConnections,
    credentialAccess,
  }),
  oauthStatus: status => ({
    type: actionTypes.OauthStatus,
    status,
  }),
  removeOauthStatus: () => ({
    type: actionTypes.RemoveOauthStatus,
  }),
  deleteOneCredential: () => ({
    type: actionTypes.DeleteOneCredential,
  }),
};

function* fetchCredentialConnections() {
  const currentState = yield select(state => {
    return state.CredentialsReducer;
  });

  const response = yield getCredentialConnections(currentState.credentialId);
  yield put(actions.setConnections(response.data['hydra:member']));
}

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestCredential, function* requestCredentialSaga(action) {
    const response = yield getCredential(action.oldCredential.credentialId);
    yield put(actions.setSelectedPackage(action.oldCredential.packageType));
    yield put(actions.fulfilledCredential(response));
  });

  yield takeLatest(actionTypes.RequestNewCredentialDetails, function* requestNewCredentialDetailsSaga(action) {
    const response = yield getNewCredentialDetails(action.packageType);

    const allCredentialDetails = {};
    response.data['hydra:member'].forEach(item => {
      const { credentialType } = item;
      allCredentialDetails[credentialType] = item;
    });

    yield put(actions.fulfilledNewCredentialDetails(allCredentialDetails));
  });

  yield takeLatest(actionTypes.DeleteOneCredential, function* deleteOneCredential() {
    const currentState = yield select(state => {
      return state.CredentialsReducer;
    });

    let severity = MESSAGE_SEVERITY_SUCCESS;

    try {
      yield deleteCredential(currentState.selectedRow.credentialId);
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      message: 'deleteCredential',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
  });

  yield takeLatest(actionTypes.RequestData, function* requestEmailsSaga() {
    const currentState = yield select(state => {
      return state.CredentialsReducer;
    });

    const response = yield getCredentials(
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));

    yield takeLatest(actionTypes.RequestConnections, fetchCredentialConnections);
  });
}
