import { put, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { INSURANCE_BRYDGE } from '../Components/OnboardingWizard/constants';
import { getConnection } from '../Services/ConnectionService';
import { getAvailableCredentialForPackageType } from '../Services/OnboardingService';

export const actionTypes = {
  ResetData: '[Onboard] Reset connection',
  RequestData: '[Onboard] Request connection',
  FulfilledData: '[Onboard] Fulfilled connection',
  RequestAvailableCredentials: '[Onboard] Request available credentials',
  FulfilledAvailableCredentials: '[Onboard] Fulfilled available credentials',
  FulfilledAllCredentials: '[Onboard] Fulfilled all available credentials',
};

const initialState = {
  ...singleObjectInitialState,
  items: {},
  loadedCredential: false,
  loadedAllCredentials: false,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.FulfilledAvailableCredentials: {
      return {
        ...state,
        loading: false,
        loadedCredential: true,
        loadedAllCredentials: false,
        items: { ...state.items, ...action.items },
      };
    }
    case actionTypes.RequestAvailableCredentials: {
      return {
        ...state,
        loading: true,
        loadedCredential: false,
        loadedAllCredentials: false,
      };
    }
    case actionTypes.FulfilledAllCredentials: {
      return {
        ...state,
        loadedAllCredentials: true,
      };
    }
    case actionTypes.ResetData: {
      return {
        ...state,
        loading: false,
        loaded: false,
        item: {},
        items: {},
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  requestAvailableCredentials: connection => ({
    type: actionTypes.RequestAvailableCredentials,
    connection,
  }),
  fulfilledAvailableCredentials: items => ({
    type: actionTypes.FulfilledAvailableCredentials,
    items,
  }),
  fulfilledAllCredentials: () => ({ type: actionTypes.FulfilledAllCredentials }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestAvailableCredentials, function* requestAvailableCredentialsSaga(action) {
    const { packages } = action.connection;
    const { organizationId } = action.connection.organization;
    const packagesEntries = packages.entries();
    let packageDetails = packagesEntries.next().value;
    while (packageDetails !== undefined) {
      const packageType = packageDetails.pop();
      if (packageType.packageType !== INSURANCE_BRYDGE) {
        const response = yield getAvailableCredentialForPackageType(packageType.packageType, organizationId);
        yield put(
          actions.fulfilledAvailableCredentials({
            [packageType.packageType]: response.data['hydra:member'],
          }),
        );
      }
      packageDetails = packagesEntries.next().value;
    }
    yield put(actions.fulfilledAllCredentials());
  });

  yield takeLatest(actionTypes.RequestData, function* requestConnectionSaga(action) {
    const response = yield getConnection(action.itemId);
    yield put(actions.fulfilledData(response.data));
    yield put(actions.requestAvailableCredentials(response.data));
  });
}
