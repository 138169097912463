import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { customValidations } from './customValidations';

export const FieldAdapter = field => {
  let type = field.fieldType;
  if (type === 'checkbox') {
    type = 'boolean';
  }
  const conditiondBy = field.conditiondBy || null;
  const options = field.fieldOptions;

  function addConditionalOption(booleanField, initial) {
    return initial.when(booleanField, {
      is: true,
      then: initial.required(),
      otherwise: initial.notRequired(),
    });
  }

  function addConditionalOption2(booleanField, initial) {
    return initial.when(booleanField, { is: true, then: initial.min(3), otherwise: initial });
  }

  function addConditionalOption3(initial) {
    return initial.trim().strict();
  }

  function addOption(initial, option, parameter) {
    if ((parameter === undefined || parameter === '' || typeof parameter === 'boolean') && option !== 'default') {
      return initial[option]();
    }

    return initial[option](parameter);
  }

  function getInitialValue(value) {
    let defaultValue;

    if (!isEmpty(options)) {
      defaultValue = 'default' in options && typeof options.default !== 'undefined' ? options.default : value;
    }

    if (defaultValue === undefined) {
      console.log(`Add 'default' key for field '${field.fieldName}', in the fieldOptions`);
      // throw new Error(`Add 'default' key for field '${field.fieldName}', in the fieldOptions`);
    }

    return defaultValue;
  }

  function getValidation() {
    let validation;

    if (typeof customValidations[type] === 'function') {
      validation = customValidations[type](field);
    } else if (typeof Yup[type] === 'function') {
      validation = addOption(Yup, type);
    } else {
      throw new Error(`Validation for ${type} does not exist in Yup. It must be added as custom validation.`);
    }

    if (!isEmpty(options)) {
      if (options.hasOwnProperty('required') && options.required === false) {
        delete options.required;
      }
      Object.keys(options).forEach(key => {
        if (['required', 'default', 'nullable', 'min', 'max'].includes(key) && typeof validation[key] === 'function') {
          if (conditiondBy !== null && key === 'required') {
            validation = addConditionalOption(conditiondBy, validation, key);
          } else if (conditiondBy !== null && key === 'min') {
            validation = addConditionalOption2(conditiondBy, validation, key);
          } else {
            validation = addOption(validation, key, options[key]);
          }
          if (validation.type === 'string') {
            validation = addConditionalOption3(validation);
          }
        }
      });
      return validation;
    }
  }
  return {
    getValidation,
    getInitialValue,
  };
};
