import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import EditUserProfileForm from '../../Forms/EditUserProfileForm';
import { getUserWithToken } from '../../../App/Services/AppServices';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import { updateProfile } from '../../Services/AccountService';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const AccountProfileDetails = () => {
  const classes = useStyles();
  const { user, authToken } = useSelector(state => state.AppReducer);
  const dispatch = useDispatch();

  const [updateUserInitialValues] = useState({
    userId: user.userId,
    firstName: user.personName.firstName,
    middleName: user.personName.middleName,
    lastName: user.personName.lastName,
    emailAddress: user.emailAddress,
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .strict()
      .min(2)
      .max(50)
      .required(),
    lastName: Yup.string()
      .trim()
      .strict()
      .min(2)
      .max(50)
      .required(),
    middleName: Yup.string()
      .trim()
      .strict(),
    emailAddress: Yup.string()
      .email()
      .required(),
  });

  const reloadUserData = async () => {
    const response = await getUserWithToken(authToken, user.userId);
    dispatch(AppActions.setUser(response.data));
  };

  const handleUpdateUser = async values => {
    await updateProfile(values);

    await reloadUserData();
  };

  return (
    <div className={classes.root}>
      <Formik initialValues={updateUserInitialValues} validationSchema={validationSchema} onSubmit={handleUpdateUser}>
        <EditUserProfileForm />
      </Formik>
    </div>
  );
};

export default AccountProfileDetails;
