import { getStoredState, REHYDRATE } from 'redux-persist';
import store from '../store';

export default function crossBrowserListener(persistConfig) {
  return async function() {
    const state = await getStoredState(persistConfig);

    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
    });
  };
}
