import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import FormikModal from '../../../../components/FormikModal';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import { getUserWithToken } from '../../../App/Services/AppServices';
import DisableTwoFactorAuthenticationFrom from '../../Forms/TwoFactorAuthentication/DisableTwoFactorAuthenticationFrom';
import { disableTwoFactorAuthentication } from '../../Services/TwoFactorAuthenticatioinService';
import { normalizeErrors } from '../../../../utils/dataAccess';

const DisableTwoFactorAuthentication = ({ showTwoFactorAuthenticationModal, setTwoFactorAuthenticationModalOpen }) => {
  const { user, authToken } = useSelector(state => state.AppReducer);

  const dispatch = useDispatch();

  const reloadUserData = async () => {
    const response = await getUserWithToken(authToken, user.userId);
    dispatch(AppActions.setUser(response.data));
  };

  const [initialValues] = useState({
    userId: user.userId,
    password: '',
    code: '',
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(),
    code: Yup.string().required(),
  });

  const handleSubmit = async (values, form) => {
    try {
      await disableTwoFactorAuthentication(values);
      await reloadUserData();
      setTwoFactorAuthenticationModalOpen(false);
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);
        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);
      }
    }
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      <FormikModal
        show={showTwoFactorAuthenticationModal}
        onHide={() => setTwoFactorAuthenticationModalOpen(false)}
        title={<FormattedMessage id="account.disableTwoFactorAuthenticationTitle" />}
      >
        <FormattedMessage id="account.disableTwoFactorAuthenticationMessage" />
        <DisableTwoFactorAuthenticationFrom />
      </FormikModal>
    </Formik>
  );
};

DisableTwoFactorAuthentication.propTypes = {
  showTwoFactorAuthenticationModal: PropTypes.bool.isRequired,
  setTwoFactorAuthenticationModalOpen: PropTypes.func.isRequired,
};

export default DisableTwoFactorAuthentication;
