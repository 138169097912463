import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BatchesPage from './ProcessesPage';
import EventsPage from './EventsPage';

const InsuranceBrydgePages = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={EventsPage} exact path={`${path}/:connectionId/events`} />
      <Route component={BatchesPage} exact path={`${path}/:connectionId/batches`} />
    </Switch>
  );
};

export default InsuranceBrydgePages;
