import axios from 'axios';
import { isEmpty } from 'lodash';
import { INSURANCE_BRYDGE } from '../../../Connections/Components/OnboardingWizard/constants';

let getCredentialsToken;
export const getCredentials = async (page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getCredentialsToken !== typeof undefined) {
      getCredentialsToken.cancel('Operation canceled due to new request.');
    }

    getCredentialsToken = axios.CancelToken.source();

    return axios.get(`credentials`, {
      cancelToken: getCredentialsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getCredentialRequestsToken;
export const getCredentialRequests = async (page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getCredentialRequestsToken !== typeof undefined) {
      getCredentialRequestsToken.cancel('Operation canceled due to new request.');
    }

    getCredentialRequestsToken = axios.CancelToken.source();

    return axios.get(`credential-requests`, {
      cancelToken: getCredentialRequestsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const requestCredential = async values => {
  return axios.post('/credentials', { ...values });
};

const packagesWithoutAuthentication = ['emasphere'];

export const needsAuthentication = packageType => {
  return !packagesWithoutAuthentication.includes(packageType);
};

let getCredentialConnectionsToken;
export const getCredentialConnections = async credentialId => {
  try {
    if (typeof getCredentialConnectionsToken !== typeof undefined) {
      getCredentialConnectionsToken.cancel('Operation canceled due to new request.');
    }

    getCredentialConnectionsToken = axios.CancelToken.source();

    return axios.get(`connections`, {
      cancelToken: getCredentialConnectionsToken.token,
      params: {
        credentialId,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const createCredential = async ({
  credentialId,
  organizationId,
  package: { packageType, credentialType, credential, credentialRequest },
}) => {
  if (packageType === INSURANCE_BRYDGE) {
    credential = { name: '' };
  }

  const packageDetails = {
    packageType,
    credential,
    credentialType,
  };
  if (!isEmpty(credentialRequest)) {
    packageDetails.credentialRequest = credentialRequest;
  }
  return axios.post('/credentials', {
    organizationId,
    credentialId,
    package: packageDetails,
  });
};

export const getCredential = async credentialId => {
  return axios.get(`/credentials/${credentialId}`);
};

export const editCredential = async values => {
  return axios.put(`/credentials/${values.credentialId}`, { ...values });
};

export const deleteCredential = async credentialId => {
  return axios.put(`/credentials/${credentialId}/delete`, { credentialId });
};

export default {
  getCredentials,
  getCredentialRequests,
  requestCredential,
  getCredentialConnections,
  editCredential,
  getCredential,
  deleteCredential,
};
