import axios from 'axios';

let getOrganizationsToken;
export const getOrganizations = async (page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getOrganizationsToken !== typeof undefined) {
      getOrganizationsToken.cancel('Operation canceled due to new request.');
    }

    getOrganizationsToken = axios.CancelToken.source();

    return axios.get(`organizations`, {
      cancelToken: getOrganizationsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getOrganizationUsersToken;
export const getOrganizationUsers = async (organization, page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getOrganizationUsersToken !== typeof undefined) {
      getOrganizationUsersToken.cancel('Operation canceled due to new request.');
    }

    getOrganizationUsersToken = axios.CancelToken.source();

    return axios.get(`/organizations/${organization}/user-access`, {
      cancelToken: getOrganizationUsersToken.token,
      params: {
        organization,
        page,
        perPage,
        sortBy,
        filters,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const createOrganization = async values => {
  return axios.post('/organizations', { ...values });
};

export const editOrganization = async values => {
  return axios.put(`/organizations/${values.organizationId}`, { ...values });
};

export const deleteOrganizationRequest = async organizationId => {
  return axios.delete(`/organizations/${organizationId}`);
};

export const getOrganization = async organizationId => {
  return axios.get(`/organizations/${organizationId}`);
};

export default { getOrganizations, createOrganization, editOrganization, getOrganizationUsers };
