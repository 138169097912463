import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const UpdateEmailForm = ({ displayTwoFactorAuthenticationCodeField }) => (
  <Grid container>
    <Grid item xs={12}>
      <Field
        required
        color="secondary"
        component={TextField}
        name="oldEmailAddress"
        label={<FormattedMessage id="account.oldEmailAddress" />}
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        color="secondary"
        component={TextField}
        name="newEmailAddress"
        label={<FormattedMessage id="account.newEmailAddress" />}
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        color="secondary"
        component={TextField}
        name="newEmailAddressConfirmation"
        label={<FormattedMessage id="account.newEmailAddressConfirmation" />}
        fullWidth
      />
    </Grid>
    {displayTwoFactorAuthenticationCodeField && (
      <Grid item xs={12}>
        <Field
          required
          color="secondary"
          component={TextField}
          name="code"
          label={<FormattedMessage id="account.twoFactorAuthenticationCode" />}
          fullWidth
        />
      </Grid>
    )}
  </Grid>
);

UpdateEmailForm.propTypes = {
  displayTwoFactorAuthenticationCodeField: PropTypes.bool,
};

UpdateEmailForm.defaultProps = {
  displayTwoFactorAuthenticationCodeField: false,
};

export default UpdateEmailForm;
