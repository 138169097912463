import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { startCase, isEmpty } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Field, Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import { TextField } from 'formik-material-ui';
import MuiTextField from '@material-ui/core/TextField';
import { Autocomplete } from 'formik-material-ui-lab';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { NEW_ORGANIZATION_VALUE } from '../../OnboardingWizard/constants';
import FormikTextField from '../../../../../components/FormikTextField';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '35%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OnboardingPackageSelectionForm = ({
  formRef,
  loading,
  initialValues,
  resellers,
  organizations,
  newOrganizationForm,
  onSelectOrganization,
  availablePackages,
  selectedPackages,
  onSelectReseller,
  onSelectPackage,
  onRemoveSelectedPackage,
  onSubmit,
  validationSchema,
}) => {
  const classes = useStyles();

  const initialFormValues = isEmpty(initialValues)
    ? {
        connectionName: '',
        synchronizationStartDate: null,
        resellerId: '',
        organizationId: '',
        organizationName: '',
        organizationCode: '',
        packages: '',
        hidden: 0,
      }
    : initialValues;

  const hiddenField = () => {
    return (
      <Box display="none">
        <FormikTextField label="hidden" name="hidden" fieldType="hidden" />
      </Box>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form ref={formRef}>
            <Grid container className={classes.root}>
              <Grid item xs={12}>
                {loading && <LinearProgress />}
              </Grid>
              <Grid item xs={7}>
                <Field
                  color="secondary"
                  component={TextField}
                  name="connectionName"
                  label={<FormattedMessage id="onboarding.packageSelection.connectionName" />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={7}>
                <Field
                  color="secondary"
                  variant="inline"
                  InputAdornmentProps={{ position: 'start' }}
                  disableFuture
                  autoOk
                  component={KeyboardDatePicker}
                  format="yyyy-MM-dd"
                  name="synchronizationStartDate"
                  label={<FormattedMessage id="onboarding.packageSelection.synchronizationStartDate" />}
                  fullWidth
                />
              </Grid>

              <Grid item xs={7}>
                {resellers && (
                  <Field
                    name="resellerId"
                    color="secondary"
                    component={Autocomplete}
                    inputProps={{
                      onChange: values.resellerId ? onSelectReseller(values.resellerId) : '',
                    }}
                    disabled={!resellers || !isEmpty(values.organizationId)}
                    options={resellers}
                    getOptionLabel={option =>
                      option.organizationName ? `${option.organizationName} (${option.organizationCode})` : ''
                    }
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        error={Boolean(touched.resellerId && errors.resellerId)}
                        helperText={touched.resellerId && errors.resellerId}
                        name="oneResellerId"
                        label={<FormattedMessage id="organizations.reseller" />}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={7}>
                <Field
                  name="organizationId"
                  color="secondary"
                  component={Autocomplete}
                  inputProps={{
                    onChange: values.resellerId ? onSelectOrganization(values.organizationId) : '',
                  }}
                  disabled={!organizations || isEmpty(values.resellerId)}
                  options={organizations ? [NEW_ORGANIZATION_VALUE, ...organizations] : ''}
                  getOptionLabel={option =>
                    option.organizationName
                      ? `${option.organizationName} (${option.organizationCode})`
                      : option === NEW_ORGANIZATION_VALUE
                      ? 'Create new organization'
                      : ''
                  }
                  renderInput={params => (
                    <MuiTextField
                      {...params}
                      error={Boolean(touched.organizationId && errors.organizationId)}
                      helperText={touched.organizationId && errors.organizationId}
                      name="oneOrganization"
                      label={<FormattedMessage id="connections.organization" />}
                    />
                  )}
                />
              </Grid>

              {newOrganizationForm && (
                <>
                  <Grid item xs={7}>
                    <Box pl={3}>
                      <Field
                        color="secondary"
                        component={TextField}
                        name="organizationName"
                        label={<FormattedMessage id="organizations.organizationName" />}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box pl={3}>
                      <Field
                        color="secondary"
                        component={TextField}
                        name="organizationCode"
                        label={<FormattedMessage id="organizations.organizationCode" />}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </>
              )}
              {hiddenField()}
              <Grid item xs={7}>
                <Field
                  color="secondary"
                  component={TextField}
                  inputProps={{
                    onChange: e => {
                      onSelectPackage(e.target.value, setFieldValue);
                    },
                    value: '',
                  }}
                  select
                  name="packages"
                  label={<FormattedMessage id="onboarding.packageToConnect" />}
                  disabled={!availablePackages || !availablePackages.length}
                  fullWidth
                >
                  {availablePackages ? (
                    availablePackages.map(availablePackage => (
                      <MenuItem key={availablePackage.packageType} value={availablePackage.packageType}>
                        {startCase(availablePackage.packageType.replace('-', ' '))}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem />
                  )}
                </Field>
              </Grid>

              {selectedPackages && (
                <Grid item xs={7}>
                  <List>
                    {selectedPackages.map(selectedPackage => (
                      <ListItem key={selectedPackage}>
                        <ListItemText primary={startCase(selectedPackage.replace('-', ' '))} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => onRemoveSelectedPackage(selectedPackage, setFieldValue)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

OnboardingPackageSelectionForm.propTypes = {
  formRef: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  resellers: PropTypes.array,
  organizations: PropTypes.array,
  newOrganizationForm: PropTypes.bool,
  onSelectOrganization: PropTypes.func.isRequired,
  availablePackages: PropTypes.array,
  selectedPackages: PropTypes.array,
  onSelectReseller: PropTypes.func.isRequired,
  onSelectPackage: PropTypes.func.isRequired,
  onRemoveSelectedPackage: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default OnboardingPackageSelectionForm;
