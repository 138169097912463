import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { actions as CredentialActions } from '../../Ducks/Credentials.duck';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  buttonElement: {
    marginRight: theme.spacing(1),
  },
}));

const OauthCredentialResponsePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { credentialId, status } = useParams();
  const oauth = { [credentialId]: status };
  useEffect(() => {
    dispatch(CredentialActions.oauthStatus(oauth));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <FormattedMessage id={`credential.oauth.${status}`} />
    </div>
  );
};

export default OauthCredentialResponsePage;
