import { put, select, takeLatest, debounce } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';
import { deleteOrganizationRequest, getOrganizations, getOrganizationUsers } from '../Services/OrganizationService';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  InitOrganizationsPage: '[Organizations] Initialize',
  RequestData: '[Organizations] Request',
  FulfilledTable: '[Organizations] Fulfilled',
  SearchTable: '[Organizations] Search',
  ChangePage: '[Organizations] change page',
  SetPageSize: '[Organizations] set page size',
  SortTable: '[Organizations] Sort',
  ApplyFilter: '[Organizations] Apply filter',
  RemoveFilter: '[Organizations] Remove filter',
  SetOrganizationId: '[Organizations] Set organization ID',
  RequestOrganizationUsers: '[Organizations] Request organization users',
  FulfillOrganizationUsers: '[Organizations] Fulfill organization users',
  DeleteOrganization: '[Organizations] Delete organization',
};

const initialState = {
  ...tableInitialState,
  organizationId: null,
  organizationAccess: null,
  loadingOrganizationAccess: false,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.InitOrganizationsPage: {
      return { ...initialState };
    }
    case actionTypes.SetOrganizationId: {
      return {
        ...state,
        organizationId: action.organizationId,
        organizationAccess: null,
      };
    }
    case actionTypes.RequestOrganizationUsers: {
      return {
        ...state,
        loadingOrganizationAccess: true,
      };
    }
    case actionTypes.FulfillOrganizationUsers: {
      return {
        ...state,
        organizationAccess: action.organizationAccess,
        loadingOrganizationAccess: false,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initOrganizationsPage: () => ({
    type: actionTypes.InitOrganizationsPage,
  }),
  setOrganizationId: organizationId => ({
    type: actionTypes.SetOrganizationId,
    organizationId,
  }),
  getOrganizationUsers: organizationId => {
    return {
      type: actionTypes.RequestOrganizationUsers,
      organizationId,
    };
  },
  setOrganizationUsers: organizationAccess => {
    return {
      type: actionTypes.FulfillOrganizationUsers,
      organizationAccess,
    };
  },
  deleteOrganization: organizationId => {
    return {
      type: actionTypes.DeleteOrganization,
      organizationId,
    };
  },
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  function* deleteOrganization(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield deleteOrganizationRequest(action.organizationId);
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      message: 'deleteOrganization',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
  }

  function* fetchOrganizationUsers() {
    const currentState = yield select(state => {
      return state.OrganizationsReducer;
    });

    const response = yield getOrganizationUsers(currentState.organizationId);

    yield put(actions.setOrganizationUsers(response.data['hydra:member']));
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestEmailsSaga() {
    const currentState = yield select(state => {
      return state.OrganizationsReducer;
    });

    const response = yield getOrganizations(
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));

    yield takeLatest(actionTypes.RequestOrganizationUsers, fetchOrganizationUsers);
  });

  yield takeLatest(actionTypes.DeleteOrganization, deleteOrganization);
}
