import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RunDetailsPage from './RunDetailsPage';

const PaymentTogglePages = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={RunDetailsPage} exact path={`${path}/:connectionId/run-details`} />
    </Switch>
  );
};

export default PaymentTogglePages;
