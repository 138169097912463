import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  cellGrouping: {
    textTransform: 'capitalize',
    borderBottom: 'none',
    fontWeight: 'bold',
  },
  subElement: {
    paddingLeft: '40px',
  },
}));

const SyncStatusTable = props => {
  const { className, syncStatusReport, loading, isUserSuperAdmin, ...rest } = props;

  const classes = useStyles();

  function getItemsPerSyncStatus(collectionSyncStatusReport, syncStatus) {
    if (
      collectionSyncStatusReport &&
      collectionSyncStatusReport.hasOwnProperty('syncStatusReportItems') &&
      collectionSyncStatusReport.syncStatusReportItems.length > 0
    ) {
      for (let i = 0; i < collectionSyncStatusReport.syncStatusReportItems.length; i++) {
        if (collectionSyncStatusReport.syncStatusReportItems[i].syncStatus === syncStatus) {
          return collectionSyncStatusReport.syncStatusReportItems[i].numberOfSyncItems;
        }
      }
    }
    return 'no data';
  }

  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="connections.syncStatusTable.collectionName" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.syncStatusTable.pending" />
            </TableCell>
            {isUserSuperAdmin && (
              <TableCell>
                <FormattedMessage id="connections.syncStatusTable.processing" />
              </TableCell>
            )}
            <TableCell>
              <FormattedMessage id="connections.syncStatusTable.failed" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.syncStatusTable.complete" />
            </TableCell>
            {isUserSuperAdmin && (
              <TableCell>
                <FormattedMessage id="connections.syncStatusTable.ignored" />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(4)
              .fill(index => index)
              .map((value, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  {isUserSuperAdmin && (
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  )}
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  {isUserSuperAdmin && (
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          {!loading && Object.keys(syncStatusReport).length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No data found</TableCell>
            </TableRow>
          )}
          {!loading &&
            Object.keys(syncStatusReport).length > 0 &&
            syncStatusReport.hasOwnProperty('collectionElementSyncStatusReports') &&
            syncStatusReport.collectionElementSyncStatusReports.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>No data found</TableCell>
              </TableRow>
            )}
          {!loading &&
            Object.keys(syncStatusReport).length > 0 &&
            syncStatusReport.hasOwnProperty('collectionElementSyncStatusReports') &&
            syncStatusReport.collectionElementSyncStatusReports.length > 0 &&
            syncStatusReport.collectionElementSyncStatusReports.map(collectionElementSyncStatusReport => (
              <>
                <TableRow>
                  <TableCell className={classes.cellGrouping} color="secondary">
                    {collectionElementSyncStatusReport.elementName}
                  </TableCell>
                </TableRow>
                {Object.keys(collectionElementSyncStatusReport).length > 0 &&
                  collectionElementSyncStatusReport.hasOwnProperty('collectionSyncStatusReports') &&
                  collectionElementSyncStatusReport.collectionSyncStatusReports.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>No data found</TableCell>
                    </TableRow>
                  )}
                {Object.keys(collectionElementSyncStatusReport).length > 0 &&
                  collectionElementSyncStatusReport.hasOwnProperty('collectionSyncStatusReports') &&
                  collectionElementSyncStatusReport.collectionSyncStatusReports.length > 0 &&
                  collectionElementSyncStatusReport.collectionSyncStatusReports.map(collectionSyncStatusReport => (
                    <TableRow key={collectionSyncStatusReport.collectionName}>
                      <TableCell className={classes.subElement}>
                        <p>{collectionSyncStatusReport.collectionName}</p>
                      </TableCell>
                      <TableCell>
                        <p>{getItemsPerSyncStatus(collectionSyncStatusReport, 'pending')}</p>
                      </TableCell>
                      {isUserSuperAdmin && (
                        <TableCell>
                          <p>{getItemsPerSyncStatus(collectionSyncStatusReport, 'processing')}</p>
                        </TableCell>
                      )}
                      <TableCell>
                        <p>{getItemsPerSyncStatus(collectionSyncStatusReport, 'failed')}</p>
                      </TableCell>
                      <TableCell>
                        <p>{getItemsPerSyncStatus(collectionSyncStatusReport, 'complete')}</p>
                      </TableCell>
                      {isUserSuperAdmin && (
                        <TableCell>
                          <p>{getItemsPerSyncStatus(collectionSyncStatusReport, 'ignored')}</p>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </>
            ))}
        </TableBody>
      </Table>
    </>
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.center}>{table}</div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

SyncStatusTable.propTypes = {
  className: PropTypes.string,
  syncStatusReport: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  isUserSuperAdmin: PropTypes.bool.isRequired,
};

SyncStatusTable.defaultProps = {
  className: '',
  syncStatusReport: null,
};

export default SyncStatusTable;
