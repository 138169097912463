import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Skeleton from '@material-ui/lab/Skeleton';
import { actions as OrganizationsActions } from '../../Ducks/Organizations.duck';

const useStyles = makeStyles(theme => ({
  modal: {
    padding: theme.spacing(5),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(5),
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
  paddingTop: {
    paddingTop: theme.spacing(5),
  },
  stepContent: {
    padding: theme.spacing(3),
  },
}));

const OrganizationDetailsModal = props => {
  const { className, loading, show, hide, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { organizationId, organizationAccess, loadingOrganizationAccess } = useSelector(
    state => state.OrganizationsReducer,
  );

  useEffect(() => {
    if (organizationId) {
      dispatch(OrganizationsActions.getOrganizationUsers(organizationId));
    }
    // eslint-disable-next-line
  }, [organizationId]);

  const formatName = personName => {
    return [personName.firstName, personName.middleName, personName.lastName].filter(Boolean).join(' ');
  };

  const placeholderRowsCount = 10;

  return organizationAccess ? (
    <Dialog open={show} maxWidth="lg" fullWidth className={classes.modal}>
      <DialogTitle className={clsx(classes.paddingBottom)} disableTypography>
        <Typography variant="h5" color="primary">
          <FormattedMessage id="organizations.details" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="organizationAccess.organizationName" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="organizationAccess.personName" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="organizationAccess.userRoles" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="organizationAccess.enabled" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingOrganizationAccess &&
                      new Array(placeholderRowsCount)
                        .fill(index => index)
                        .map((value, index) => (
                          <TableRow className={classes.tableRow} key={index}>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>

                            <TableCell>
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                        ))}
                    {!loadingOrganizationAccess &&
                      organizationAccess.length > 0 &&
                      organizationAccess.map(access => (
                        <TableRow className={classes.tableRow} hover key={access['@id']}>
                          <TableCell>{access.organization.organizationName}</TableCell>
                          <TableCell>{formatName(access.user.personName)}</TableCell>
                          <TableCell>
                            {access.roles.map((role, index) => (
                              <Chip key={index} label={role} />
                            ))}
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id={`organizationAccess.enabled${access.enabled ? 'Yes' : 'No'}`} />
                          </TableCell>
                        </TableRow>
                      ))}
                    {!loadingOrganizationAccess && organizationAccess.length === 0 && (
                      <TableRow className={classes.tableRow}>
                        <TableCell colSpan={5}>
                          <FormattedMessage id="organizationAccess.noData" />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={hide} className={classes.button}>
          <FormattedMessage id="organizations.closeDetails" />
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default OrganizationDetailsModal;
