import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Skeleton from '@material-ui/lab/Skeleton';
import { actions as CredentialActions } from '../../Ducks/Credentials.duck';

const useStyles = makeStyles(theme => ({
  modal: {
    padding: theme.spacing(5),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(5),
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
  paddingTop: {
    paddingTop: theme.spacing(5),
  },
  stepContent: {
    padding: theme.spacing(3),
  },
}));

const CredentialConnectionsModal = props => {
  const { className, show, hide, history, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { credentialId, credentialAccess, loading } = useSelector(state => state.CredentialsReducer);

  useEffect(() => {
    if (credentialId) {
      dispatch(CredentialActions.requestConnections(credentialId));
    }
    // eslint-disable-next-line
  }, [credentialId]);

  const handleShowDetails = connectionId => {
    history.push(`/connection/${connectionId}`);
  };

  const placeholderRowsCount = 10;
  return (
    <Dialog open={show} maxWidth="lg" fullWidth className={classes.modal}>
      <DialogTitle className={clsx(classes.paddingBottom)} disableTypography>
        <Typography variant="h5" color="primary">
          <FormattedMessage id="organizations.details" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="connections.connectionName" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.packages" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.organization" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.reseller" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading &&
                      new Array(placeholderRowsCount)
                        .fill(index => index)
                        .map((value, index) => (
                          <TableRow className={classes.tableRow} key={index}>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                        ))}
                    {credentialAccess && credentialAccess.length
                      ? credentialAccess.map(access => (
                          <TableRow className={classes.tableRow} hover key={access['@id']}>
                            <TableCell>
                              <Link href="#" onClick={() => handleShowDetails(access.connectionId)}>
                                {access.connectionName}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {access.packages.map((p, index) => (
                                <Chip key={index} label={<FormattedMessage id={`packages.type.${p.packageType}`} />} />
                              ))}
                            </TableCell>
                            <TableCell>{access.organization.organizationName}</TableCell>
                            <TableCell>
                              {access.organization.reseller && access.organization.reseller.organizationName}
                            </TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={hide} className={classes.button}>
          <FormattedMessage id="organizations.closeDetails" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CredentialConnectionsModal;
