import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(2),
  },
}));

const DisableTwoFactorAuthenticationFrom = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Field
          className={classes.textField}
          required
          color="secondary"
          component={TextField}
          name="password"
          type="password"
          variant="outlined"
          label={<FormattedMessage id="account.password" />}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          className={classes.textField}
          required
          color="secondary"
          component={TextField}
          name="code"
          variant="outlined"
          label={<FormattedMessage id="signIn.code" />}
          fullWidth
          inputmode="numeric"
          pattern="[0-9]*"
          autocomplete="one-time-code"
        />
      </Grid>
    </Grid>
  );
};

export default DisableTwoFactorAuthenticationFrom;
