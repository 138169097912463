import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormattedMessage } from 'react-intl';
import OrganizationAutocompleteField from 'components/OrganizationAutocompleteField';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { isEmpty, startCase } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import FormikTextField from '../../../../components/FormikTextField/FormikTextField';

const CreateCredentialForm = ({
  loading,
  availablePackages,
  onSelectPackage,
  onSelectCredentialType,
  newCredentialForm,
  selectedPackageType,
  credentialDetails,
  selectedCredentialType,
}) => {
  const renderNewCredential = () => {
    if (!selectedPackageType || !selectedCredentialType || isEmpty(newCredentialForm)) {
      return '';
    }

    return selectedPackageType && !isEmpty(newCredentialForm) && newCredentialForm[selectedPackageType] ? (
      <Grid item xs={12}>
        {newCredentialForm[selectedPackageType].fields.map(field => (
          <Grid key={field.fieldName} item xs={12}>
            <FormikTextField
              label={<FormattedMessage id={`onboarding.credentials.new.${field.fieldName}`} />}
              name={`package.credential.${field.fieldName}`}
              fieldType={field.fieldType}
              fieldOptions={field.fieldOptions}
            />
          </Grid>
        ))}
      </Grid>
    ) : (
      ''
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        <Field
          color="secondary"
          component={OrganizationAutocompleteField}
          name="organizationId"
          label={<FormattedMessage id="credentials.organization" />}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          color="secondary"
          component={TextField}
          inputProps={{ onChange: e => onSelectPackage(e.target.value) }}
          select
          name="package.packageType"
          label={<FormattedMessage id="credentials.packageType" />}
          fullWidth
        >
          {availablePackages ? (
            availablePackages.map(availablePackage => (
              <MenuItem key={availablePackage.packageType} value={availablePackage.packageType}>
                {startCase(availablePackage.packageType.replace('-', ' '))}
              </MenuItem>
            ))
          ) : (
            <MenuItem />
          )}
        </Field>
      </Grid>
      {selectedPackageType && credentialDetails && (
        <Grid item xs={12}>
          <Field
            color="secondary"
            component={TextField}
            inputProps={{ onChange: e => onSelectCredentialType(e.target.value) }}
            select
            name="package.credentialType"
            label={<FormattedMessage id="credentials.credentialType" />}
            fullWidth
          >
            {Object.keys(credentialDetails).length > 0 ? (
              Object.keys(credentialDetails).map(credentialType => (
                <MenuItem key={credentialType} value={credentialType}>
                  {startCase(credentialType.replace('-', ' '))}
                </MenuItem>
              ))
            ) : (
              <MenuItem />
            )}
          </Field>
        </Grid>
      )}
      {renderNewCredential()}
    </Grid>
  );
};

CreateCredentialForm.propTypes = {
  availablePackages: PropTypes.array.isRequired,
  onSelectPackage: PropTypes.func.isRequired,
  onSelectCredentialType: PropTypes.func.isRequired,
  newCredentialForm: PropTypes.object.isRequired,
  credentialDetails: PropTypes.array.isRequired,
  selectedCredentialType: PropTypes.string.isRequired,
};

export default CreateCredentialForm;
