import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const WizardForm = ({ children, initialValues, validationSchema, onSubmit, submitDisabled, buttonTitle, onCancel }) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, submitForm }) => (
          <Form>
            {children}
            <div className={classes.buttonContainer}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || submitDisabled}
                  className={classes.button}
                  onClick={submitForm}
                >
                  {buttonTitle}
                </Button>

                {onCancel !== null && (
                  <Button variant="outlined" color="primary" onClick={onCancel} className={classes.button}>
                    <FormattedMessage id="button.cancel" />
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default WizardForm;

WizardForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  buttonTitle: PropTypes.string,
  onCancel: PropTypes.func,
};

WizardForm.defaultProps = {
  submitDisabled: false,
  buttonTitle: 'Next',
  onCancel: null,
};
