import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EventsTable from '../../Components/EventsTable';
import { actions as EventsActions } from '../../../../Ducks/Events.duck';
import EventsToolbar from '../../../../Components/EventsToolbar/EventsToolbar';
import ShowEventDetailsDialog from '../../Components/ShowEventDetailsDialog';
import { actions as ConnectionActions } from '../../../../Ducks/Connection.duck';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const EventsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { connectionId } = useParams();
  const [showEventDetails, setShowEventDetails] = useState(null);
  const [allEventsArchived, setAllEventsArchived] = useState(false);
  const [allEventsUnarchived, setAllEventsUnarchived] = useState(false);

  const {
    items,
    page,
    pageSize,
    totalCount,
    filters,
    selectedRowsIds,
    selectAll,
    archivedRows,
    unarchivedRows,
  } = useSelector(state => state.EventsReducer);
  const { loading, item: connection } = useSelector(state => state.ConnectionReducer);

  useEffect(() => {
    dispatch(EventsActions.clearEvents());
    dispatch(EventsActions.setConnectionId(connectionId));
    dispatch(EventsActions.setPageSize(100));
    dispatch(ConnectionActions.requestData(connectionId));
    dispatch(EventsActions.requestData());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (archivedRows > 0 && unarchivedRows === 0) {
      setAllEventsArchived(true);
      setAllEventsUnarchived(false);
    } else if (archivedRows === 0 && unarchivedRows > 0) {
      setAllEventsArchived(false);
      setAllEventsUnarchived(true);
    } else {
      setAllEventsArchived(false);
      setAllEventsUnarchived(false);
    }
  }, [archivedRows, unarchivedRows]);

  const handlePageChange = (event, newPage) => {
    dispatch(EventsActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(EventsActions.setPageSize(event.target.value));
  };

  const handleToggleEvent = event => {
    dispatch(EventsActions.toggleEvent(event));
  };

  const handleToggleAllEvents = events => {
    dispatch(EventsActions.toggleAllEvents(events));
  };

  const applyFilter = (key, value) => {
    dispatch(EventsActions.applyFilter(key, value));
  };

  const resetFilters = () => {
    dispatch(EventsActions.resetFilters());
  };

  const archiveEvents = () => {
    dispatch(EventsActions.archiveSelectedEvents());
  };

  const unArchiveEvents = () => {
    dispatch(EventsActions.unArchiveSelectedEvents());
  };

  const processEvents = () => {
    dispatch(EventsActions.processSelectedEvents());
  };

  const handleSearch = event => {
    dispatch(EventsActions.search(event.target.value));
  };

  const handleShowDetails = event => {
    setShowEventDetails(event);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h1">{!loading && connection.connectionName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <EventsToolbar
            connectionId={connectionId}
            handleSearch={handleSearch}
            filters={filters}
            resetFilters={resetFilters}
            applyFilter={applyFilter}
            archiveEvents={archiveEvents}
            unArchiveEvents={unArchiveEvents}
            processEvents={processEvents}
            hasSelectedItems={selectedRowsIds.length || selectAll}
            allEventsArchived={allEventsArchived}
            allEventsUnarchived={allEventsUnarchived}
          />
          <div className={classes.content} />
          <EventsTable
            events={items}
            page={page - 1}
            rowsPerPage={pageSize}
            totalCount={totalCount}
            loading={loading}
            selectAll={selectAll}
            selectedRowsIds={selectedRowsIds}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handleToggleEvent={handleToggleEvent}
            handleToggleAllEvents={handleToggleAllEvents}
            handleShowDetails={handleShowDetails}
          />
          {showEventDetails && (
            <ShowEventDetailsDialog onClose={() => setShowEventDetails(null)} event={showEventDetails} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default EventsPage;
