import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { Check, Clear, Error, ErrorOutlined, Info } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {},
  success: {
    color: theme.palette.success.main,
  },
  content: {
    padding: 0,
  },
  center: {
    verticalAlign: 'middle',
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const TasksTable = props => {
  const {
    className,
    tasks,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    disableCard,
    paging,
    modules,
    ...rest
  } = props;

  const [openLog, setOpenLog] = useState(false);

  const getModuleName = searchModuleId => {
    const module = modules.find(({ moduleId }) => searchModuleId === moduleId);

    if (module) {
      return <FormattedMessage id={module.moduleName} />;
    }

    return searchModuleId;
  };

  const classes = useStyles();
  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="connections.tasks.createdAt" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.tasks.status" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.tasks.module" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.tasks.startedAt" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.tasks.finishedAt" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(rowsPerPage)
              .fill(index => index)
              .map((value, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
          {!loading && tasks.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No tasks found</TableCell>
            </TableRow>
          )}
          {!loading &&
            tasks.length > 0 &&
            tasks.map(task => (
              <TableRow key={task.taskId}>
                <TableCell>
                  {task.createdAt && (
                    <>
                      <FormattedDate value={task.createdAt} />
                      &nbsp;
                      <FormattedTime value={task.createdAt} />
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <Grid container alignContent="center" spacing={1}>
                    <Grid item>
                      {task.taskStatus === 'pending' && <Info color="disabled" />}
                      {task.taskStatus === 'processing' && <Info color="secondary" />}
                      {task.taskStatus === 'complete' && <Check className={classes.success} />}
                      {task.taskStatus === 'queued' && <Info color="primary" />}
                      {task.taskStatus === 'cancelled' && <ErrorOutlined color="primary" />}
                      {task.taskStatus === 'failed' && <Error color="error" />}
                    </Grid>
                    <Grid item>{task.taskStatus}</Grid>
                  </Grid>
                </TableCell>
                <TableCell>{getModuleName(task.moduleId)}</TableCell>
                <TableCell>
                  {task.startedAt && (
                    <>
                      <FormattedDate value={task.startedAt} />
                      &nbsp;
                      <FormattedTime value={task.startedAt} />
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {task.finishedAt && (
                    <>
                      <FormattedDate value={task.finishedAt} />
                      &nbsp;
                      <FormattedTime value={task.finishedAt} />
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog open={Boolean(openLog)} onClose={() => setOpenLog(null)} maxWidth="md" fullWidth>
        {openLog && (
          <>
            <DialogTitle>
              <span>
                <FormattedMessage id={`packages.type.${openLog.packageType}`} />
              </span>
              <span>:&nbsp;</span>
              <span>{openLog.message}</span>
            </DialogTitle>
            <DialogContent>
              <Table>
                {openLog.packageType && (
                  <TableRow>
                    <TableCell>Package</TableCell>
                    <TableCell>
                      <FormattedMessage id={`packages.type.${openLog.packageType}`} />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Message</TableCell>
                  <TableCell>{openLog.message}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Logged at</TableCell>
                  <TableCell>
                    <FormattedDate value={openLog.createdAt} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Notified</TableCell>
                  <TableCell>
                    {openLog.notified && <Check />}
                    {!openLog.notified && <Clear />}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Archived</TableCell>
                  <TableCell>
                    {openLog.archived && <Check />}
                    {!openLog.archived && <Clear />}
                  </TableCell>
                </TableRow>
                {openLog.solution && (
                  <TableRow>
                    <TableCell>Probable solution</TableCell>
                    <TableCell>{openLog.solution}</TableCell>
                  </TableRow>
                )}
                {openLog.wiki && (
                  <TableRow>
                    <TableCell>Wiki</TableCell>
                    <TableCell>
                      <a href={openLog.wiki} target="_blank" rel="noopener noreferrer">
                        {openLog.wiki}
                      </a>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.internal && (
                  <TableRow>
                    <TableCell>Internal log</TableCell>
                    <TableCell>
                      <SyntaxHighlighter language="json" style={atomOneDark}>
                        {JSON.stringify(openLog.internal, null, 2)}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.request && (
                  <TableRow>
                    <TableCell>Request</TableCell>
                    <TableCell>
                      <SyntaxHighlighter language="json" style={atomOneDark}>
                        {JSON.stringify(openLog.request, null, 2)}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.response && (
                  <TableRow>
                    <TableCell>Response</TableCell>
                    <TableCell>
                      <SyntaxHighlighter language="json" style={atomOneDark}>
                        {JSON.stringify(openLog.response, null, 2)}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.extraData && (
                  <TableRow>
                    <TableCell>Extra data</TableCell>
                    <TableCell>
                      <SyntaxHighlighter language="json" style={atomOneDark}>
                        {JSON.stringify(openLog.extraData, null, 2)}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );

  if (disableCard) {
    return table;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.center}>{table}</div>
        </PerfectScrollbar>
      </CardContent>
      {paging && (
        <CardActions className={classes.actions}>
          <TablePagination
            showFirstButton
            showLastButton
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      )}
    </Card>
  );
};

TasksTable.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.bool,
  tasks: PropTypes.array.isRequired,
};

TasksTable.defaultProps = {
  paging: true,
  className: '',
};

export default TasksTable;
