import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { SearchInput } from 'components';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const CredentialRequestsToolbar = props => {
  const { className, handleClickAdd, handleSearch, intl, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <SearchInput
          className={classes.searchInput}
          onChange={handleSearch}
          placeholder={intl.formatMessage({ id: 'credentialRequests.search' })}
        />
      </div>
    </div>
  );
};

CredentialRequestsToolbar.propTypes = {
  className: PropTypes.string,
  handleClickAdd: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default injectIntl(CredentialRequestsToolbar);
