import { put, select, takeLatest } from 'redux-saga/effects';
import { getConnectionSyncStatusReport } from '../Services/ConnectionService';

export const actionTypes = {
  RequestData: '[SyncStatus] Request',
  SetSyncStatusReport: '[SyncStatus] Set syncStatus report',
  SetConnectionId: '[SyncStatus] Set connection Id',
  SetLoading: '[SyncStatus] Set loading',
};

const initialState = {
  syncStatusReport: {},
  loading: false,
  connectionId: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetConnectionId: {
      return {
        ...state,
        connectionId: action.connectionId,
      };
    }

    case actionTypes.RequestData: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.SetSyncStatusReport: {
      return {
        ...state,
        loading: false,
        syncStatusReport: action.syncStatusReport,
      };
    }

    case actionTypes.SetLoading: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),

  setSyncStatusReport: syncStatusReport => ({
    type: actionTypes.SetSyncStatusReport,
    syncStatusReport,
  }),

  requestData: () => ({
    type: actionTypes.RequestData,
  }),

  setLoading: loading => ({
    type: actionTypes.SetLoading,
    loading,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* request() {
    const currentState = yield select(state => {
      return state.SyncStatusReducer;
    });

    try {
      const response = yield getConnectionSyncStatusReport(currentState.connectionId);
      yield put(actions.setSyncStatusReport(response.data));
    } catch (err) {
      yield put(actions.setLoading(false));
    }
  });
}
