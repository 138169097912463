import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import OrganizationAutocompleteField
  from "../../../../components/OrganizationAutocompleteField/OrganizationAutocompleteField";
import {FormattedMessage} from "react-intl";

const AssignUsersToOrganizationsForm = () => (
  <Grid container>
    <Grid item xs={12}>
      <Field
        color="secondary"
        multiple={true}
        component={OrganizationAutocompleteField}
        name="organizations"
        label={<FormattedMessage id="organizations.reseller" />}
        fullWidth
      />
    </Grid>
  </Grid>
);

export default AssignUsersToOrganizationsForm;
