import React, { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';

const RouteWithLayout = props => {
  const { layout: Layout, secure, component: Component, ...rest } = props;
  const { loggedIn } = useSelector(state => state.AppReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!loggedIn && secure) {
      dispatch(AppActions.setInitialPath(location.pathname));
      history.push('/sign-in');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  if (!loggedIn && secure) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
