export const tableInitialState = {
  items: [],
  loading: false,
  loaded: false,
  page: 1,
  pageSize: 10,
  totalCount: 0,
  sortBy: [],
  filters: {},
  selectedRow: null,
};

export const tableAction = (actionTypes, state, action) => {
  switch (action.type) {
    case actionTypes.FulfilledTable: {
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.items,
        totalCount: action.totalCount,
      };
    }

    case actionTypes.RequestData: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.SearchTable: {
      return {
        ...state,
        page: 1,
        searchQuery: action.searchQuery,
      };
    }

    case actionTypes.ChangePage: {
      return {
        ...state,
        page: action.page,
      };
    }

    case actionTypes.SetPageSize: {
      return {
        ...state,
        page: 1,
        pageSize: action.pageSize,
      };
    }

    case actionTypes.SortTable: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }

    case actionTypes.ApplyFilter: {
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    }

    case actionTypes.RemoveFilter: {
      const filters = { ...state.filters };
      delete filters[action.key];

      return {
        ...state,
        filters,
      };
    }

    case actionTypes.SetSelectedRow: {
      return {
        ...state,
        selectedRow: action.selectedRow,
      };
    }

    default:
      return state;
  }
};

export const tableActions = actionTypes => ({
  applyFilter: (key, value) => ({ type: actionTypes.ApplyFilter, key, value }),
  removeFilter: key => ({ type: actionTypes.RemoveFilter, key }),
  setPageSize: pageSize => ({ type: actionTypes.SetPageSize, pageSize }),
  sortTable: sortBy => ({ type: actionTypes.SortTable, sortBy }),
  changePage: page => ({ type: actionTypes.ChangePage, page }),
  search: searchQuery => ({
    type: actionTypes.SearchTable,
    searchQuery,
  }),
  requestData: () => ({ type: actionTypes.RequestData }),
  fulfilled: (items, totalCount) => ({
    type: actionTypes.FulfilledTable,
    items,
    totalCount,
  }),
  setSelectedRow: selectedRow => ({ type: actionTypes.SetSelectedRow, selectedRow }),
});
