import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { ConfirmDialog } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const OrganizationsTable = props => {
  const {
    className,
    loading,
    page,
    totalCount,
    rowsPerPage,
    handleShowDetails,
    handlePageChange,
    handleRowsPerPageChange,
    handleClickChange,
    handleClickDelete,
    organizations,
    ...rest
  } = props;

  const classes = useStyles();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedDeleteOrganization, setSelectedDeleteOrganization] = useState(null);

  const handleDelete = organizationId => {
    setConfirmDeleteOpen(true);
    setSelectedDeleteOrganization(organizationId);
  };

  const deleteOrganization = () => {
    handleClickDelete(selectedDeleteOrganization);
  };

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="organizations.organizationCode" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="organizations.organizationName" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="organizations.reseller" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="organizations.createdAt" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="organizations.users" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="button.change" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="button.remove" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading &&
                    new Array(rowsPerPage)
                      .fill(index => index)
                      .map((value, index) => (
                        <TableRow className={classes.tableRow} key={index}>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      ))}
                  {!loading &&
                    organizations.map(organization => (
                      <TableRow className={classes.tableRow} hover key={organization.organizationId}>
                        <TableCell>{organization.organizationCode}</TableCell>
                        <TableCell>{organization.organizationName}</TableCell>
                        <TableCell>{organization.reseller ? organization.reseller.organizationName : ''}</TableCell>
                        <TableCell>{moment(organization.createdAt).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.buttonElement}
                            onClick={() => handleShowDetails(organization.organizationId)}
                          >
                            <FormattedMessage id="organizations.show" />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button color="primary" variant="contained" onClick={() => handleClickChange(organization)}>
                            <FormattedMessage id="button.change" />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleDelete(organization.organizationId)}
                          >
                            <FormattedMessage id="button.remove" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            showFirstButton
            showLastButton
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      </Card>

      <ConfirmDialog
        title={<FormattedMessage id="organizations.confirmDeleteTitle" />}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={deleteOrganization}
      >
        <FormattedMessage id="organizations.confirmDeleteMessage" />
      </ConfirmDialog>
    </>
  );
};

OrganizationsTable.propTypes = {
  className: PropTypes.string,
  organizations: PropTypes.array.isRequired,
};

export default OrganizationsTable;
