import axios from 'axios';

export const setupTwoFactorAuthentication = async values =>
  axios.put(`/users/${values.userId}/setup-two-factor`, { ...values });

export const enableTwoFactorAuthentication = async values =>
  axios.put(`/users/${values.userId}/enable-two-factor`, { ...values });

export const disableTwoFactorAuthentication = async values =>
  axios.put(`/users/${values.userId}/disable-two-factor`, { ...values });

export default { setupTwoFactorAuthentication, enableTwoFactorAuthentication, disableTwoFactorAuthentication };
