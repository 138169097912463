import React, { useEffect, useState } from 'react';
import { CardContent, MenuItem, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from '@mui/material';
import {
  getConnectionSyncElements,
  ignoreSyncElement,
  resetSyncElementSyncStatus,
  resetSyncElementFromSource,
} from '../../Services/ConnectionService';
import InsightTable from '../InsightTable';

const syncStatusArray = ['complete', 'pending', 'failed', 'ignored', 'processing', 'queued', 'cancelled'];

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  formControl: {
    minWidth: '150',
    marginBottom: '15px',
  },
  cardContent: {
    paddingTop: '0px',
  },
}));

const SyncElement = ({ syncElementName, connectionId, defaultSyncStatus, syncKeyToSearch, className }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [syncElements, setSyncElements] = useState([]);
  const [syncStatus, setSyncStatus] = useState(defaultSyncStatus || 0);
  const [syncKey, setSyncKey] = useState(syncKeyToSearch);

  const getSyncElements = async (newPage, newPageSize, newSyncStatus, syncKeySearch, useToken = true) => {
    setLoading(true);

    const syncStatusFilter = newSyncStatus || null;
    const syncKeySearchFilter = syncKeySearch || null;
    const response = await getConnectionSyncElements(
      connectionId,
      syncElementName,
      newPage,
      newPageSize,
      syncStatusFilter,
      syncKeySearchFilter,
      useToken,
    );
    setSyncElements(response.data['hydra:member']);
    setTotalCount(response.data['hydra:totalItems']);

    setLoading(false);
  };

  useEffect(() => {
    getSyncElements(page, pageSize, syncStatus, syncKey, false);
  }, []);

  const handlePageChange = async (event, newPage) => {
    setPage(newPage + 1);
    await getSyncElements(newPage + 1, pageSize, syncStatus, syncKey);
  };

  const handleRowsPerPageChange = async event => {
    setPageSize(event.target.value);
    await getSyncElements(page, event.target.value, syncStatus, syncKey);
  };

  const handleChangeSyncStatus = async event => {
    const newSyncStatus = event.target.value;
    setSyncStatus(newSyncStatus);

    await getSyncElements(page, pageSize, newSyncStatus, syncKey);
  };

  const handleClickResetSyncElementSyncStatus = async syncElementKey => {
    await resetSyncElementSyncStatus(connectionId, syncElementName, syncElementKey);
    await getSyncElements(page, pageSize, syncStatus, syncKey);
  };
  const handleClickResetSyncElementFromSource = async syncElementKey => {
    await resetSyncElementFromSource(connectionId, syncElementName, syncElementKey);
    await getSyncElements(page, pageSize, syncStatus, syncKey);
  };
  const handleClickIgnoreSyncElement = async syncElementKey => {
    await ignoreSyncElement(connectionId, syncElementName, syncElementKey);
    await getSyncElements(page, pageSize, syncStatus, syncKey);
  };

  const handleSearchSyncElement = async event => {
    setSyncKey(event.target.value);
    await getSyncElements(page, pageSize, syncStatus, event.target.value);
  };

  return (
    <div className={className}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={<span style={{ textTransform: 'capitalize' }}>{syncElementName}</span>} />
            <CardContent className={classes.cardContent}>
              <Grid container xs={1} spacing={1} className={classes.formControl}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>SyncStatus</InputLabel>
                    <Select value={syncStatus} onChange={handleChangeSyncStatus}>
                      <MenuItem value="0">All</MenuItem>
                      {syncStatusArray.map(status => (
                        <MenuItem key={status} value={status} className={classes.capitalize}>
                          <span className={classes.capitalize}>{status}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Search"
                    variant="standard"
                    fullWidth
                    value={syncKey}
                    onChange={handleSearchSyncElement}
                  />
                </Grid>
              </Grid>
              <InsightTable
                syncElements={syncElements}
                loading={loading}
                paging
                page={page - 1}
                rowsPerPage={pageSize}
                totalCount={totalCount}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleClickResetSyncElementSyncStatus={handleClickResetSyncElementSyncStatus}
                handleClickResetSyncElementFromSource={handleClickResetSyncElementFromSource}
                handleClickIgnoreSyncElement={handleClickIgnoreSyncElement}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SyncElement;
