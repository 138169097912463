import get from 'lodash/get';

export function formatName(person) {
  return [
    get(person, 'initials'),
    get(person, 'firstName'),
    get(person, 'middleName'),
    get(person, 'lastNamePrefix'),
    get(person, 'lastName'),
  ].filter(Boolean).join(' ');
}
