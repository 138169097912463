import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AppActions } from 'Domain/App/Ducks/App.duck';

const ConfirmDialog = props => {
  const { title, children, open, setOpen, onConfirm } = props;
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.AppReducer);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)} color="default">
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(AppActions.setLoading(true));
            setOpen(false);
            onConfirm();
          }}
          disabled={loading}
          color="secondary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmDialog;
