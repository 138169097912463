import axios from 'axios';

export const updateProfile = async values => axios.put(`/profile/user/${values.userId}`, { ...values });

export const updatePassword = async values => axios.put(`/profile/user/${values.userId}/password`, { ...values });

export const updateEmail = async values => axios.put(`/profile/user/${values.userId}/email`, { ...values });

export const deleteAccount = async values => axios.put(`/profile/user/${values.userId}/delete`, { ...values });

export default { updateProfile, updatePassword, updateEmail, deleteAccount };
