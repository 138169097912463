import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import { PROCESS_RESULTS } from '../../Pages/ProcessesPage/constants';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  buttonElement: {
    marginRight: theme.spacing(1),
  },
  filterDropdown: {
    minWidth: '150px',
  },
}));

const ProcessesToolbar = props => {
  const { className, connectionId, applyFilter, ...rest } = props;

  const classes = useStyles();
  const backLink = `/connection/${connectionId}`;
  const [result, setResult] = useState([]);

  const changeResult = event => {
    setResult(event.target.value);
    applyFilter('batchStatus', event.target.value ? event.target.value : undefined);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Button color="primary" variant="contained" className={classes.buttonElement} component={Link} to={backLink}>
          <FormattedMessage id="connection.processes.back" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonElement}
          component={Link}
          to={`/connection/insurance-brydge/${connectionId}/events`}
        >
          <FormattedMessage id="connectionDetails.events" />
        </Button>
        <FormControl className={classes.filterDropdown}>
          <InputLabel>
            <FormattedMessage id="connection.processes.result" />
          </InputLabel>
          <Select
            multiple
            value={result}
            onChange={changeResult}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip
                    key={value}
                    label={<FormattedMessage id={`connection.processes.result.${value}`} />}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
          >
            {PROCESS_RESULTS.map(processResult => (
              <MenuItem key={processResult} value={processResult}>
                <FormattedMessage id={`connection.processes.result.${processResult}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

ProcessesToolbar.propTypes = {
  className: PropTypes.string,
};

export default injectIntl(ProcessesToolbar);
