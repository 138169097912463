import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';
import { getConnectionLogs } from '../Services/ConnectionService';

export const actionTypes = {
  RequestData: '[LogEntries] Request',
  FulfilledTable: '[LogEntries] Fulfilled',
  SearchTable: '[LogEntries] Search',
  ChangePage: '[LogEntries] change page',
  SetPageSize: '[LogEntries] set page size',
  SortTable: '[LogEntries] Sort',
  ApplyFilter: '[LogEntries] Apply filter',
  RemoveFilter: '[LogEntries] Remove filter',
  SetConnectionId: '[LogEntries] Set connection Id',
  // CheckCanDownloadMissingDetails: '[LogEntries] Can download missing details',
  // SetCanDownload: '[LogEntries] Set can download',
  ToggleLog: '[LogEntries] Toggle Log',
  ToggleAllLogs: '[LogEntries] Toggle all logs',
};

const initialState = {
  ...tableInitialState,
  connectionId: null,
  canDownload: false,
  checkingDownload: true,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.SetConnectionId: {
      return {
        ...state,
        connectionId: action.connectionId,
      };
    }
    // case actionTypes.SetCanDownload: {
    //   return {
    //     ...state,
    //     canDownload: action.canDownload,
    //     checkingDownload: false,
    //   };
    // }
    case actionTypes.ToggleLog: {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.mongoId === action.event.mongoId) {
            item.selectedRow = !item.selectedRow;
          }
          return item;
        }),
      };
    }
    case actionTypes.ToggleAllLogs: {
      const isSelectAll = action.events.filter(item => !item.selectedRow).length;
      return {
        ...state,
        items: action.events.map(item => {
          item.selectedRow = !!isSelectAll;
          return item;
        }),
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
  // checkCanDownloadMissingDetails: () => ({
  //   type: actionTypes.CheckCanDownloadMissingDetails,
  // }),
  // setCanDownload: canDownload => ({
  //   type: actionTypes.SetCanDownload,
  //   canDownload,
  // }),
  toggleLog: log => {
    return {
      type: actionTypes.ToggleLog,
      event: log,
    };
  },
  toggleAllLogs: logs => ({
    type: actionTypes.ToggleAllLogs,
    events: logs,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  // yield takeLatest(actionTypes.ApplyFilter, reloadData);
  // yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestData, function* request() {
    const currentState = yield select(state => {
      return state.LogEntriesReducer;
    });

    const response = yield getConnectionLogs(
      currentState.connectionId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });

  // yield takeLatest(actionTypes.CheckCanDownloadMissingDetails, function* checkCanDownloadMissingDetails() {
  //   // eslint-disable-next-line no-unused-vars
  //   const currentState = yield select(state => {
  //     return state.LogEntriesReducer;
  //   });
  //   // const response = yield checkDownload(currentState.connectionId);
  //   yield put(actions.setCanDownload(false));
  // });
}
