import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatName } from '../../../../../../utils/nameFormatter';
import eventDetailsExtractor from '../../../../../../utils/eventDetailsExtractor';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableRow: {
    cursor: 'pointer',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const EventsTable = props => {
  const {
    className,
    events,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    selectAll,
    selectedRowsIds,
    handleToggleEvent,
    handleToggleAllEvents,
    handleShowDetails,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormHelperText>
                      <FormattedMessage id="events.selectAll" />
                    </FormHelperText>
                    <Checkbox
                      onClick={() => handleToggleAllEvents(events)}
                      checked={
                        (events.filter(item => selectedRowsIds.includes(item.mongoId)).length === events.length &&
                          events.length !== 0) ||
                        selectAll === true
                      }
                      indeterminate={
                        events.filter(item => selectedRowsIds.includes(item.mongoId)).length !== events.length &&
                        events.filter(item => selectedRowsIds.includes(item.mongoId)).length !== 0
                      }
                      disabled={events.length === 0}
                      inputProps={{ 'aria-label': 'all items selected' }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.type" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.details" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.employee" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.archived" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.createdAt" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow className={classes.tableRow} key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  events.map(event => (
                    <TableRow className={classes.tableRow} hover key={event.mongoId}>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={selectedRowsIds.includes(event.mongoId)}
                          onChange={() => handleToggleEvent(event)}
                        />
                      </TableCell>
                      <TableCell onClick={() => handleShowDetails(event)}>{event['@type']}</TableCell>
                      <TableCell>{eventDetailsExtractor(event)}</TableCell>
                      <TableCell>{formatName(event.hrEmployee.personName)}</TableCell>
                      <TableCell>
                        {event.archived ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}
                      </TableCell>
                      <TableCell>{moment(event.eventDate).format('L')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          showFirstButton
          showLastButton
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[100, 500, 1000]}
        />
      </CardActions>
    </Card>
  );
};

EventsTable.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array.isRequired,
};

export default EventsTable;
