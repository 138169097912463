import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';

import { FormattedMessage } from 'react-intl';
// import { Security } from '@material-ui/icons';
import { VerifiedUser } from '@material-ui/icons';
import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    // {
    //   title: <FormattedMessage id="menu.dashboard" />,
    //   href: '/dashboard',
    //   icon: <DashboardIcon />,
    // },
    {
      title: <FormattedMessage id="menu.connections" />,
      href: '/connection',
      exact: true,
      icon: <SettingsInputComponentIcon />,
    },
    {
      title: <FormattedMessage id="menu.credentials" />,
      href: '/credential',
      exact: true,
      icon: <VerifiedUser />,
    },
    // {
    //   title: <FormattedMessage id="menu.credentialsRequests" />,
    //   href: '/credential/requests',
    //   icon: <Security />,
    // },
    {
      divider: true,
    },
    {
      title: <FormattedMessage id="menu.user" />,
      href: '/users',
      icon: <PeopleIcon />,
    },
    {
      title: <FormattedMessage id="menu.organizations" />,
      href: '/organization',
      icon: <BusinessIcon />,
    },
    {
      title: <FormattedMessage id="menu.account" />,
      href: '/account',
      icon: <AccountBoxIcon />,
    },
    // {
    //   title: 'Products',
    //   href: '/products',
    //   icon: <ShoppingBasketIcon />
    // },
    // {
    //   title: 'Authentication',
    //   href: '/sign-in',
    //   icon: <LockOpenIcon />
    // },
    // {
    //   title: 'Typography',
    //   href: '/typography',
    //   icon: <TextFieldsIcon />
    // },
    // {
    //   title: 'Icons',
    //   href: '/icons',
    //   icon: <ImageIcon />
    // },
    // {
    //   title: 'Account',
    //   href: '/account',
    //   icon: <AccountBoxIcon />
    // },
    // {
    //   title: 'Settings',
    //   href: '/settings',
    //   icon: <SettingsIcon />
    // }
  ];

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
