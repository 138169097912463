import React, { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { NavLink as RouterLink, useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Button, CardContent } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { actions as ConnectionActions } from '../../../../Ducks/Connection.duck';
import {
  downloadInitialRunReport,
  downloadReversalRunReport,
  getRunReports,
  reverseInitialRun,
  startInitialRun,
} from '../../Service/RunService';
import RunsTable from '../../Components/RunsTable';
import { actions as AppActions } from '../../../../../App/Ducks/App.duck';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../../../../common/constants';
import { ConfirmDialog } from '../../../../../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  syncElement: {
    marginTop: theme.spacing(2),
  },
  buttonElement: {
    marginRight: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={index} {...other}>
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const RunDetailsPage = () => {
  const classes = useStyles();
  const { connectionId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading: loadingConnection, item: connection } = useSelector(state => state.ConnectionReducer);

  const [loadingRunReports, setLoadingRunReports] = useState(false);
  const [runReports, setRunReports] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);

  const [confirmStartInitialRunModal, setConfirmStartInitialRunModal] = useState(false);
  const [confirmReverseInitialRunModal, setConfirmReverseInitialRunModal] = useState(false);
  const [initialRunIdToReverse, setInitialRunIdToReverse] = useState(false);

  const getPaymentToggleRunReport = async (requestPage, RequestPageSize) => {
    setLoadingRunReports(true);

    const response = await getRunReports(connectionId, requestPage, RequestPageSize);
    setRunReports(response.data['hydra:member']);
    setTotalCount(response.data['hydra:totalItems']);

    setLoadingRunReports(false);
  };

  const startPaymentToggleInitialRun = async () => {
    try {
      await startInitialRun(connectionId);

      await getPaymentToggleRunReport(1, 5);

      dispatch(
        AppActions.displayMessage({
          message: 'connections.paymentToggle.initialRun.start',
          severity: MESSAGE_SEVERITY_SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        AppActions.displayMessage({
          message: 'connections.paymentToggle.initialRun.start',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  };

  const onCLickDownloadInitialRun = async initialRunId => {
    try {
      const response = await downloadInitialRunReport(connectionId, initialRunId);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', response.headers['x-filename']);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(
        AppActions.displayMessage({
          message: 'connections.paymentToggle.download',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  };

  const onCLickDownloadReversalRun = async reversalRunId => {
    try {
      const response = await downloadReversalRunReport(connectionId, reversalRunId);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', response.headers['x-filename']);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(
        AppActions.displayMessage({
          message: 'connections.paymentToggle.download',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  };

  const startPaymentToggleReversalRun = async () => {
    try {
      await reverseInitialRun(connectionId, initialRunIdToReverse);

      await getPaymentToggleRunReport(1, 5);

      dispatch(
        AppActions.displayMessage({
          message: 'connections.paymentToggle.initialRun.reverse',
          severity: MESSAGE_SEVERITY_SUCCESS,
        }),
      );
    } catch (e) {
      dispatch(
        AppActions.displayMessage({
          message: 'connections.paymentToggle.initialRun.reverse',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(ConnectionActions.requestData(connectionId));
  }, [dispatch]);

  useEffect(() => {
    if (connection !== undefined && Object.keys(connection).length === 0 && !loadingConnection) {
      history.push('/not-found');
    }

    if (connection !== undefined && Object.keys(connection).length > 0 && !loadingConnection) {
      getPaymentToggleRunReport(page, pageSize);
    }
  }, [loadingConnection, connection]);

  const handlePageChange = async (event, newPage) => {
    setPage(newPage + 1);
    await getPaymentToggleRunReport(newPage + 1, pageSize);
  };

  const handleRowsPerPageChange = async event => {
    setPageSize(event.target.value);
    await getPaymentToggleRunReport(page, event.target.value);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ display: 'inline-block' }}>
      <RouterLink {...props} />
    </div>
  ));

  const handleClickStartInitialRun = async () => {
    setConfirmStartInitialRunModal(true);
  };

  const handleClickStartReversalRun = async reversalRunId => {
    setInitialRunIdToReverse(reversalRunId);

    setConfirmReverseInitialRunModal(true);
  };

  const handleClickRefresh = async () => {
    await getPaymentToggleRunReport(1, 5);
  };

  return (
    <div className={classes.root}>
      {!loadingConnection && connection !== undefined && Object.keys(connection).length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">{!loadingConnection && connection.connectionName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonElement}
              component={CustomRouterLink}
              to={`/connection/${connectionId}`}
            >
              <FormattedMessage id="button.overview" />
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonElement}
              onClick={handleClickStartInitialRun}
            >
              <FormattedMessage id="button.paymentToggle.initialRun.start" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={<FormattedMessage id="connections.paymentToggle.runDetails.title" />}
                action={
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleClickRefresh}
                      className={classes.buttonElement}
                      disabled={loadingRunReports}
                    >
                      <FormattedMessage id="button.paymentToggle.refresh" />
                    </Button>
                  </>
                }
              />

              <CardContent>
                <RunsTable
                  connectionId={connectionId}
                  runs={runReports}
                  onCLickDownloadInitialRun={onCLickDownloadInitialRun}
                  onCLickDownloadReversalRun={onCLickDownloadReversalRun}
                  handleClickStartReversalRun={handleClickStartReversalRun}
                  loading={loadingRunReports}
                  paging
                  page={page - 1}
                  rowsPerPage={pageSize}
                  totalCount={totalCount}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {confirmStartInitialRunModal && (
        <ConfirmDialog
          title={<FormattedMessage id="connections.paymentToggle.initialRun.confirmStartInitialRunTitle" />}
          open={confirmStartInitialRunModal}
          setOpen={setConfirmStartInitialRunModal}
          onConfirm={startPaymentToggleInitialRun}
        >
          <FormattedMessage id="connections.paymentToggle.initialRun.confirmStartInitialRunMessage" />
        </ConfirmDialog>
      )}
      {confirmReverseInitialRunModal && (
        <ConfirmDialog
          title={<FormattedMessage id="connections.paymentToggle.initialRun.confirmReverseInitialRunTitle" />}
          open={confirmReverseInitialRunModal}
          setOpen={setConfirmReverseInitialRunModal}
          onConfirm={startPaymentToggleReversalRun}
        >
          <FormattedMessage id="connections.paymentToggle.initialRun.confirmReverseInitialRunMessage" />
        </ConfirmDialog>
      )}
    </div>
  );
};

export default RunDetailsPage;
