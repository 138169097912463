import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RequestResetPasswordPage from './RequestResetPasswordPage';
import VerifyResetPasswordPage from './VerifyResetPasswordPage';

const ResetPasswordPages = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={VerifyResetPasswordPage} exact path={`${path}/verify/:token`} />
      <Route component={RequestResetPasswordPage} exact path="" />
    </Switch>
  );
};

export default ResetPasswordPages;
