import { actions as AppActions } from '../Domain/App/Ducks/App.duck';

const setupAxios = (axios, store) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers['Content-Type'] = 'application/ld+json';
  const { dispatch } = store;

  axios.interceptors.request.use(
    config => {
      dispatch(AppActions.setLoading(true));
      const {
        AppReducer: { authToken },
      } = store.getState();

      if (authToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => {
      dispatch(AppActions.setLoading(true));
      return Promise.reject(err);
    },
  );

  axios.interceptors.response.use(
    response => {
      dispatch(AppActions.setLoading(false));
      return response;
    },
    error => {
      const { response } = error;
      dispatch(AppActions.setLoading(false));
      if (response && response.status === 401) {
        dispatch(AppActions.logout());
        // prevent response from being thrown as exception further
        return new Promise(() => {});
      }

      return Promise.reject(error);
    },
  );
};

export default setupAxios;
