import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { v4 } from 'uuid';
import { isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationsToolbar, OrganizationsTable } from '../../Components';
import FormikModal from '../../../../components/FormikModal';
import CreateOrganizationForm from '../../Forms/CreateOrganizationForm';
import { createOrganization, editOrganization } from '../../Services/OrganizationService';
import { normalizeErrors } from '../../../../utils/dataAccess';
import { actions as OrganizationsActions } from '../../Ducks/Organizations.duck';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../../common/constants';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import OrganizationDetailsModal from '../../Components/OrganizationDetailsModal';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const OrganizationManagementPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [createOrganizationModalOpen, setCreateOrganizationModalOpen] = useState(false);
  const [editOrganizationModalOpen, setEditOrganizationModalOpen] = useState(false);
  const [editOrganizationInitialValues, setEditOrganizationInitialValues] = useState({});
  const [detailsVisible, setDetailsVisible] = useState(false);
  const { loading, items, page, pageSize, totalCount } = useSelector(state => state.OrganizationsReducer);
  const [newOrganizationInitialValues, setNewOrganizationInitialValues] = useState({});

  useEffect(() => {
    dispatch(OrganizationsActions.initOrganizationsPage());
    dispatch(OrganizationsActions.requestData());
  }, [dispatch]);

  const handleSearch = event => {
    dispatch(OrganizationsActions.search(event.target.value));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(OrganizationsActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(OrganizationsActions.setPageSize(event.target.value));
  };

  const handleCreateOrganization = async (values, form) => {
    try {
      await createOrganization(values);
      setCreateOrganizationModalOpen(false);
      dispatch(OrganizationsActions.requestData());
    } catch (e) {
      if (e.response && e.response.status === 400) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }

      throw e;
    }
  };

  const handleClickEdit = organization => {
    setEditOrganizationInitialValues({
      organizationId: organization.organizationId,
      organizationName: organization.organizationName,
      resellerId: organization.reseller
        ? {
            organizationId: organization.reseller.organizationId,
            label: `${organization.reseller.organizationName} (${organization.reseller.organizationCode})`,
          }
        : '',
    });
    setEditOrganizationModalOpen(true);
  };

  const handleEditOrganization = async (values, form) => {
    let messageSeverity = MESSAGE_SEVERITY_SUCCESS;
    try {
      values.resellerId = isObject(values.resellerId) ? values.resellerId.organizationId : values.resellerId;
      await editOrganization(values);
      setEditOrganizationModalOpen(false);
      dispatch(OrganizationsActions.requestData());
    } catch (e) {
      if (e.response && e.response.status === 400) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }
      messageSeverity = MESSAGE_SEVERITY_ERROR;

      throw e;
    }

    dispatch(
      AppActions.displayMessage({
        message: 'editOrganization',
        severity: messageSeverity,
      }),
    );
  };

  const handleShowDetails = organizationId => {
    dispatch(OrganizationsActions.setOrganizationId(organizationId));
    setDetailsVisible(true);
  };

  const handleClickDelete = organizationId => {
    dispatch(OrganizationsActions.deleteOrganization(organizationId));
  };

  const handleHideDetails = () => {
    setDetailsVisible(false);
    dispatch(OrganizationsActions.setOrganizationId(null));
  };

  const handleNewOrganizationModalOpen = () => {
    setNewOrganizationInitialValues({
      organizationId: v4(),
      organizationCode: '',
      organizationName: '',
      resellerId: '',
    });
    setCreateOrganizationModalOpen(true);
  };

  return (
    <div className={classes.root}>
      <OrganizationsToolbar handleClickAddOrganization={handleNewOrganizationModalOpen} handleSearch={handleSearch} />
      <div className={classes.content}>
        <OrganizationsTable
          handleShowDetails={handleShowDetails}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handleClickChange={handleClickEdit}
          handleClickDelete={handleClickDelete}
          organizations={items}
          page={page - 1}
          rowsPerPage={pageSize}
          totalCount={totalCount}
          loading={loading}
        />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={newOrganizationInitialValues}
        onSubmit={handleCreateOrganization}
      >
        <FormikModal
          show={createOrganizationModalOpen}
          onHide={() => setCreateOrganizationModalOpen(false)}
          title={<FormattedMessage id="organizations.create" />}
        >
          <CreateOrganizationForm isCreateOrganization />
        </FormikModal>
      </Formik>

      {editOrganizationModalOpen && (
        <Formik initialValues={editOrganizationInitialValues} onSubmit={handleEditOrganization}>
          <FormikModal
            show={editOrganizationModalOpen}
            onHide={() => setEditOrganizationModalOpen(false)}
            title={<FormattedMessage id="organizations.update" />}
          >
            <CreateOrganizationForm initialValues={editOrganizationInitialValues} />
          </FormikModal>
        </Formik>
      )}
      <OrganizationDetailsModal show={detailsVisible} hide={handleHideDetails} />
    </div>
  );
};

export default OrganizationManagementPage;
