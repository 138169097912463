import React, { useState, createRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Typography, Link } from '@material-ui/core';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { TextField } from 'formik-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import { normalizeErrors } from '../../utils/dataAccess';
import { getUserWithToken, login, verifyToken } from '../../Domain/App/Services/AppServices';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignIn = props => {
  const { history } = props;
  const dispatch = useDispatch();
  const initialPath = useSelector(state => state.AppReducer.initialPath);
  const [showTwoFactorForm, setShowTwoFactorForm] = useState(false);
  const [authToken, setAuthToken] = useState();
  const recaptchaRef = createRef();

  const classes = useStyles();

  const handleLogin = async (values, form) => {
    try {
      let responseCode = null;
      let responseMessage = null;
      let apiToken = null;
      let authenticatedUserId = null;

      if (showTwoFactorForm && values.code) {
        const {
          data: { code, message, token, userId },
        } = await verifyToken(authToken, values);
        apiToken = token;
        responseCode = code;
        responseMessage = message;
        authenticatedUserId = userId;
      } else {
        const recaptchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        const {
          data: { code, message, token, userId, twoFactorNeeded },
        } = await login(values, recaptchaToken);

        if (twoFactorNeeded) {
          setShowTwoFactorForm(true);
          setAuthToken(token);

          return;
        }

        apiToken = token;
        responseCode = code;
        responseMessage = message;
        authenticatedUserId = userId;
      }

      if (responseCode === 401) {
        form.setStatus(responseMessage);
      } else {
        const { data: user } = await getUserWithToken(apiToken, authenticatedUserId);
        dispatch(AppActions.login(apiToken, '', user));
        history.push(initialPath);
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }
      throw e;
    }
  };

  let validation = Yup.object().shape({
    password: Yup.string()
      .max(50)
      .required(),
    email: Yup.string()
      .email()
      .required(),
  });

  if (showTwoFactorForm) {
    validation = validation.concat(
      Yup.object({
        code: Yup.number().required(),
      }),
    );
  }

  return (
    <div className={classes.root}>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        size="invisible"
        ref={recaptchaRef}
        badge="bottomright"
      />

      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote} />
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            {/* <div className={classes.contentHeader}> */}
            {/*  <IconButton onClick={handleBack}> */}
            {/*    <ArrowBackIcon /> */}
            {/*  </IconButton> */}
            {/* </div> */}
            <div className={classes.contentBody}>
              <Formik
                initialValues={{ email: '', password: '', code: '' }}
                validationSchema={validation}
                onSubmit={handleLogin}
              >
                {({ isValid, status, isSubmitting }) => (
                  <Form className={classes.form}>
                    <Typography className={classes.title} variant="h2">
                      <FormattedMessage id="signIn.title" />
                    </Typography>
                    {status && <Alert severity="error">{status}</Alert>}
                    <Field
                      className={classes.textField}
                      required
                      type="email"
                      color="secondary"
                      component={TextField}
                      name="email"
                      variant="outlined"
                      label={<FormattedMessage id="signIn.email" />}
                      fullWidth
                    />
                    <Field
                      className={classes.textField}
                      required
                      type="password"
                      color="secondary"
                      component={TextField}
                      name="password"
                      variant="outlined"
                      label={<FormattedMessage id="signIn.password" />}
                      fullWidth
                    />
                    {showTwoFactorForm && (
                      <Field
                        className={classes.textField}
                        required
                        type="number"
                        color="secondary"
                        component={TextField}
                        name="code"
                        variant="outlined"
                        label={<FormattedMessage id="signIn.code" />}
                        fullWidth
                        inputmode="numeric"
                        pattern="[0-9]*"
                        autocomplete="one-time-code"
                      />
                    )}
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign in now
                    </Button>
                    <Typography color="textSecondary" variant="body1">
                      <Link component={RouterLink} to="/reset-password" variant="h6">
                        <FormattedMessage id="signIn.forgetPassword.Button" />
                      </Link>
                    </Typography>
                    {/* <Typography color="textSecondary" variant="body1"> */}
                    {/*  Don't have an account?{' '} */}
                    {/*  <Link component={RouterLink} to="/sign-up" variant="h6"> */}
                    {/*    Sign up */}
                    {/*  </Link> */}
                    {/* </Typography> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignIn);
