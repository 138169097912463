import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrganizationManagementPage from './OrganizationManagementPage';

const OrganizationPages = () => {
  return (
    <Switch>
      <Route component={OrganizationManagementPage} exact path="" />
    </Switch>
  );
};

export default OrganizationPages;
