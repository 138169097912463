const getValidationMessages = messages => ({
  mixed: {
    required: messages['validationMessage.mixed.required'],
  },
  string: {
    min: messages['validationMessage.string.min'],
    max: messages['validationMessage.string.max'],
    email: messages['validationMessage.string.email'],
    trim: messages['validationMessage.string.trim'],
  },
});

export default getValidationMessages;
