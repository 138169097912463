import React from 'react';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';

const DeleteAccountFrom = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Field
          required
          color="secondary"
          component={TextField}
          type="password"
          name="password"
          label={<FormattedMessage id="account.password" />}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default DeleteAccountFrom;
