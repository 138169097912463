import React from 'react';
import { Form, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  modal: {
    padding: theme.spacing(5),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(5),
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
  paddingTop: {
    paddingTop: theme.spacing(5),
  },
}));

const FormikModal = ({ show, title, onHide, maxWidth, children, showActions, actions }) => {
  const classes = useStyles();
  const { isSubmitting, submitForm, isValid, status } = useFormikContext();

  return (
    <Dialog open={show} onExit={onHide} maxWidth={maxWidth} fullWidth className={classes.modal}>
      <Form>
        <DialogTitle className={clsx(classes.paddingBottom)} disableTypography>
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
        </DialogTitle>

        <DialogContent>
          {status && (
            <Alert severity="warning" className={classes.marginBottom}>
              {status}
            </Alert>
          )}
          {children}
        </DialogContent>
        {showActions &&
          (actions || (
            <DialogActions className={clsx(classes.paddingTop)}>
              <Button variant="outlined" color="primary" onClick={onHide}>
                <FormattedMessage id="button.cancel" />
              </Button>
              <Button
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
              >
                <FormattedMessage id="button.save" />
              </Button>
            </DialogActions>
          ))}
      </Form>
    </Dialog>
  );
};

FormikModal.propTypes = {
  title: PropTypes.node,
  maxWidth: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  showActions: PropTypes.bool,
  actions: PropTypes.node,
};

FormikModal.defaultProps = {
  title: null,
  closeMessage: null,
  maxWidth: 'sm',
  showActions: true,
  actions: null,
  dark: false,
};

export default FormikModal;
