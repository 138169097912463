import React, { Children, useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import PropTypes from 'prop-types';

const Wizard = ({ children, activeStep }) => {
  const [stepNumber, setStepNumber] = useState(activeStep);
  const steps = Children.toArray(children);

  const step = steps[activeStep];
  const totalSteps = steps.length;

  useEffect(() => {
    setStepNumber(Math.min(activeStep, totalSteps - 1));
  }, [activeStep]);

  return (
    <>
      <Stepper activeStep={stepNumber}>
        {steps.map(selectedStep => (
          <Step key={selectedStep.props.stepperKey}>
            <StepLabel>{selectedStep.props.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {step}
    </>
  );
};

export default Wizard;

Wizard.propTypes = {
  activeStep: PropTypes.number.isRequired,
};
