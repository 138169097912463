import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { v4 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { without } from 'lodash';
import { UsersTable, UsersToolbar } from '../../Components';
import { assignUsersToOrganizations } from '../../Services/UserService';
import FormikModal from '../../../../components/FormikModal';
import CreateUserForm from '../../Forms/CreateUserForm';
import { normalizeErrors } from '../../../../utils/dataAccess';
import { actions as UsersActions } from '../../Ducks/Users.duck';
import AssignUsersToOrganizationsForm from '../../Forms/AssignUsersToOrganizationsForm';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../../common/constants';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import RemoveAccessToOrganizationsForm from '../../Forms/RemoveAccessToOrganizationsForm';
import { ConfirmDialog } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const UserManagementPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.AppReducer);
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [editUserInitialValues, setEditUserInitialValues] = useState({});
  const [modalAssignOpen, setModalAssignOpen] = useState(false);
  const [modalRemoveOpen, setModalRemoveOpen] = useState(false);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  const [confirmActivationModal, setConfirmActivationModal] = useState(false);
  const [confirmDeactivationModal, setConfirmDeactivationModal] = useState(false);
  const [removeValidationSchema, setRemoveValidationSchema] = useState(Yup.object().shape({}));

  const {
    loading,
    items,
    page,
    pageSize,
    totalCount,
    selectedOrganizations,
    userOrganizations,
    selectedUser,
  } = useSelector(state => state.UsersReducer);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .strict()
      .min(2)
      .max(50)
      .required(),
    lastName: Yup.string()
      .trim()
      .strict()
      .min(2)
      .max(50)
      .required(),
    middleName: Yup.string()
      .trim()
      .strict(),
    emailAddress: Yup.string()
      .email()
      .required(),
  });

  useEffect(() => {
    setIsUserSuperAdmin(user.roles.includes('ROLE_SUPER_ADMIN'));
  }, [user]);

  useEffect(() => {
    dispatch(UsersActions.initializeUsersState());
    dispatch(UsersActions.requestData());
  }, [dispatch]);

  useEffect(() => {
    if (modalRemoveOpen) {
      let initialSchema = Yup.object().shape({});
      const organizationsValidation = Yup.object({
        organizations: Yup.string().required('Please select at least one organization'),
      });
      initialSchema = initialSchema.concat(organizationsValidation);
      setRemoveValidationSchema(initialSchema);
    } else if (!modalRemoveOpen) {
      setRemoveValidationSchema(Yup.object().shape({}));
    }
  }, [modalRemoveOpen]);

  const handleSearch = event => {
    dispatch(UsersActions.search(event.target.value));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(UsersActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(UsersActions.setPageSize(event.target.value));
  };

  const handleToggleUser = userToToggle => {
    dispatch(UsersActions.toggleUser(userToToggle));
  };

  const handleToggleAllUsers = users => dispatch(UsersActions.toggleAllUsers(users));

  const handleCreateUser = async (values, form) => {
    try {
      dispatch(UsersActions.createUser(values));
      setCreateUserModalOpen(false);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }

      throw e;
    }
    form.resetForm();
  };

  const handleClickEdit = userToEdit => {
    setEditUserInitialValues({
      userId: userToEdit.userId,
      firstName: userToEdit.personName.firstName,
      middleName: userToEdit.personName.middleName,
      lastName: userToEdit.personName.lastName,
      emailAddress: userToEdit.emailAddress,
      receiveSyncReport: userToEdit.receiveSyncReport,
      receiveConnectionTaskReport: userToEdit.receiveConnectionTaskReport,
    });
    setEditUserModalOpen(true);
  };

  const handleEditUser = async (values, form) => {
    let messageSeverity = MESSAGE_SEVERITY_SUCCESS;
    try {
      dispatch(UsersActions.editUser(values));
      setEditUserModalOpen(false);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }
      messageSeverity = MESSAGE_SEVERITY_ERROR;

      throw e;
    }
    dispatch(
      AppActions.displayMessage({
        message: 'editUser',
        severity: messageSeverity,
      }),
    );
    form.resetForm();
  };

  const handleAssignUsersToOrganizations = async values => {
    setModalAssignOpen(false);
    const users = items.filter(item => item.selectedRow).map(item => item.userId);
    await assignUsersToOrganizations(users, values.organizations);
    dispatch(UsersActions.requestData());
  };

  const onRemoveSelectedOrganization = (organization, resetForm) => {
    if (selectedOrganizations.length === 1) {
      resetForm();
    }
    dispatch(UsersActions.setSelectedOrganizations(without(selectedOrganizations, organization)));
    userOrganizations.push(organization);
  };

  const handleSelectOrganization = organization => {
    selectedOrganizations.push(organization);
    dispatch(UsersActions.setUserOrganizations(without(userOrganizations, organization)));
  };

  const handleRemoveAccessToOrganizations = async () => {
    const organizations = [];
    selectedOrganizations.forEach(function(org) {
      organizations.push(org.organizationId);
    });
    setModalRemoveOpen(false);
    dispatch(UsersActions.removeAccessToOrganizations(organizations, selectedUser.userId));
  };

  const handleClickRemoveAccess = userToRemoveAccess => {
    const orgs = [];
    Object.keys(userToRemoveAccess.accessToOrganizations).forEach(function(org) {
      orgs.push(userToRemoveAccess.accessToOrganizations[org].organization);
    });
    dispatch(UsersActions.setSelectedUser(userToRemoveAccess));
    dispatch(UsersActions.setUserOrganizations(...userOrganizations, orgs));
    setModalRemoveOpen(true);
  };

  const handleDeleteUser = () => {
    dispatch(UsersActions.deleteUser());
  };

  const handleClickDeleteUser = userToDelete => {
    setConfirmDeleteModal(true);
    dispatch(UsersActions.setSelectedUser(userToDelete));
  };

  const handleClickActivateUser = async userToActivate => {
    setConfirmActivationModal(true);
    dispatch(UsersActions.setSelectedUser(userToActivate));
  };

  const handleClickDeactivateUser = async userToDeactivate => {
    setConfirmDeactivationModal(true);
    dispatch(UsersActions.setSelectedUser(userToDeactivate));
  };

  const handleActivateUser = async () => {
    dispatch(UsersActions.activateUser());
  };

  const handleDeactivateUser = async () => {
    dispatch(UsersActions.deactivateUser());
  };

  return (
    <div className={classes.root}>
      <UsersToolbar
        handleClickAssignOrganizations={() => setModalAssignOpen(true)}
        enableAssignOrganizations={items.filter(item => item.selectedRow).length > 0}
        handleClickAdd={() => setCreateUserModalOpen(true)}
        handleSearch={handleSearch}
      />
      <div className={classes.content}>
        <UsersTable
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handleToggleUser={handleToggleUser}
          handleToggleAllUsers={handleToggleAllUsers}
          handleClickChange={handleClickEdit}
          handleClickRemoveAccess={handleClickRemoveAccess}
          handleClickDelete={handleClickDeleteUser}
          isUserSuperAdmin={isUserSuperAdmin}
          handleClickActivate={handleClickActivateUser}
          handleClickDeactivate={handleClickDeactivateUser}
          users={items}
          page={page - 1}
          rowsPerPage={pageSize}
          totalCount={totalCount}
          loading={loading}
        />
      </div>
      <Formik initialValues={{ organizations: [] }} onSubmit={handleAssignUsersToOrganizations}>
        <FormikModal
          show={modalAssignOpen}
          onHide={() => setModalAssignOpen(false)}
          title={<FormattedMessage id="users.assignOrganizations" />}
        >
          <AssignUsersToOrganizationsForm />
        </FormikModal>
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{ organizations: [] }}
        onSubmit={handleRemoveAccessToOrganizations}
        validationSchema={removeValidationSchema}
      >
        {({ resetForm }) => (
          <FormikModal
            show={modalRemoveOpen}
            onHide={() => {
              setModalRemoveOpen(false);
              dispatch(UsersActions.clearAll());
              resetForm();
            }}
            title={<FormattedMessage id="users.removeOrganizations" />}
          >
            <RemoveAccessToOrganizationsForm
              userOrganizations={userOrganizations}
              onRemoveSelectedOrganization={onRemoveSelectedOrganization}
              onSelectOrganization={handleSelectOrganization}
              selectedOrganizations={selectedOrganizations}
              resetForm={resetForm}
            />
          </FormikModal>
        )}
      </Formik>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          userId: v4(),
          emailAddress: '',
          firstName: '',
          middleName: '',
          lastName: '',
          accessToOrganizations: [],
        }}
        onSubmit={handleCreateUser}
      >
        <FormikModal
          show={createUserModalOpen}
          onHide={() => setCreateUserModalOpen(false)}
          title={<FormattedMessage id="users.create" />}
        >
          <CreateUserForm />
        </FormikModal>
      </Formik>

      {editUserModalOpen && (
        <Formik validationSchema={validationSchema} initialValues={editUserInitialValues} onSubmit={handleEditUser}>
          <FormikModal
            show={editUserModalOpen}
            onHide={() => setEditUserModalOpen(false)}
            title={<FormattedMessage id="user.update" />}
          >
            <CreateUserForm initialValues={editUserInitialValues} />
          </FormikModal>
        </Formik>
      )}

      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id="user.confirmDeleteTitle" />}
          open={confirmDeleteModal}
          setOpen={setConfirmDeleteModal}
          onConfirm={handleDeleteUser}
        >
          <FormattedMessage id="user.confirmDeleteMessage" />
        </ConfirmDialog>
      )}

      {confirmActivationModal && (
        <ConfirmDialog
          title={<FormattedMessage id="users.confirmActivationTitle" />}
          open={confirmActivationModal}
          setOpen={setConfirmActivationModal}
          onConfirm={handleActivateUser}
        >
          <FormattedMessage id="users.confirmActivationMessage" />
        </ConfirmDialog>
      )}

      {confirmDeactivationModal && (
        <ConfirmDialog
          title={<FormattedMessage id="users.confirmDeactivationTitle" />}
          open={confirmDeactivationModal}
          setOpen={setConfirmDeactivationModal}
          onConfirm={handleDeactivateUser}
        >
          <FormattedMessage id="users.confirmDeactivationMessage" />
        </ConfirmDialog>
      )}
    </div>
  );
};

export default UserManagementPage;
