import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createAction, handleActions } from 'redux-actions';
import { mapValues, unset } from 'lodash';
import {
  getCredentialForPackageType,
  getNewCredentialDetails,
  getPackageRelations,
  getPackageTypes,
  getSettingsForPackageType,
} from '../Services/OnboardingService';
import { INSURANCE_BRYDGE } from '../Components/OnboardingWizard/constants';

export const actionTypes = {
  // STEP 1
  RequestPackageTypes: '[Onboarding] Request package types',
  RequestPackageRelations: '[Onboarding] Request package types',
  FulfilledPackageTypes: '[Onboarding] Fulfilled package types',
  FulfilledPackageRelations: '[Onboarding] Fulfilled package relations',
  SelectPackageType: '[Onboarding] Select package type',
  SelectPackageToConnect: '[Onboarding] Select package to connect',
  RequestCredentialsForPackageType: '[Onboarding] Request credentials for package type',
  FullfilledCredentialsForPackageType: '[Onboarding] Fulfilled credentials for package type',
  RequestSettingsForPackageType: '[Onboarding] Request settings for package type',
  FullfilledSettingsForPackageType: '[Onboarding] Fulfilled settings for package type',
  SetSelectedPackage: '[Onboarding] Set Selected Package',
  SetSelectedCredentialType: '[Onboarding] Set Selected credential type',
  RequestCredentialDetailsForPackageType: '[Onboarding] Request new credential details for package type',
  RequestCredentialDetailsForPackageTypes: '[Onboarding] Request new credential details for package types',
  FullfilledCredentialDetailsForPackageType: '[Onboarding] Fulfilled new credential details for package type',
  FulfilledAllItems: '[Onboarding] Fulfilled all credential details',
  InitialValuesCredentials: '[Onboarding] Initial values for credentials',
  SetInitialValueForCredentials: '[Onboarding] Set initial value for credential',
  SetValues: '[Onboarding] Set values',
  RemovePackage: '[Onboarding] Remove package',
  RemoveInitialValueForCredential: '[Onboarding] Remove initial value for credential',
  RemoveCredentialDetails: '[Onboarding] Remove credential detail',
  RemoveSelectedCredentialType: '[Onboarding] Remove selected credential type',
  RemoveSelectedCredentialTypes: '[Onboarding] Remove selected credential types',
  ClearAll: '[Onboarding] Clear all',
  SetLoading: '[Onboarding] Set loading',
  SetCanGoBack: '[Onboarding] Set canGoBack false',
  SetSelectedReseller: '[Onboarding] Set selected reseller',
  SetSelectedOrganization: '[Onboarding] Set selected organization',
};

const initialState = {
  loading: false,
  loadedItem: false,
  loadedAllItems: false,
  loaded: false,
  packageTypes: [],
  packageRelations: [],
  availablePackageToConnect: [],
  selectedPackageType: null,
  selectedPackageToConnect: null,
  selectedCredentialType: null,
  credentials: {},
  settings: {},
  selectedPackages: [],
  credentialDetails: {},
  packages: [],
  initialCredentials: {},
  oldValues: {},
  canGoBack: false,
  selectedReseller: {},
  selectedOrganization: {},
};

export const reducer = handleActions(
  {
    [actionTypes.RequestPackageTypes]: state => ({ ...state, loading: true }),
    [actionTypes.RequestPackageRelations]: state => ({ ...state, loading: true }),
    [actionTypes.RequestCredentialsForPackageType]: state => ({ ...state, loading: true }),
    [actionTypes.RequestSettingsForPackageType]: state => ({ ...state, loading: true }),
    [actionTypes.RequestCredentialDetailsForPackageType]: state => ({ ...state, loading: true }),
    [actionTypes.RequestCredentialDetailsForPackageTypes]: state => ({ ...state, loading: true }),
    [actionTypes.FulfilledPackageTypes]: (state, { payload: packageTypes }) => ({
      ...state,
      loading: false,
      packageTypes,
    }),
    [actionTypes.FulfilledPackageRelations]: (state, { payload: packageRelations }) => ({
      ...state,
      loading: false,
      packageRelations,
    }),
    [actionTypes.FullfilledCredentialsForPackageType]: (state, { payload: { packageType, type, fields } }) => ({
      ...state,
      loading: false,
      credentials: {
        ...state.credentials,
        [packageType]: {
          type,
          fields,
        },
      },
    }),
    [actionTypes.FullfilledSettingsForPackageType]: (state, { payload: { packageType, type, fields } }) => ({
      ...state,
      loading: false,
      settings: {
        ...state.settings,
        [packageType]: {
          type,
          fields,
        },
      },
    }),
    [actionTypes.SelectPackageType]: (state, { payload: selectedPackageType }) => ({
      ...state,
      selectedPackageType,
      selectedPackageToConnect: null,
    }),
    [actionTypes.SelectPackageToConnect]: (state, { payload: selectedPackageToConnect }) => ({
      ...state,
      selectedPackageToConnect,
    }),
    [actionTypes.SetSelectedCredentialType]: (state, { payload: { packageType, credentialType } }) => ({
      ...state,
      selectedCredentialType: {
        ...state.selectedCredentialType,
        [packageType]: credentialType,
      },
    }),
    [actionTypes.SetSelectedPackage]: (state, { payload: packageType }) => ({
      ...state,
      selectedPackages: [...state.selectedPackages, packageType],
    }),
    [actionTypes.FullfilledCredentialDetailsForPackageType]: (
      state,
      // { payload: { packageType, fields, credentialType } },
      { payload: { packageType, allCredentialDetails } },
    ) => ({
      ...state,
      loading: false,
      loadedItem: true,
      loadedAllItems: false,
      credentialDetails: {
        ...state.credentialDetails,
        [packageType]: {
          ...allCredentialDetails,
        },
      },
    }),
    [actionTypes.FulfilledAllItems]: state => ({
      ...state,
      loading: false,
      loadedAllItems: true,
    }),
    [actionTypes.SetInitialValueForCredentials]: (state, { payload: { packageType, credentialId } }) => ({
      ...state,
      initialCredentials: {
        ...state.initialCredentials,
        [packageType]: credentialId,
      },
    }),
    [actionTypes.SetValues]: (state, { payload: values }) => ({
      ...state,
      oldValues: { values },
    }),
    [actionTypes.SetCanGoBack]: (state, { payload: truth }) => ({
      ...state,
      canGoBack: truth,
    }),
    [actionTypes.SetLoading]: (state, { payload: truth }) => ({
      ...state,
      loading: truth,
    }),
    [actionTypes.SetSelectedReseller]: (state, { payload: resellerId }) => ({
      ...state,
      selectedReseller: resellerId,
    }),
    [actionTypes.SetSelectedOrganization]: (state, { payload: organizationId }) => ({
      ...state,
      selectedOrganization: organizationId,
    }),
    [actionTypes.RemovePackage]: (state, { payload: packageType }) => ({
      ...state,
      selectedPackages: state.selectedPackages.filter(existingPackageType => existingPackageType !== packageType),
    }),
    [actionTypes.RemoveInitialValueForCredential]: (state, { payload: packageType }) => {
      const currentInitialCredentials = state.initialCredentials;
      unset(currentInitialCredentials, packageType);

      return {
        ...state,
        initialCredentials: currentInitialCredentials,
      };
    },
    [actionTypes.RemoveCredentialDetails]: (state, { payload: packageType }) => {
      const currentCredentialDetails = state.credentialDetails;
      unset(currentCredentialDetails, packageType);

      return {
        ...state,
        credentialDetails: currentCredentialDetails,
      };
    },
    [actionTypes.RemoveSelectedCredentialType]: (state, { payload: packageType }) => {
      const currentSelectedCredentialType = state.selectedCredentialType;
      unset(currentSelectedCredentialType, packageType);

      return {
        ...state,
        selectedCredentialType: currentSelectedCredentialType,
      };
    },
    [actionTypes.RemoveSelectedCredentialTypes]: state => {
      return {
        ...state,
        selectedCredentialType: null,
      };
    },
    [actionTypes.ClearAll]: state => ({
      ...state,
      selectedPackages: [],
      credentialDetails: {},
      packages: [],
      initialCredentials: {},
      settings: {},
      canGoBack: false,
      selectedReseller: {},
      selectedOrganization: {},
    }),
  },
  initialState,
);

export const actions = mapValues(actionTypes, constant => createAction(constant));

export function* saga() {
  yield takeLatest(actionTypes.RequestPackageTypes, function* requestPackageTypesSaga() {
    const response = yield getPackageTypes();
    yield put(actions.FulfilledPackageTypes(Object.values(response.data)));
  });

  yield takeLatest(actionTypes.RequestPackageRelations, function* requestPackageRelationsSaga() {
    const currentState = yield select(state => state.OnboardingReducer);
    const response = yield getPackageRelations(currentState.selectedPackageType);
    yield put(actions.FulfilledPackageRelations(Object.values(response.data)));
  });

  yield takeEvery(actionTypes.RequestCredentialsForPackageType, function* requestPackageTypesSaga({
    payload: packageType,
  }) {
    const { data } = yield getCredentialForPackageType(packageType);
    yield put(actions.FullfilledCredentialsForPackageType({ packageType, ...data }));
  });

  yield takeEvery(actionTypes.RequestSettingsForPackageType, function* requestPackageTypesSaga({
    payload: packageType,
  }) {
    const { data } = yield getSettingsForPackageType(packageType);
    yield put(actions.FullfilledSettingsForPackageType({ packageType, ...data }));
  });

  yield takeLatest(actionTypes.RequestCredentialDetailsForPackageTypes, function* requestCredentialsDetailsSaga() {
    const currentState = yield select(state => state.OnboardingReducer);

    for (const packageTypeKey in currentState.selectedPackages) {
      const packageType = currentState.selectedPackages[packageTypeKey];
      if (packageType !== INSURANCE_BRYDGE) {
        const response = yield getNewCredentialDetails(packageType);

        const allCredentialDetails = {};

        response.data['hydra:member'].forEach(item => {
          const { credentialType } = item;
          allCredentialDetails[credentialType] = item;
        });

        yield put(
          actions.FullfilledCredentialDetailsForPackageType({
            packageType,
            allCredentialDetails,
          }),
        );
      }
    }

    yield put(actions.FulfilledAllItems());
  });

  yield takeLatest(actionTypes.RemovePackage, function* removeInitialValueForCredential({ payload: packageType }) {
    const currentState = yield select(state => state.OnboardingReducer);
    if (currentState.initialCredentials.hasOwnProperty(packageType)) {
      yield put(actions.RemoveInitialValueForCredential(packageType));
    }
    if (currentState.credentialDetails.hasOwnProperty(packageType)) {
      yield put(actions.RemoveCredentialDetails(packageType));
    }
  });
}
