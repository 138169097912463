import React, { useEffect, useState } from 'react';
import { CardContent, MenuItem, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { atomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { getConnectionCurlCollection } from '../../Services/ConnectionService';

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  formControl: {
    minWidth: '100px',
  },
}));

const CurlDetails = ({ connectionId, curlId }) => {
  const [curlCollection, setCurlCollection] = useState([]);

  const getCurlCollection = async () => {
    const response = await getConnectionCurlCollection(connectionId, curlId);
    const curlData = response.data;
    if ('options' in curlData && 'headers' in curlData.options && 'Authorization' in curlData.options.headers) {
      curlData.options.headers.Authorization = '<hidden>';
    }
    setCurlCollection(curlData);
  };

  useEffect(() => {
    getCurlCollection();
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={'Curl Detail Overview'} />
            <CardContent>
              <SyntaxHighlighter language="json" style={atomOneDark}>
                {JSON.stringify(curlCollection, null, 2)}
              </SyntaxHighlighter>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CurlDetails;
