import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getProcessItem } from '../../../../Services/ProcessesService';
import { formatName } from '../../../../../../utils/nameFormatter';

const ProcessEventsDialog = props => {
  const { open, batchId, handleClose } = props;

  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    const response = await getProcessItem(batchId);
    setEvents(response.data.events);
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md" aria-labelledby="process-events-dialog">
      <DialogTitle id="process-events-dialog">
        <FormattedMessage id="connection.processes.events.dialogTitle" />
      </DialogTitle>
      <DialogContent>
        {loading && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="events.type" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="events.employee" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="events.archived" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="events.createdAt" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map(event => (
              <TableRow hover key={event.mongoId}>
                <TableCell>{event['@type']}</TableCell>
                <TableCell>{formatName(event.hrEmployee.personName)}</TableCell>
                <TableCell>{event.archived ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</TableCell>
                <TableCell>{moment(event.createdAt).format('L')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => handleClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProcessEventsDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  batchId: PropTypes.string,
};

export default ProcessEventsDialog;
