import React, { useState, createRef, useEffect } from 'react';
import Wizard from 'components/Wizard';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty, set } from 'lodash';
import { actions as OnboardingActions } from '../../Ducks/Onboarding.duck';
import OnboardingPackageSelectionStep from './OnboardingPackageSelectionStep';
import OnboardingCredentialStep from './OnboardingCredentialStep';
import OnboardingCredentialsSettingsStep from './OnboardingCredentialsSettingsStep';
import OnboardingConnectionSettingsStep from './OnboardingConnectionSettingsStep';
import { onboardNewConnection } from '../../Services/OnboardingService';
import { getConnection, updateConnection } from '../../Services/ConnectionService';

const OnboardingWizard = ({ onClose, onSubmit }) => {
  const [completePackageSelection, setCompletePackageSelection] = useState(false);
  const [completeCredentials, setCompleteCredentials] = useState(false);
  const [completeConnectionSettings, setCompleteConnectionSettings] = useState(false);
  const [completeCredentialSettings, setCompleteCredentialSettings] = useState(false);
  const [packageSelectionSubmitData, setPackageSelectionSubmitData] = useState({});
  const [credentialSubmitData, setCredentialSubmitData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [settingsSubmitData, setSettingsSubmitData] = useState(false);
  const [defaultsSettings, setDefaultsSettings] = useState([]);
  const [initialCredentialValues, setInitialCredentialValues] = useState({});
  const [backButtonPressed, setBackButtonPressed] = useState(false);
  const [connection, setConnection] = useState(null);
  const [activateConnection, setActivateConnection] = useState(false);
  const { selectedReseller, selectedOrganization } = useSelector(state => state.OnboardingReducer);
  const title = <FormattedMessage id="onboarding.title" />;
  const dispatch = useDispatch();

  const setBackButton = bool => {
    setBackButtonPressed(bool);
  };

  const formRef = createRef();
  useEffect(() => {
    if (!isEmpty(connection) && isEmpty(defaultsSettings) && isEmpty(initialCredentialValues)) {
      const defaultSettings = [];
      connection.packages.forEach(packageDetails => {
        setInitialCredentialValues(prevState => {
          const currentState = Object.assign({}, prevState);
          set(currentState, ['credentials', packageDetails.packageType], packageDetails.credentialId);
          return currentState;
        });
        defaultSettings[packageDetails.packageType] = packageDetails.settings;
      });
      setDefaultsSettings(defaultSettings);
    }
    // eslint-disable-next-line
  }, [connection]);

  const packageSelectionStep = {
    label: <FormattedMessage id="onboarding.selectPackagesStep" />,
    content: (
      <OnboardingPackageSelectionStep
        formRef={formRef}
        initialValues={packageSelectionSubmitData}
        onReset={() => {
          setPackageSelectionSubmitData([]);
          setCompletePackageSelection(false);
        }}
        onSubmit={values => {
          dispatch(OnboardingActions.RequestCredentialDetailsForPackageTypes());
          setPackageSelectionSubmitData(values);
          setCompletePackageSelection(true);
        }}
      />
    ),
    completed: completePackageSelection,
    optional: false,
    formRef,
    init: () => {
      setCompletePackageSelection(false);
    },
  };

  const packageAuthenticationStep = {
    label: <FormattedMessage id="onboarding.authenticatePackagesStep" />,
    content: (
      <OnboardingCredentialStep
        formRef={formRef}
        initialValues={credentialSubmitData}
        backButtonPressed={backButtonPressed}
        setBackButton={setBackButton}
        packageSelectionData={packageSelectionSubmitData}
        onReset={() => setCompleteCredentials(false)}
        onSubmit={values => {
          setCredentialSubmitData(values);
          setCompleteCredentials(true);
        }}
      />
    ),
    completed: completeCredentials,
    optional: false,
    formRef,
    init: () => {
      setCompleteCredentials(false);
    },
    setValues: () => {
      packageSelectionSubmitData.resellerId = selectedReseller;
      packageSelectionSubmitData.organizationId = selectedOrganization;
      setBackButtonPressed(true);
    },
  };

  const credentialSettingsStep = {
    label: <FormattedMessage id="onboarding.credentialSettingsStep" />,
    content: (
      <OnboardingCredentialsSettingsStep
        formRef={formRef}
        connection={connection}
        defaults={defaultsSettings}
        credentials={credentialSubmitData}
        onReset={() => setCompleteCredentialSettings(false)}
        usedPackages={packageSelectionSubmitData.packages}
        disabled={Boolean(connection)}
        onSubmit={async ({ settings }) => {
          if (!connection) {
            const response = await onboardNewConnection({
              connectionId: v4(),
              resellerId: packageSelectionSubmitData.resellerId,
              name: packageSelectionSubmitData.connectionName,
              synchronizationStartDate: moment(packageSelectionSubmitData.synchronizationStartDate).format(
                'YYYY-MM-DD',
              ),
              packages: packageSelectionSubmitData.packages.map(packageType => ({
                packageType,
                credentialId: credentialSubmitData.credentials[packageType],
                settings: settings[packageType] ?? {},
              })),
              organization: { organizationId: packageSelectionSubmitData.organizationId },
            });

            const { data: newConnection } = await getConnection(response.data.connectionId);
            setConnection(newConnection);
            setSettingsSubmitData(settings);
          }
          setCompleteCredentialSettings(true);
        }}
      />
    ),
    completed: completeCredentialSettings,
    optional: false,
    formRef,
    init: () => {
      setCompleteCredentialSettings(false);
    },
  };

  const connectionSettingsStep = {
    label: <FormattedMessage id="onboarding.connectionSettingsStep" />,
    content: connection && (
      <OnboardingConnectionSettingsStep
        formRef={formRef}
        defaults={defaultsSettings}
        connection={connection}
        useDelay
        onReset={() => setCompleteConnectionSettings(false)}
        usedPackages={packageSelectionSubmitData.packages}
        onSubmit={async ({ settings }) => {
          await updateConnection({
            connectionId: connection.connectionId,
            active: activateConnection,
            synchronizationStartDate: moment(packageSelectionSubmitData.synchronizationStartDate).format('YYYY-MM-DD'),
            packages: packageSelectionSubmitData.packages.map(packageType => ({
              packageType,
              credentialId: credentialSubmitData.credentials[packageType],
              settings: settings[packageType] ?? {},
            })),
          });
          setSettingsSubmitData(settings);
          setCompleteConnectionSettings(true);
        }}
      />
    ),
    completed: completeConnectionSettings,
    optional: false,
    formRef,
    init: () => {
      setCompleteConnectionSettings(false);
    },
  };

  const steps = [packageSelectionStep, packageAuthenticationStep, credentialSettingsStep, connectionSettingsStep];

  return (
    <Wizard
      onSubmit={() => {
        return onSubmit();
      }}
      steps={steps}
      onClose={onClose}
      showInModal
      title={title}
      activateConnection={setActivateConnection}
    />
  );
};

OnboardingWizard.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default OnboardingWizard;
