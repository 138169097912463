export const singleObjectInitialState = {
  item: undefined,
  loading: true,
  loaded: false,
};

export const singleObjectAction = (actionTypes, state, action) => {
  switch (action.type) {
    case actionTypes.FulfilledData: {
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.item,
      };
    }

    case actionTypes.RequestData: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.ResetData: {
      return {
        ...state,
        loading: false,
        loaded: false,
        item: {},
      };
    }

    default:
      return state;
  }
};

export const singleObjectActions = actionTypes => ({
  resetData: () => ({ type: actionTypes.ResetData }),
  requestData: itemId => ({
    type: actionTypes.RequestData,
    itemId,
  }),
  fulfilledData: item => ({
    type: actionTypes.FulfilledData,
    item,
  }),
});
