import React, { useState, createRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, set } from 'lodash';
import CredentialStep from '../Steps/CredentialsStepComponent/CredentialStep';
import { actions as OnboardActions } from '../../Ducks/Onboard.duck';
import Wizard from '../../../../components/Wizard';
import OnboardingConnectionSettingsStep from '../OnboardingWizard/OnboardingConnectionSettingsStep';

const ChangeConnectionWizard = ({ onClose, onSubmit, connectionId }) => {
  const [completeCredentials, setCompleteCredentials] = useState(false);
  const [completeSettings, setCompleteSettings] = useState(false);
  const [newCredentials, setNewCredentials] = useState({});
  const [settingsSubmitData, setSettingsSubmitData] = useState(false);
  const [defaultsSettings, setDefaultsSettings] = useState([]);
  const [initialCredentialValues, setInitialCredentialValues] = useState({});
  const { loadedAllCredentials, item: connection, items: credentials } = useSelector(state => state.OnboardReducer);
  const title = <FormattedMessage id="connection.update.title" />;
  const dispatch = useDispatch();
  const formRef = createRef();

  useEffect(() => {
    dispatch(OnboardActions.requestData(connectionId));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(connection) && isEmpty(defaultsSettings) && isEmpty(initialCredentialValues)) {
      const defaultSettings = [];
      connection.packages.forEach(packageDetails => {
        setInitialCredentialValues(prevState => {
          const currentState = Object.assign({}, prevState);
          set(currentState, ['credentials', packageDetails.packageType], packageDetails.credentialId);
          return currentState;
        });
        defaultSettings[packageDetails.packageType] = packageDetails.settings;
      });
      setDefaultsSettings(defaultSettings);
    }
    // eslint-disable-next-line
  }, [connection]);

  const credentialStep = {
    label: <FormattedMessage id="connection.update.credentials" />,
    content: (
      <CredentialStep
        formRef={formRef}
        onReset={() => setCompleteCredentials(false)}
        credentials={credentials}
        loading={loadedAllCredentials}
        initialValues={initialCredentialValues}
        onSubmit={values => {
          setNewCredentials(values);
          setCompleteCredentials(true);
        }}
      />
    ),
    completed: completeCredentials,
    optional: false,
    formRef,
    init: () => {
      setCompleteCredentials(false);
    },
  };

  const settingsStep = {
    label: <FormattedMessage id="connection.update.settings" />,
    content: connection ? (
      <OnboardingConnectionSettingsStep
        formRef={formRef}
        connection={connection}
        defaults={defaultsSettings}
        onReset={() => setCompleteSettings(false)}
        useDelay={false}
        onSubmit={({ settings }) => {
          setSettingsSubmitData(settings);
          setCompleteSettings(true);
        }}
      />
    ) : (
      <span />
    ),
    completed: completeSettings,
    optional: false,
    formRef,
    init: () => {
      setCompleteSettings(false);
    },
  };
  const steps = [credentialStep, settingsStep];

  return (
    <Wizard
      onSubmit={() => {
        return onSubmit({
          connectionId,
          packages: Object.keys(newCredentials.credentials).map(packageType => ({
            packageType,
            credentialId: newCredentials.credentials[packageType],
            settings: settingsSubmitData[packageType] ?? {},
          })),
        });
      }}
      steps={steps}
      onClose={onClose}
      showInModal
      title={title}
    />
  );
};

ChangeConnectionWizard.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  connectionId: PropTypes.string.isRequired,
};

export default ChangeConnectionWizard;
