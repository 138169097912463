import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useFormikContext } from 'formik';
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';

const TagsFieldComponent = ({ packageType, disabled, field }) => {
  const { setFieldValue, values } = useFormikContext();
  const fieldValue = !isEmpty(values) ? values.settings[packageType][field.fieldName] : [];

  return (
    <Grid key={field.fieldName} item xs={12}>
      <Autocomplete
        multiple
        disabled={disabled}
        options={[]}
        color="secondary"
        defaultValue={fieldValue}
        freeSolo
        onChange={(event, newValue) => {
          setFieldValue(`settings.${packageType}.${field.fieldName}`, newValue);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
        }
        renderInput={params => (
          <TextField
            {...params}
            color="secondary"
            fullWidth
            name={`onboarding.settings.${field.fieldName}`}
            label={<FormattedMessage id={`onboarding.settings.${field.fieldName}`} />}
          />
        )}
      />
    </Grid>
  );
};

export default TagsFieldComponent;
