import React, { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { getConnectionCurlCollection } from '../../Services/ConnectionService';
import { actions as ConnectionActions } from '../../Ducks/Connection.duck';
import CurlDetails from "../../Components/CurlDetails";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  syncElement: {
    marginTop: theme.spacing(2),
  },
  buttonElement: {
    marginRight: theme.spacing(1),
  },
}));


const ConnectionCurlDetailsPage = () => {
  const classes = useStyles();
  const { connectionId } = useParams();
  const { curlId } = useParams();
  const dispatch = useDispatch();

  const { loading: loadingConnection, item: connection } = useSelector(state => state.ConnectionReducer);

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ display: 'inline-block' }}>
      <RouterLink {...props} />
    </div>
  ));

  const [loadingCurlCollection, setLoadingCurlCollection] = useState(false);
  const [CurlCollection, setCurlCollection] = useState([]);

  const getCurlCollection = async () => {
    setLoadingCurlCollection(true);

    const response = await getConnectionCurlCollection(connectionId, curlId);
    setCurlCollection(response.data['hydra:member']);

    setLoadingCurlCollection(false);
  };

  useEffect(() => {
    dispatch(ConnectionActions.requestData(connectionId));

    getCurlCollection();
  }, [dispatch]);

  return (
    <div className={classes.root}>
      {!loadingConnection && !loadingCurlCollection && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">{!loadingConnection && connection.connectionName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonElement}
              component={CustomRouterLink}
              to={`/connection/${connectionId}/curls`}
            >
              <FormattedMessage id="button.curl.overview" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%' }}>
              <CurlDetails connectionId={connectionId} curlId={curlId} />
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ConnectionCurlDetailsPage;
