import * as React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect, useState } from 'react';
import { getOrganizations } from '../../Domain/Organizations/Services/OrganizationService';

const OrganizationAutocomplete = props => {
  const [globalFilter, setGlobalFilter] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const {
    form: { setFieldValue },
    field: { name },
    value,
  } = props;

  const [inputValue, setInputValue] = React.useState(value);

  const formatSingle = (event, newValue) => {
    if (!newValue) {
      setFieldValue(name, '');
      setInputValue('');
    } else {
      setFieldValue(name, newValue.organizationId || '');
      setInputValue(newValue);
    }
  };

  const onChange = React.useCallback(
    (event, newValue) => {
      if (props.multiple) {
        setFieldValue(
          name,
          newValue.map(item => item.organizationId),
        );
      } else {
        formatSingle(event, newValue);
      }
    },
    // eslint-disable-next-line
    [setFieldValue, name],
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getOrganizations(1, 999, {}, {}, globalFilter);

      setLoading(false);

      setData(result.data['hydra:member']);
    };

    fetchData();
  }, [globalFilter]);

  return (
    <Autocomplete
      {...props}
      value={inputValue}
      style={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      onInputChange={(event, value) => {
        return setGlobalFilter(value);
      }}
      getOptionSelected={(option, value) => {
        return option.organizationId === value.organizationId;
      }}
      getOptionLabel={option => {
        return option.label ?? '';
      }}
      options={
        loading
          ? []
          : data.map(organization => {
              const splittedId = organization['@id'].split('/');
              return {
                organizationId: splittedId[splittedId.length - 1],
                label: `${organization.organizationName} (${organization.organizationCode})`,
              };
            })
      }
      loading={loading}
      renderInput={params => {
        return (
          <TextField
            {...fieldToTextField(props)}
            {...params}
            onChange={() => {}}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={25} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default OrganizationAutocomplete;
