import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { Check, Error, ErrorOutlined, Info, LinkRounded, ContentCopy } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { round } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {},
  success: {
    color: theme.palette.success.main,
  },
  content: {
    padding: 0,
  },
  center: {
    verticalAlign: 'middle',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  innerTable: {
    border: '1px solid black',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const CurlTable = props => {
  const {
    className,
    curlCollections,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    disableCard,
    handleCopyContentToClipboard,
    paging,
    ...rest
  } = props;

  const classes = useStyles();

  function Row({ curlCollection }) {
    return (
      <>
        <TableRow key={curlCollection.mongoId}>
          <TableCell>{curlCollection.taskId ?? 'Not part of a task'}</TableCell>
          <TableCell>
            <FormattedMessage id={curlCollection.moduleName ?? 'Not part of a module'} />
          </TableCell>
          <TableCell>
            {curlCollection.createdAt && (
              <>
                <FormattedDate value={curlCollection.createdAt} />
                &nbsp;
                <FormattedTime value={curlCollection.createdAt} />
              </>
            )}
          </TableCell>
          <TableCell>{curlCollection.method}</TableCell>
          <TableCell>{curlCollection.url}</TableCell>
          <TableCell>
            <Grid container alignContent="center" spacing={1}>
              <Grid item>
                {curlCollection.response?.statusCode === 200 && <Check color="disabled" />}
                {curlCollection.response?.statusCode === 401 && <Error color="error" />}
              </Grid>
              <Grid item>{curlCollection.response?.statusCode}</Grid>
            </Grid>
          </TableCell>
          <TableCell>
            {curlCollection?.response?.options?.total_time_us ?? curlCollection?.response?.info?.total_time ?? false
              ? `${round(
                  curlCollection?.response?.options?.total_time_us ??
                    curlCollection?.response?.info?.total_time ??
                    false,
                  2,
                )} seconds`
              : 'Not found'}
          </TableCell>
          <TableCell>
            <Link href="#" onClick={() => handleCopyContentToClipboard(curlCollection.options)}>
              <ContentCopy />
            </Link>
          </TableCell>
          <TableCell>
            <Link href="#" onClick={() => handleCopyContentToClipboard(curlCollection.response)}>
              <ContentCopy />
            </Link>
          </TableCell>
          <TableCell>
            <Link href={`curls/${curlCollection.mongoId}`}>
              <LinkRounded />
            </Link>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="connections.curls.task_id" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.module" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.date" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.method" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.url" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.response_code" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.request_duration" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.request_info" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.response_info" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.curls.curl_details" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(rowsPerPage)
              .fill(index => index)
              .map((value, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
          {!loading && curlCollections.length === 0 && (
            <TableRow>
              <TableCell colSpan={10}>No curls found</TableCell>
            </TableRow>
          )}
          {!loading &&
            curlCollections.length > 0 &&
            curlCollections.map(curlCollection => <Row key={curlCollection.mongoId} curlCollection={curlCollection} />)}
        </TableBody>
      </Table>
    </>
  );

  if (disableCard) {
    return table;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.center}>{table}</div>
        </PerfectScrollbar>
      </CardContent>
      {paging && (
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      )}
    </Card>
  );
};

CurlTable.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.bool,
  curlCollections: PropTypes.array.isRequired,
};

CurlTable.defaultProps = {
  paging: true,
  className: '',
};

export default CurlTable;
