import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import OrganizationAutocompleteField from '../../../../components/OrganizationAutocompleteField/OrganizationAutocompleteField';

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginTop: theme.spacing(1),
  },
}));

const CreateUserForm = ({ initialValues }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={4}>
        <Field
          required
          color="secondary"
          component={TextField}
          name="firstName"
          label={<FormattedMessage id="users.firstName" />}
          fullWidth
        />
      </Grid>

      <Grid item xs={4}>
        <Field
          color="secondary"
          component={TextField}
          name="middleName"
          label={<FormattedMessage id="users.middleName" />}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          required
          color="secondary"
          component={TextField}
          name="lastName"
          label={<FormattedMessage id="users.lastName" />}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          required
          type="email"
          color="secondary"
          component={TextField}
          name="emailAddress"
          label={<FormattedMessage id="users.email" />}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          color="secondary"
          multiple
          component={OrganizationAutocompleteField}
          name="accessToOrganizations"
          label={<FormattedMessage id="users.accessToOrganizations" />}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className={classes.checkbox}>
        <Typography>
          <Field
            required
            type="checkbox"
            color="secondary"
            component={Checkbox}
            name="receiveSyncReport"
            label={<FormattedMessage id="users.receiveSyncReport" />}
            fullWidth
          />
          <FormattedMessage id="user.receiveSyncReport" />
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.checkbox}>
        <Typography>
          <Field
            required
            type="checkbox"
            color="secondary"
            component={Checkbox}
            name="receiveConnectionTaskReport"
            label={<FormattedMessage id="users.receiveConnectionTaskReport" />}
            fullWidth
          />
          <FormattedMessage id="user.receiveConnectionTaskReport" />
        </Typography>
      </Grid>
    </Grid>
  );
};

CreateUserForm.propTypes = {
  initialValues: PropTypes.object,
};

export default CreateUserForm;
