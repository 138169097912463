import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { Check, Error, ErrorOutlined, Info } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
  root: {},
  success: {
    color: theme.palette.success.main,
  },
  content: {
    padding: 0,
  },
  center: {
    verticalAlign: 'middle',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  innerTable: {
    border: '1px solid black',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const InsightTable = props => {
  const {
    className,
    syncElements,
    handlePageChange,
    handleRowsPerPageChange,
    handleClickResetSyncElementSyncStatus,
    handleClickResetSyncElementFromSource,
    handleClickIgnoreSyncElement,
    page,
    rowsPerPage,
    totalCount,
    loading,
    disableCard,
    paging,
    ...rest
  } = props;

  const classes = useStyles();

  function Row({ syncElement }) {
    const [open, setOpen] = React.useState(false);

    return (
      <>
        <TableRow key={syncElement.syncElementSyncKey}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{syncElement.syncElementName}</TableCell>
          <TableCell>{syncElement.syncElementSyncKey}</TableCell>
          <TableCell>
            <Grid container alignContent="center" spacing={1}>
              <Grid item>
                {syncElement.syncElementSyncStatus === 'pending' && <Info color="disabled" />}
                {syncElement.syncElementSyncStatus === 'processing' && <Info color="secondary" />}
                {syncElement.syncElementSyncStatus === 'complete' && <Check className={classes.success} />}
                {syncElement.syncElementSyncStatus === 'queued' && <Info color="primary" />}
                {syncElement.syncElementSyncStatus === 'cancelled' && <ErrorOutlined color="primary" />}
                {syncElement.syncElementSyncStatus === 'failed' && <Error color="error" />}
                {syncElement.syncElementSyncStatus === 'ignored' && <Check color="secondary" />}
              </Grid>
              <Grid item>{syncElement.syncElementSyncStatus}</Grid>
            </Grid>
          </TableCell>

          <TableCell>
            {syncElement.syncElementDate && (
              <>
                <FormattedDate value={syncElement.syncElementDate} />
                &nbsp;
                <FormattedTime value={syncElement.syncElementDate} />
              </>
            )}
          </TableCell>
          <TableCell>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleClickResetSyncElementSyncStatus(syncElement.syncElementSyncKey)}
                  disabled={false}
                >
                  <FormattedMessage id="button.resetSyncStatus" />
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleClickResetSyncElementFromSource(syncElement.syncElementSyncKey)}
                  disabled={false}
                  className={classes.button}
                >
                  <FormattedMessage id="button.resetSyncStatusFromSource" />
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleClickIgnoreSyncElement(syncElement.syncElementSyncKey)}
                  disabled={false}
                  className={classes.button}
                >
                  <FormattedMessage id="button.ignore" />
                </Button>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" className={classes.innerTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="connections.insight.syncElement.syncCollection.name" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.insight.syncElement.syncCollection.type" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.insight.syncElement.syncCollection.documentId" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.insight.syncElement.syncCollection.syncStatus" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.insight.syncElement.syncCollection.id" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.insight.syncElement.syncCollection.syncMessage" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {syncElement.syncElementSyncDetails.map(syncElementCollection => (
                      <TableRow key={syncElementCollection.documentName}>
                        <TableCell>{syncElementCollection.documentName}</TableCell>
                        <TableCell>{syncElementCollection.type}</TableCell>
                        <TableCell>{syncElementCollection.documentId}</TableCell>
                        <TableCell>
                          <Grid container alignContent="center" spacing={1}>
                            <Grid item>
                              {syncElementCollection.syncStatus === 'pending' && <Info color="disabled" />}
                              {syncElementCollection.syncStatus === 'processing' && <Info color="secondary" />}
                              {syncElementCollection.syncStatus === 'complete' && <Check className={classes.success} />}
                              {syncElementCollection.syncStatus === 'queued' && <Info color="primary" />}
                              {syncElementCollection.syncStatus === 'cancelled' && <ErrorOutlined color="primary" />}
                              {syncElementCollection.syncStatus === 'failed' && <Error color="error" />}
                              {syncElementCollection.syncStatus === 'ignored' && <Check color="secondary" />}
                            </Grid>
                            <Grid item>{syncElementCollection.syncStatus}</Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>{syncElementCollection.externalId}</TableCell>
                        <TableCell>{syncElementCollection.syncMessage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <FormattedMessage id="connections.insight.syncElement" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.insight.syncKey" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.insight.syncStatus" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.insight.syncDate" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.insight.actions" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(rowsPerPage)
              .fill(index => index)
              .map((value, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
          {!loading && syncElements.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No sync Element found</TableCell>
            </TableRow>
          )}
          {!loading &&
            syncElements.length > 0 &&
            syncElements.map(syncElement => <Row key={syncElement.syncElementSyncKey} syncElement={syncElement} />)}
        </TableBody>
      </Table>
    </>
  );

  if (disableCard) {
    return table;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.center}>{table}</div>
        </PerfectScrollbar>
      </CardContent>
      {paging && (
        <CardActions className={classes.actions}>
          <TablePagination
            showFirstButton
            showLastButton
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      )}
    </Card>
  );
};

InsightTable.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.bool,
  syncElements: PropTypes.array.isRequired,
};

InsightTable.defaultProps = {
  paging: true,
  className: '',
};

export default InsightTable;
