import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';
import { getConnectionModules } from '../Services/ConnectionService';

export const actionTypes = {
  RequestData: '[Modules] Request',
  FulfilledTable: '[Modules] Fulfilled',
  SearchTable: '[Modules] Search',
  ChangePage: '[Modules] change page',
  SetPageSize: '[Modules] set page size',
  SortTable: '[Modules] Sort',
  ApplyFilter: '[Modules] Apply filter',
  RemoveFilter: '[Modules] Remove filter',
};

const initialState = {
  ...tableInitialState,
  pageSize: 1000,
  connectionId: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...state,
        loading: true,
        connectionId: action.connectionId,
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  requestData: connectionId => ({ type: actionTypes.RequestData, connectionId }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestData, function* request() {
    const currentState = yield select(state => {
      return state.ModulesReducer;
    });

    const response = yield getConnectionModules(
      currentState.connectionId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
