import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccountManagementPage from './AccountManagementPage';

const AccountPages = () => {
  return (
    <Switch>
      <Route component={AccountManagementPage} exact path="" />
    </Switch>
  );
};

export default AccountPages;
