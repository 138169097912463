import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import AccountProfileDetails from '../../Components/AccountProfileDetails/AccountProfileDetails';
import SettingsPassword from '../../Components/SettingsPassword/SettingsPassword';
import AccountToolbar from '../../Components/AccountToolbar/AccountToolbar';
import UpdateEmail from '../../Components/UpdateEmail/UpdateEmail';
import DeleteAccount from '../../Components/DeleteAccount';
import ActivateTwoFactorAuthentication from '../../Components/ActivateTwoFactorAuthentication';
import DisableTwoFactorAuthentication from '../../Components/DisableTwoFactorAuthentication';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const AccountManagementPage = () => {
  const classes = useStyles();
  const [updateEmailModalOpen, setUpdateEmailModalOpen] = useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [enableTwoFactorAuthenticationModalOpen, setEnableTwoFactorAuthenticationModalOpen] = useState(false);
  const [disableTwoFactorAuthenticationModalOpen, setDisableTwoFactorAuthenticationModalOpen] = useState(false);

  return (
    <div className={classes.root}>
      <AccountToolbar
        handleClickUpdateEmail={() => setUpdateEmailModalOpen(true)}
        handleClickDeleteAccount={() => setDeleteAccountModalOpen(true)}
        handleClickActivateTwoFactorAuthenticationStatus={() => setEnableTwoFactorAuthenticationModalOpen(true)}
        handleClickDeactivateTwoFactorAuthenticationStatus={() => setDisableTwoFactorAuthenticationModalOpen(true)}
      />
      <UpdateEmail showUpdateEmailModal={updateEmailModalOpen} setUpdateEmailModalOpen={setUpdateEmailModalOpen} />
      <DeleteAccount
        showDeleteAccountModal={deleteAccountModalOpen}
        setDeleteAccountModalOpen={setDeleteAccountModalOpen}
      />
      <ActivateTwoFactorAuthentication
        showTwoFactorAuthenticationModal={enableTwoFactorAuthenticationModalOpen}
        setTwoFactorAuthenticationModalOpen={setEnableTwoFactorAuthenticationModalOpen}
      />
      <DisableTwoFactorAuthentication
        showTwoFactorAuthenticationModal={disableTwoFactorAuthenticationModalOpen}
        setTwoFactorAuthenticationModalOpen={setDisableTwoFactorAuthenticationModalOpen}
      />
      <AccountProfileDetails />
      <SettingsPassword />
    </div>
  );
};

export default AccountManagementPage;
