import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import EnableTwoFactorAuthenticationStep from './EnableTwoFactorAuthenticationStep';
import Wizard from '../Wizard';
import WizardStep from '../Wizard/WizardStep';
import SetupTwoFactorAuthenticationStep from './SetupTwoFactorAuthenticationStep';
import { ENABLE_TWO_FACTOR_AUTHENTICATION_STEP, SETUP_TWO_FACTOR_AUTHENTICATION_STEP } from '../../Constants/Steps';
import { getUserWithToken } from '../../../App/Services/AppServices';

const ActivateTwoFactorAuthentication = ({ showTwoFactorAuthenticationModal, setTwoFactorAuthenticationModalOpen }) => {
  const { user, authToken } = useSelector(state => state.AppReducer);
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [qrCodeImage, setQrCodeImage] = useState(null);

  const next = () => {
    setActiveStep(prevState => prevState + 1);
  };

  const reloadUserData = async () => {
    const response = await getUserWithToken(authToken, user.userId);
    dispatch(AppActions.setUser(response.data));
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      aria-labelledby="wizard-dialog-title"
      open={showTwoFactorAuthenticationModal}
      onClose={() => {}}
      disableEscapeKeyDown
      disableBackdropClick
    >
      <DialogTitle id="wizard-dialog-title">
        <FormattedMessage id="account.enableTwoFactorAuthenticationTitle" />
      </DialogTitle>

      <Wizard activeStep={activeStep}>
        <WizardStep
          label={<FormattedMessage id={`account.${SETUP_TWO_FACTOR_AUTHENTICATION_STEP}`} />}
          stepperKey={SETUP_TWO_FACTOR_AUTHENTICATION_STEP}
        >
          <SetupTwoFactorAuthenticationStep
            onSubmit={() => {
              next();
            }}
            setQrCodeImage={setQrCodeImage}
            user={user}
            onCancel={() => {
              setTwoFactorAuthenticationModalOpen(false);
            }}
          />
        </WizardStep>
        <WizardStep
          label={<FormattedMessage id={`account.${ENABLE_TWO_FACTOR_AUTHENTICATION_STEP}`} />}
          stepperKey={ENABLE_TWO_FACTOR_AUTHENTICATION_STEP}
        >
          <EnableTwoFactorAuthenticationStep
            onSubmit={async () => {
              await reloadUserData();
              setTwoFactorAuthenticationModalOpen(false);
              setActiveStep(0);
              setQrCodeImage(null);
            }}
            user={user}
            qrCodeImage={qrCodeImage}
            onCancel={() => {
              setTwoFactorAuthenticationModalOpen(false);
              setActiveStep(0);
              setQrCodeImage(null);
            }}
            buttonTitle={<FormattedMessage id="button.enable" />}
          />
        </WizardStep>
      </Wizard>
    </Dialog>
  );
};

ActivateTwoFactorAuthentication.propTypes = {
  showTwoFactorAuthenticationModal: PropTypes.bool.isRequired,
  setTwoFactorAuthenticationModalOpen: PropTypes.func.isRequired,
};

export default ActivateTwoFactorAuthentication;
