import React from 'react';
import PropTypes from 'prop-types';

import { blue, yellow, red } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';

const MessageTypeIcon = props => {
  const { messageType } = props;

  const icons = {
    info: <InfoIcon style={{ color: blue[500] }} />,
    warning: <WarningIcon style={{ color: yellow[500] }} />,
    error: <ErrorOutlineIcon style={{ color: red[500] }} />,
    emergency: <ErrorIcon style={{ color: red[500] }} />,
  };

  return icons[messageType] ?? messageType;
};

MessageTypeIcon.propTypes = {
  messageType: PropTypes.string,
};

export default MessageTypeIcon;
