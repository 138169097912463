import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SettingsPasswordForm from '../../Forms/SettingsPasswordForm';
import { normalizeErrors } from '../../../../utils/dataAccess';
import { updatePassword } from '../../Services/AccountService';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

const SettingsPassword = () => {
  const classes = useStyles();
  const { user } = useSelector(state => state.AppReducer);

  const [updatePasswordInitialValues] = useState({
    userId: user.userId,
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(),
    newPassword: Yup.string().required(),
    newPasswordConfirmation: Yup.string()
      .required()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const handleUpdatePassword = async (values, form) => {
    try {
      await updatePassword(values);
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }

      throw e;
    }

    form.resetForm();
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={updatePasswordInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdatePassword}
      >
        <SettingsPasswordForm />
      </Formik>
    </div>
  );
};

export default SettingsPassword;
