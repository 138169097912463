import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { SearchInput, ConfirmDialog } from 'components';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { EVENT_TYPES } from '../../Packages/InsuranceBrydge/Pages/EventsPage/constants';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  buttonElement: {
    marginRight: theme.spacing(1),
  },
  eventsDropdown: {
    minWidth: '150px',
    marginRight: '5px',
  },
  eventsDatePicker: {
    width: '150px',
    marginRight: '5px',
    marginTop: '8px',
  },
}));

const EventsToolbar = props => {
  const {
    className,
    connectionId,
    handleSearch,
    filters,
    applyFilter,
    processEvents,
    archiveEvents,
    unArchiveEvents,
    hasSelectedItems,
    allEventsArchived,
    allEventsUnarchived,
    resetFilters,
    intl,
    ...rest
  } = props;

  const classes = useStyles();
  const [confirmProcessOpen, setConfirmProcessOpen] = useState(false);
  const [confirmArchiveOpen, setConfirmArchiveOpen] = useState(false);
  const [confirmUnArchiveOpen, setConfirmUnArchiveOpen] = useState(false);

  const handleFromDateChange = date => {
    applyFilter('eventDate[after]', date ? moment(date).format('L') : null);
  };

  const handleUntilDateChange = date => {
    applyFilter('eventDate[before]', date ? moment(date).format('L') : null);
  };

  const changeEventType = event => {
    applyFilter('eventType', event.target.value ? event.target.value : undefined);
  };

  const changeArchived = event => {
    applyFilter('archived', event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonElement}
          component={Link}
          to={`/connection/${connectionId}`}
        >
          <FormattedMessage id="connectionDetails.back" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonElement}
          component={Link}
          to={`/connection/insurance-brydge/${connectionId}/batches`}
        >
          <FormattedMessage id="connectionDetails.batches" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonElement}
          onClick={() => setConfirmProcessOpen(true)}
          disabled={!hasSelectedItems}
        >
          <FormattedMessage id="events.processAction" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonElement}
          onClick={() => setConfirmArchiveOpen(true)}
          disabled={!hasSelectedItems || !allEventsUnarchived}
        >
          <FormattedMessage id="events.archiveAction" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonElement}
          onClick={() => setConfirmUnArchiveOpen(true)}
          disabled={!hasSelectedItems || !allEventsArchived}
        >
          <FormattedMessage id="events.unArchiveAction" />
        </Button>
        <Button color="primary" variant="contained" className={classes.buttonElement} onClick={() => resetFilters()}>
          <FormattedMessage id="events.resetFilters" />
        </Button>
      </div>
      <div className={classes.row}>
        <FormControl className={classes.eventsDropdown}>
          <InputLabel>Event types</InputLabel>
          <Select
            multiple
            value={filters.eventType ?? []}
            onChange={changeEventType}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
          >
            {EVENT_TYPES.map(type => (
              <MenuItem key={type} value={type} selected={filters.eventType ? filters.eventType.includes(type) : false}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.eventsDropdown}>
          <InputLabel>Archived</InputLabel>
          <Select onChange={changeArchived} value={filters.archived ?? ''}>
            <MenuItem value>
              <FormattedMessage id="yes" />
            </MenuItem>
            <MenuItem value={false}>
              <FormattedMessage id="no" />
            </MenuItem>
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-from-inline"
            className={classes.eventsDatePicker}
            label={intl.formatMessage({ id: 'events.datePickerFrom' })}
            value={filters['eventDate[after]'] ?? null}
            onChange={handleFromDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />

          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-until-inline"
            className={classes.eventsDatePicker}
            label={intl.formatMessage({ id: 'events.datePickerUntil' })}
            value={filters['eventDate[before]'] ?? null}
            onChange={handleUntilDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        <span className={classes.spacer} />
        <SearchInput
          className={classes.searchInput}
          onChange={handleSearch}
          placeholder={intl.formatMessage({ id: 'events.search' })}
        />
      </div>
      <ConfirmDialog
        title="Process events?"
        open={confirmProcessOpen}
        setOpen={setConfirmProcessOpen}
        onConfirm={processEvents}
      >
        <p>
          <FormattedMessage id="events.processConfirmMessage" />
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        title="Archive events?"
        open={confirmArchiveOpen}
        setOpen={setConfirmArchiveOpen}
        onConfirm={archiveEvents}
      >
        <p>
          <FormattedMessage id="events.archiveConfirmMessage" />
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        title={<FormattedMessage id="events.unArchiveConfirmTitle" />}
        open={confirmUnArchiveOpen}
        setOpen={setConfirmUnArchiveOpen}
        onConfirm={unArchiveEvents}
      >
        <p>
          <FormattedMessage id="events.unArchiveConfirmMessage" />
        </p>
      </ConfirmDialog>
    </div>
  );
};

EventsToolbar.propTypes = {
  className: PropTypes.string,
};

EventsToolbar.defaultProps = {
  className: '',
};

export default injectIntl(EventsToolbar);
