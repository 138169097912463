import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';

export default palette => ({
  MuiButton: MuiButton(palette),
  MuiIconButton: MuiIconButton(palette),
  MuiPaper: MuiPaper(palette),
  MuiTableCell: MuiTableCell(palette),
  MuiTableHead: MuiTableHead(palette),
  MuiTypography: MuiTypography(palette),
});
