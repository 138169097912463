import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { actions as OnboardingActions } from '../../Domain/Connections/Ducks/Onboarding.duck';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Wizard = ({ steps, showInModal = true, onSubmit, onClose, title, activateConnection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { canGoBack, loading: isLoading } = useSelector(state => state.OnboardingReducer);

  const optionalLabel = (
    <Typography variant="caption">
      <FormattedMessage id="step.optional" />
    </Typography>
  );
  const stepperSteps = steps.map(({ label, optional }, index) => (
    <Step key={label.props.id} completed={index < activeStep}>
      <StepLabel optional={optional ? optionalLabel : null}>{label}</StepLabel>
    </Step>
  ));

  const { init, formRef, content, completed, optional = false, setValues } = steps[activeStep];
  init();
  const canProceed = (!optional && completed) || optional;

  const handleNext = async () => {
    if (!canProceed) {
      return;
    }
    if (activeStep === steps.length - 1) {
      setLoading(true);
      await onSubmit();
      setLoading(false);
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1 && setValues) {
      setValues();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
      dispatch(OnboardingActions.SetCanGoBack(false));
    }
  };
  useEffect(() => {
    if (canGoBack && activeStep === 1) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
      dispatch(OnboardingActions.SetCanGoBack(false));
    }
    // eslint-disable-next-line
  }, [canGoBack]);

  useEffect(() => {
    if (completed) {
      handleNext();
    }
    // eslint-disable-next-line
  }, [completed]);

  const component = (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>{stepperSteps}</Stepper>
      <div>
        <div>
          {content}
          <div>
            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={loading || isLoading}
              onClick={() => formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))}
              className={classes.button}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>

            {activateConnection && activeStep === steps.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                disabled={loading || isLoading}
                onClick={() => {
                  activateConnection(true);
                  formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
                }}
                className={classes.button}
              >
                Finish and activate
              </Button>
            )}
            <Button variant="outlined" color="primary" onClick={onClose}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
  if (showInModal) {
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        aria-labelledby="wizard-dialog-title"
        open
        onClose={onClose}
        disableEscapeKeyDown
        disableBackdropClick
      >
        <DialogTitle id="wizard-dialog-title">{title}</DialogTitle>
        {component}
      </Dialog>
    );
  }
  return component;
};

Wizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.element.isRequired,
      completed: PropTypes.bool,
      optional: PropTypes.bool,
      label: PropTypes.object, // translated string
    }),
  ).isRequired,
  title: PropTypes.object, // translated string
  showInModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default injectIntl(Wizard);
