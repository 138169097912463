import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormattedMessage } from 'react-intl';
import OrganizationAutocompleteField from 'components/OrganizationAutocompleteField';
import PropTypes from 'prop-types';

const CreateOrganizationForm = ({ initialValues, isCreateOrganization }) => (
  <Grid container>
    <Grid item xs={12}>
      <Field
        color="secondary"
        component={TextField}
        name="organizationName"
        label={<FormattedMessage id="organizations.organizationName" />}
        fullWidth
      />
    </Grid>
    {isCreateOrganization && (
      <Grid item xs={12}>
        <Field
          color="secondary"
          component={TextField}
          name="organizationCode"
          label={<FormattedMessage id="organizations.organizationCode"/>}
          fullWidth
        />
      </Grid>
    )}
    <Grid item xs={12}>
      <Field
        color="secondary"
        component={OrganizationAutocompleteField}
        name="resellerId"
        value={initialValues ? initialValues.resellerId : ''}
        label={<FormattedMessage id="organizations.reseller" />}
        fullWidth
      />
    </Grid>
  </Grid>
);

CreateOrganizationForm.propTypes = {
  initialValues: PropTypes.object,
  isCreateOrganization: PropTypes.bool,
};

export default CreateOrganizationForm;
