import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const AccountToolbar = props => {
  const { user } = useSelector(state => state.AppReducer);

  const {
    className,
    handleClickUpdateEmail,
    handleClickDeleteAccount,
    handleClickActivateTwoFactorAuthenticationStatus,
    handleClickDeactivateTwoFactorAuthenticationStatus,
  } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Button className={classes.button} color="primary" variant="contained" onClick={handleClickUpdateEmail}>
          <FormattedMessage id="button.updateEmail" />
        </Button>
        <Button className={classes.button} color="primary" variant="contained" onClick={handleClickDeleteAccount}>
          <FormattedMessage id="button.deleteAccount" />
        </Button>

        {!user.totpAuthenticationEnabled && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={handleClickActivateTwoFactorAuthenticationStatus}
          >
            <FormattedMessage id="button.enableTwoFactorAuthentication" />
          </Button>
        )}

        {user.totpAuthenticationEnabled && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={handleClickDeactivateTwoFactorAuthenticationStatus}
          >
            <FormattedMessage id="button.disableTwoFactorAuthentication" />
          </Button>
        )}
      </div>
    </div>
  );
};

AccountToolbar.propTypes = {
  className: PropTypes.string,
  handleClickUpdateEmail: PropTypes.func.isRequired,
  handleClickDeleteAccount: PropTypes.func.isRequired,
  handleClickActivateTwoFactorAuthenticationStatus: PropTypes.func.isRequired,
  handleClickDeactivateTwoFactorAuthenticationStatus: PropTypes.func.isRequired,
};

export default injectIntl(AccountToolbar);
