import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { actions as ProcessesActions } from '../../../../Ducks/Processes.duck';
import ProcessesToolbar from '../../Components/ProcessesToolbar/ProcessesToolbar';
import ProcessesTable from '../../Components/ProcessesTable';
import ProcessMessagesDialog from '../../Components/ProcessMessagesDialog';
import ProcessEventsDialog from '../../Components/ProcessEventsDialog';
import { downloadProcessResultRequest } from '../../../../Services/ProcessesService';
import { actions as ConnectionActions } from '../../../../Ducks/Connection.duck';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const BatchesPage = () => {
  const { connectionId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { items, page, pageSize, totalCount } = useSelector(state => state.ProcessesReducer);
  const { loading, item: connection } = useSelector(state => state.ConnectionReducer);

  const [errorMessagesOpen, setErrorMessagesOpen] = useState(false);
  const [selectedErrorMessagesProcess, setSelectedErrorMessagesProcess] = useState(null);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [selectedEventsProcess, setSelectedEventsProcess] = useState(null);

  useEffect(() => {
    dispatch(ProcessesActions.setConnectionId(connectionId));
    dispatch(ConnectionActions.requestData(connectionId));
    dispatch(ProcessesActions.requestData());
    // eslint-disable-next-line
  }, [dispatch]);

  const handlePageChange = (event, newPage) => {
    dispatch(ProcessesActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(ProcessesActions.setPageSize(event.target.value));
  };

  const applyFilter = (key, value) => {
    dispatch(ProcessesActions.applyFilter(key, value));
  };

  const handleClickRetry = process => {
    dispatch(ProcessesActions.retryProcess(connectionId, process));
  };

  const handleClickApprove = process => {
    dispatch(ProcessesActions.approveProcess(connectionId, process));
  };

  const handleClickDecline = process => {
    dispatch(ProcessesActions.declineProcess(connectionId, process));
  };

  const handleClickDownload = async process => {
    try {
      const response = await downloadProcessResultRequest(connectionId, process);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers['x-filename']);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const handleErrorMessagesClick = processId => {
    setErrorMessagesOpen(true);
    setSelectedErrorMessagesProcess(processId);
  };

  const handleErrorMessagesDialogClose = () => {
    setErrorMessagesOpen(false);
    setSelectedErrorMessagesProcess(null);
  };

  const handleEventsClick = processId => {
    setEventsOpen(true);
    setSelectedEventsProcess(processId);
  };

  const handleEventsDialogClose = () => {
    setEventsOpen(false);
    setSelectedEventsProcess(null);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h1">{!loading && connection.connectionName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ProcessesToolbar connectionId={connectionId} applyFilter={applyFilter} />
          <div className={classes.content} />
          <ProcessesTable
            connectionId={connectionId}
            processes={items}
            page={page - 1}
            rowsPerPage={pageSize}
            totalCount={totalCount}
            loading={loading}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handleClickRetry={handleClickRetry}
            handleClickApprove={handleClickApprove}
            handleClickDecline={handleClickDecline}
            handleClickDownload={handleClickDownload}
            handleErrorMessagesClick={handleErrorMessagesClick}
            handleEventsClick={handleEventsClick}
          />
          {errorMessagesOpen && (
            <ProcessMessagesDialog
              open={errorMessagesOpen}
              batchId={selectedErrorMessagesProcess}
              handleClose={handleErrorMessagesDialogClose}
            />
          )}
          {eventsOpen && (
            <ProcessEventsDialog
              open={eventsOpen}
              batchId={selectedEventsProcess}
              handleClose={handleEventsDialogClose}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default BatchesPage;
