import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { get, isEmpty } from 'lodash';

const SearchableSelectFieldComponent = ({ packageType, disabled, field, path }) => {
  const { setFieldValue, values } = useFormikContext();
  const fieldValue = !isEmpty(values) ? get(values, path) : [];
  const orderDescending = 'orderDescending' in field.fieldOptions ? field.fieldOptions.orderDescending : false;
  const fieldOptions = field.fieldOptions.options;
  const fieldOptionsKeys = orderDescending
    ? Object.keys(fieldOptions)
        .sort()
        .reverse()
    : Object.keys(fieldOptions);

  return (
    <Grid key={field.fieldName} item xs={12}>
      <Autocomplete
        disabled={disabled}
        getOptionLabel={option => (option ? fieldOptions[option] : '')}
        options={fieldOptionsKeys}
        color="secondary"
        defaultValue={fieldValue}
        onChange={(event, newValue) => {
          setFieldValue(path, newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            color="secondary"
            fullWidth
            name={path}
            label={<FormattedMessage id={`onboarding.settings.${field.fieldName}`} />}
          />
        )}
      />
    </Grid>
  );
};

export default SearchableSelectFieldComponent;
