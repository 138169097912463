import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { TextField } from 'formik-material-ui';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { isEmpty } from 'lodash';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

const RemoveAccessToOrganizationsForm = ({
  userOrganizations,
  onRemoveSelectedOrganization,
  onSelectOrganization,
  selectedOrganizations,
  resetForm,
}) => {
  return (
    <Grid container>
      <Grid item xs={7}>
        <Field
          color="secondary"
          component={TextField}
          inputProps={{
            onChange: e => {
              onSelectOrganization(e.target.value);
            },
            value: '',
          }}
          select
          name="organizations"
          label={<FormattedMessage id="users.organizationsToRemove" />}
          disabled={!userOrganizations || !userOrganizations.length}
          fullWidth
        >
          {userOrganizations ? (
            userOrganizations.map(userOrganization => (
              <MenuItem key={userOrganization.organizationName} value={userOrganization}>
                {userOrganization.organizationName}
              </MenuItem>
            ))
          ) : (
            <MenuItem />
          )}
        </Field>
      </Grid>
      {!isEmpty(selectedOrganizations) && (
        <Grid item xs={7}>
          <List>
            {selectedOrganizations.map(selectedOrganization => (
              <ListItem key={selectedOrganization.organizationName}>
                <ListItemText primary={selectedOrganization.organizationName} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => onRemoveSelectedOrganization(selectedOrganization, resetForm)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

RemoveAccessToOrganizationsForm.propTypes = {
  userOrganizations: PropTypes.array.isRequired,
};

export default RemoveAccessToOrganizationsForm;
