import * as Yup from 'yup';
import { FieldAdapter } from './FieldAdapter';
import { createValidationByKeys } from './index';

export const customValidations = (function() {
  function float() {
    return Yup.number()
      .strict()
      .test('is-decimal', 'Invalid decimal', value => `${value}`.match(/^\d+(\.\d+)?$/));
  }
  function stringMap() {
    return Yup.array().of(
      Yup.object({
        key: Yup.string().required(),
        value: Yup.string().required(),
      }),
    );
  }

  function tags() {
    return Yup.array().of(Yup.string().required());
  }

  function multiSelect() {
    return Yup.array().of(Yup.string().required());
  }

  function select(field) {
    let validation = Yup.string();

    if (field.fieldOptions.required) {
      validation = validation.required();
    }

    return validation;
  }

  function searchableSelect(field) {
    let validation = Yup.string();

    if (field.fieldOptions.required) {
      validation = validation.required();
    }

    return validation;
  }

  function selectWithTranslations() {
    return Yup.string().required();
  }

  function table(field) {
    let rowSchema = Yup.object().shape({});

    field.fieldOptions.fields.forEach(subField => {
      if (!field.fieldOptions.required) {
        // if main table is not required, make all sub fields not required as well
        subField.fieldOptions.required = false;
      }
      const fieldValidation = FieldAdapter(subField).getValidation();
      const objectValidation = createValidationByKeys([subField.fieldName], fieldValidation);
      rowSchema = rowSchema.concat(objectValidation);
    });

    return Yup.array().of(rowSchema);
  }

  function password() {
    return Yup.string();
  }

  return {
    float,
    stringMap,
    select,
    multiSelect,
    tags,
    table,
    password,
    searchableSelect,
    selectWithTranslations,
  };
})();
