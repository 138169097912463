import { TableCell, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import EventContentTable from '../EventContentTable';

const EventContentTableRow = ({ header, value, renderer }) => {
  let content = value;
  if (typeof renderer === 'function') {
    content = renderer(value, header);
  } else if (value && typeof value === 'object') {
    content = <EventContentTable event={value} ignoreKeys={['@key', '@type', '@id']} collapse />;
  }

  return (
    <TableRow hover>
      <TableCell variant="head">
        {/^\d+$/.test(header) ? header : <FormattedMessage id={`events.content.header.${header}`} />}
      </TableCell>
      <TableCell>{content}</TableCell>
    </TableRow>
  );
};

export default EventContentTableRow;
