import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { isEmpty, set, startCase, values } from 'lodash';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Collapse, Paper } from '@material-ui/core';
import { CREDENTIAL_TYPE_OAUTH, INSURANCE_BRYDGE, NEW_CREDENTIAL_VALUE } from '../../OnboardingWizard/constants';
import FormikTextField from '../../../../../components/FormikTextField';
import { actions as OnboardingActions } from '../../../Ducks/Onboarding.duck';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '35%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OnboardingCredentialForm = ({
  formRef,
  packageCredentials,
  packageCredentialDetails,
  loading,
  initialValues,
  onSubmit,
  onSelectCredential,
  onSelectCredentialType,
  newCredentialForms,
  handleConnect,
  setLoading,
  validationSchema,
  backButtonPressed,
  setBackButton,
}) => {
  const classes = useStyles();

  const { oauthStatus } = useSelector(state => state.CredentialsReducer);
  const [buttonLabel, setButtonLabel] = useState(['credentialRequests.connect']);
  const [buttonId, setButtonId] = useState('credentialRequests.connect');
  const [click, setClick] = useState(true);
  const { oldValues } = useSelector(state => state.OnboardingReducer);
  const [currentValues, setCurrentValues] = useState({});
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    if (!isEmpty(currentValues)) {
      dispatch(OnboardingActions.SetValues(currentValues));
      dispatch(OnboardingActions.SetCanGoBack(true));
    }
    // eslint-disable-next-line
  }, [currentValues]);

  useEffect(() => {
    packageCredentials.forEach(packageType => {
      if (!isEmpty(initialValues)) {
        for (const [key, value] of Object.entries(initialValues.credentials)) {
          let expand = false;
          if (key !== INSURANCE_BRYDGE && value === NEW_CREDENTIAL_VALUE && !isEmpty(oldValues.values)) {
            expand = true;
          }
          setExpanded(prevState => {
            const currentState = { ...prevState };
            set(currentState, [key], expand);
            return currentState;
          });
        }
      }
    });
    // eslint-disable-next-line
  }, [packageCredentials]);

  useEffect(() => {
    if (!isEmpty(oauthStatus)) {
      const button1 = [];
      // eslint-disable-next-line array-callback-return
      Object.keys(oauthStatus).map(key => {
        let credentialExists = false;
        if (!isEmpty(initialValues)) {
          credentialExists = values(initialValues.credentials).includes(key);
        }
        if (credentialExists) {
          if (oauthStatus[key] === 'success') {
            setClick(false);
            button1[key] = 'credentialRequests.connected';
          } else {
            button1[key] = 'credentialRequests.failed';
          }
        } else {
          setClick(true);
        }
      });
      setButtonLabel(button1);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [oauthStatus]);

  const oauthSchemaValidator = packageType => {
    let initialSchema = Yup.object().shape({});
    if (!newCredentialForms[packageType]) {
      return null;
    }
    // eslint-disable-next-line array-callback-return
    newCredentialForms[packageType].fields.map(field => {
      if (newCredentialForms[packageType].credentialType !== CREDENTIAL_TYPE_OAUTH) {
        // eslint-disable-next-line
        return;
      }
      const objectValidation = Yup.object({
        newCredentials: Yup.object({
          [packageType]: Yup.object({
            [field.fieldName]: Yup.string()
              .required()
              .min(2)
              .max(90),
          }),
        }),
      });
      initialSchema = initialSchema.concat(objectValidation);
    });
    return initialSchema;
  };

  const handleClick = async (event, packageType, values, setFieldError, setFieldTouched) => {
    if (click) {
      const schemaValidator = oauthSchemaValidator(packageType);
      const fieldPieces = ['newCredentials', packageType];
      const field = fieldPieces.join('.');
      await schemaValidator
        .validateAt(field, values)
        .then(() => {
          handleConnect(packageType, values, event, setFieldError);
        })
        .catch(err => {
          console.log(err);
          setFieldTouched(field, true, false);
          setFieldError(field, err.message);
        });
    } else {
      event.preventDefault();
    }
  };

  const hiddenField = packageType => (
    <Box display="none">
      <FormikTextField label="hidden" name={`newCredentials.${packageType}.hidden`} fieldType="hidden" />
    </Box>
  );

  const renderNewOauthCredential = (packageType, values, setFieldError, setFieldTouched) => {
    let buttonName = buttonLabel.slice(0, 1).shift();
    if (buttonName === undefined) {
      buttonName = 'credentialRequests.connect';
    }
    if (newCredentialForms[packageType].hasOwnProperty('credentialId')) {
      if (buttonLabel.hasOwnProperty(newCredentialForms[packageType].credentialId)) {
        buttonName = buttonLabel[newCredentialForms[packageType].credentialId];
      }
    }
    setButtonId(buttonName);
    return (
      <Box>
        <Grid key={`${packageType}`} item xs={12}>
          {newCredentialForms[packageType].fields.map(field => (
            <Grid key={field.fieldName} item xs={12}>
              <FormikTextField
                label={<FormattedMessage id={`onboarding.credentials.new.${field.fieldName}`} />}
                name={`newCredentials.${packageType}.${field.fieldName}`}
                fieldType={field.fieldType}
                fieldOptions={field.fieldOptions}
              />
            </Grid>
          ))}
        </Grid>
        <Box pt={2}>
          <Button
            variant="outlined"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => handleClick(e, packageType, values, setFieldError, setFieldTouched)}
          >
            <FormattedMessage id={`${buttonId}`} />
          </Button>
        </Box>
      </Box>
    );
  };

  const renderNewCredential = packageType =>
    // eslint-disable-next-line react/prop-types
    newCredentialForms[packageType].fields.map(field => (
      <Grid key={field.fieldName} item xs={12}>
        <FormikTextField
          label={<FormattedMessage id={`onboarding.credentials.new.${field.fieldName}`} />}
          name={`newCredentials.${packageType}.${field.fieldName}`}
          fieldType={field.fieldType}
          fieldOptions={field.fieldOptions}
        />
      </Grid>
    ));

  const handleSelectCredential = (value, packageType, formValues, setFieldValue) => {
    onSelectCredential(value, packageType);

    // reset the form values in case first NEW_CREDENTIAL_VALUE was selected and then an existing credentials
    if (value !== NEW_CREDENTIAL_VALUE) {
      const { credentialsDetails } = formValues;
      if (credentialsDetails) {
        delete credentialsDetails[packageType];
        setFieldValue(`credentialsDetails`, credentialsDetails);
      }

      const { newCredentials } = formValues;
      if (newCredentials) {
        newCredentials[packageType] = {};
        setFieldValue(`newCredentials`, newCredentials);
      }
    }
  };

  const handleSelectCredentialType = (packageType, selectedCredentialId, selectedCredentialType, setFieldValue) => {
    let expand = false;
    if (selectedCredentialId === NEW_CREDENTIAL_VALUE) {
      expand = true;
      setClick(true);
    }
    setFieldValue(`newCredentials.${packageType}.hidden`, expand);

    setExpanded(prevState => {
      const currentState = { ...prevState };
      set(currentState, [packageType], expand);
      return currentState;
    });
    onSelectCredentialType(selectedCredentialId, packageType, selectedCredentialType);
  };

  const handlePressBack = formValues => {
    if (isEmpty(currentValues)) {
      setCurrentValues(formValues);
      setBackButton(false);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        {loading && <LinearProgress />}
      </Grid>
      {!isEmpty(initialValues) && (
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            onSubmit={(values, form) => onSubmit(values, form)}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, setFieldError, setFieldTouched, setFieldValue, errors }) => (
              <Form ref={formRef}>
                <Grid container className={classes.root}>
                  {packageCredentials.map(({ packageType, credentials }) => {
                    const defaultInputProps = {
                      onChange: e => handleSelectCredential(e.target.value, packageType, values, setFieldValue),
                    };
                    if (packageType === INSURANCE_BRYDGE) {
                      defaultInputProps.value = NEW_CREDENTIAL_VALUE;
                    }
                    if (backButtonPressed) {
                      handlePressBack(values);
                    }

                    const defaultCredentialTypeInputProps = {
                      onChange: e =>
                        handleSelectCredentialType(
                          packageType,
                          values.credentials[packageType],
                          e.target.value,
                          setFieldValue,
                        ),
                    };

                    return (
                      <React.Fragment key={packageType}>
                        <Box width="100%" display={packageType === INSURANCE_BRYDGE ? 'none' : 'block'}>
                          <Grid item xs={7} mt={3}>
                            <Typography variant="h4">{startCase(packageType.replace('-', ' '))}</Typography>
                            <Field
                              color="secondary"
                              component={TextField}
                              inputProps={defaultInputProps}
                              select
                              name={`credentials.${packageType}`}
                              label={<FormattedMessage id="onboarding.credentials.credential" />}
                              fullWidth
                            >
                              <MenuItem value={NEW_CREDENTIAL_VALUE} key={packageType + NEW_CREDENTIAL_VALUE}>
                                <FormattedMessage id="onboarding.credentials.createNewCredential" />
                              </MenuItem>

                              {credentials.length > 0 &&
                                credentials.map(credential => (
                                  <MenuItem key={credential.credentialId} value={credential.credentialId}>
                                    {credential.name}
                                  </MenuItem>
                                ))}
                            </Field>

                            {values.credentials[packageType] === NEW_CREDENTIAL_VALUE && (
                              <Field
                                color="secondary"
                                component={TextField}
                                inputProps={defaultCredentialTypeInputProps}
                                select
                                name={`credentialsDetails.${packageType}.credentialType`}
                                label={<FormattedMessage id="onboarding.credentials.credentialType" />}
                                fullWidth
                              >
                                {Object.keys(packageCredentialDetails[packageType]).length > 0 &&
                                  Object.keys(packageCredentialDetails[packageType]).map(credentialType => (
                                    <MenuItem key={credentialType} value={credentialType}>
                                      {startCase(credentialType.replace('-', ' '))}
                                    </MenuItem>
                                  ))}
                              </Field>
                            )}

                            {values.credentials[packageType] === NEW_CREDENTIAL_VALUE && (
                              <Box pl={3}>
                                <Collapse in={expanded[packageType]} className={classes.content}>
                                  <Paper elevation={0}>
                                    {newCredentialForms[packageType]
                                      ? newCredentialForms[packageType].credentialType === CREDENTIAL_TYPE_OAUTH
                                        ? renderNewOauthCredential(packageType, values, setFieldError, setFieldTouched)
                                        : renderNewCredential(packageType, values)
                                      : null}
                                    {newCredentialForms[packageType] ? hiddenField(packageType) : null}
                                  </Paper>
                                </Collapse>
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Box py={3}>
                              <Divider />
                            </Box>
                          </Grid>
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
};

OnboardingCredentialForm.propTypes = {
  formRef: PropTypes.object.isRequired,
  packageCredentials: PropTypes.array,
  packageCredentialDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSelectCredential: PropTypes.func.isRequired,
  onSelectCredentialType: PropTypes.func.isRequired,
  newCredentialForms: PropTypes.object,
  validationSchema: PropTypes.object.isRequired,
};

export default OnboardingCredentialForm;
