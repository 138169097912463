import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';
import FormikTextField from '../../../../../components/FormikTextField';

const StringMapFieldComponent = ({ packageType, disabled, field }) => {
  const { setFieldValue, values } = useFormikContext();
  const fieldValue = values.settings[packageType][field.fieldName] || [];
  const addRow = () => {
    setFieldValue('settings', {
      ...values.settings,
      [packageType]: { ...values.settings[packageType], [field.fieldName]: [...fieldValue, { key: '', value: '' }] },
    });
  };

  const deleteRow = (removeKey, removeIndex) => {
    setFieldValue('settings', {
      ...values.settings,
      [packageType]: {
        ...values.settings[packageType],
        [field.fieldName]: [...fieldValue.filter(({ key }, index) => index !== removeIndex && key !== removeKey)],
      },
    });
  };

  return (
    <Grid key={field.fieldName} item xs={12}>
      <Typography variant="h5">
        <FormattedMessage id={`onboarding.settings.${field.fieldName}`} />
      </Typography>
      <Grid container spacing={2} alignContent="center" alignItems="center">
        <Grid item xs={12}>
          <Button disabled={disabled} onClick={addRow} variant="contained" color="primary" size="small">
            <FormattedMessage id="button.addRow" />
          </Button>
        </Grid>
        {fieldValue.map((row, index) => (
          <React.Fragment key={index}>
            <Grid item xs={5}>
              <FormikTextField
                disabled={disabled}
                label={<FormattedMessage id={`onboarding.settings.${field.fieldName}.key`} />}
                name={`settings.${packageType}.${field.fieldName}[${index}].key`}
                fieldType="string"
              />
            </Grid>
            <Grid item xs={5}>
              <FormikTextField
                disabled={disabled}
                label={<FormattedMessage id={`onboarding.settings.${field.fieldName}.value`} />}
                name={`settings.${packageType}.${field.fieldName}[${index}].value`}
                fieldType="string"
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={disabled}
                onClick={() => deleteRow(row.key, index)}
                variant="contained"
                color="secondary"
                size="small"
              >
                <FormattedMessage id="button.deleteRow" />
              </Button>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default StringMapFieldComponent;
