import React, { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { NavLink as RouterLink, useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SyncElement } from '../../Components';
import { getConnectionSyncItems } from '../../Services/ConnectionService';
import { actions as ConnectionActions } from '../../Ducks/Connection.duck';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  syncElement: {
    marginTop: theme.spacing(2),
  },
  buttonElement: {
    marginRight: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={index} {...other}>
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ConnectionInsightPage = () => {
  const classes = useStyles();
  const { connectionId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [value, setValue] = useState(0);

  const syncElementName = new URLSearchParams(window.location.search).get('sync-element-name');
  const syncElementKey = new URLSearchParams(window.location.search).get('sync-element-key');
  const syncElementStatus = new URLSearchParams(window.location.search).get('sync-element-status');

  const { loading: loadingConnection, item: connection } = useSelector(state => state.ConnectionReducer);

  const [loadingSyncItems, setLoadingSyncItems] = useState(false);
  const [SyncItems, setSyncItems] = useState([]);

  const getSyncElementIndexByName = (listOfSyncItems, syncItemName) => {
    for (let i = 0; i < listOfSyncItems.length; i++) {
      if (listOfSyncItems[i].name === syncItemName) {
        return i;
      }
    }

    return 0;
  };

  const getSyncItems = async () => {
    setLoadingSyncItems(true);

    const response = await getConnectionSyncItems(connectionId);
    setSyncItems(response.data['hydra:member']);

    if (syncElementName) {
      const syncItemIndex = getSyncElementIndexByName(response.data['hydra:member'], syncElementName);

      setValue(syncItemIndex);
    }

    setLoadingSyncItems(false);
  };

  useEffect(() => {
    dispatch(ConnectionActions.requestData(connectionId));
  }, [dispatch]);

  useEffect(() => {
    if (connection !== undefined && Object.keys(connection).length === 0 && !loadingConnection) {
      history.push('/not-found');
    }

    if (connection !== undefined && Object.keys(connection).length > 0 && !loadingConnection) {
      getSyncItems();
    }
  }, [loadingConnection, connection]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ display: 'inline-block' }}>
      <RouterLink {...props} />
    </div>
  ));

  return (
    <div className={classes.root}>
      {!loadingConnection && !loadingSyncItems && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">{!loadingConnection && connection.connectionName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonElement}
              component={CustomRouterLink}
              to={`/connection/${connectionId}`}
            >
              <FormattedMessage id="button.overview" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange}>
                  {SyncItems.map(syncItem => (
                    <Tab label={syncItem.name} />
                  ))}
                  <Tab label="failed" />
                </Tabs>
              </Box>

              {!loadingSyncItems &&
                SyncItems.map((syncItem, index) => (
                  <TabPanel value={value} index={index}>
                    <SyncElement
                      key={syncItem.name}
                      connectionId={connectionId}
                      syncElementName={syncItem.name}
                      className={classes.syncElement}
                      syncKeyToSearch={syncElementKey && syncElementName === syncItem.name ? syncElementKey : null}
                      defaultSyncStatus={
                        syncElementKey && syncElementName === syncItem.name && syncElementStatus
                          ? syncElementStatus
                          : null
                      }
                    />
                  </TabPanel>
                ))}

              <TabPanel value={value} index={SyncItems.length}>
                {!loadingSyncItems &&
                  SyncItems.map(syncItem => (
                    <SyncElement
                      key={syncItem.name}
                      connectionId={connectionId}
                      syncElementName={syncItem.name}
                      className={classes.syncElement}
                      defaultSyncStatus="failed"
                    />
                  ))}
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ConnectionInsightPage;
