import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, TextField } from 'formik-material-ui';
import { Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { MenuItem } from '@material-ui/core';
import { useIntl } from 'react-intl';

const FormikTextField = ({ name, label, fieldType, fieldOptions, disabled, update = false }) => {
  const intl = useIntl();

  if (fieldType === 'checkbox')
    return (
      <Typography>
        {label}
        <Field
          color="primary"
          type={fieldType}
          component={Checkbox}
          name={name}
          label={label}
          fullWidth
          disabled={disabled}
        />
      </Typography>
    );
  if (fieldType === 'number')
    return (
      <Typography>
        {label}
        <Field
          disabled={disabled}
          color="primary"
          type={fieldType}
          inputProps={{ step: '0.1' }}
          component={TextField}
          name={name}
          label={label}
          fullWidth
        />
      </Typography>
    );

  if (fieldType === 'select') {
    return (
      <Grid item xs={12}>
        <Field
          disabled={disabled}
          color="secondary"
          type={fieldType}
          component={TextField}
          name={name}
          label={label}
          fullWidth
          select
        >
          {Object.keys(fieldOptions.options).map(id => (
            <MenuItem key={id} value={id}>
              {fieldOptions.options[id]}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    );
  }

  if (fieldType === 'password' && update) {
    const placeholderText = intl.formatMessage({ id: 'formiktextfield.password.placeholder' });
    return (
      <Typography>
        {label}
        <Field
          disabled={disabled}
          color="secondary"
          type={fieldType}
          component={TextField}
          name={name}
          placeholder={placeholderText}
          fullWidth
          autoComplete="new-password"
        />
      </Typography>
    );
  }

  return (
    <Field
      disabled={disabled}
      color="secondary"
      type={fieldType}
      component={TextField}
      name={name}
      label={label}
      fullWidth
    />
  );
};

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  fieldType: PropTypes.string.isRequired,
};

export default FormikTextField;
