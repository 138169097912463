import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AppActions } from '../../../../Domain/App/Ducks/App.duck';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading } = useSelector(state => state.AppReducer);

  const [notifications] = useState(['test']);

  const handleLogout = () => {
    dispatch(AppActions.logout());
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <div className={classes.flexGrow} />
        {loading && <CircularProgress color="secondary" />}
        <Hidden mdDown>
          {notifications.length > 0 && (
            <IconButton color="inherit">
              <Badge badgeContent={notifications.length} color="primary" variant="dot">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
          <Tooltip title="Logout">
            <IconButton className={classes.signOutButton} onClick={handleLogout} color="inherit">
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
