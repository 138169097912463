import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';
import { getConnections, deleteConnection, activateConnection, deactivateConnection } from '../Services/ConnectionService';

export const actionTypes = {
  InitConnectionState: '[Connections] Initialize',
  RequestData: '[Connections] Request',
  FulfilledTable: '[Connections] Fulfilled',
  SearchTable: '[Connections] Search',
  ChangePage: '[Connections] change page',
  SetPageSize: '[Connections] set page size',
  SortTable: '[Connections] Sort',
  ApplyFilter: '[Connections] Apply filter',
  RemoveFilter: '[Connections] Remove filter',
  SetSelectedRow: '[Connections] Set selected row',
  AddTenant2SelectedRow: '[Connections] Add tenant to selected row tenants',
  RemoveTenantFromSelectedRow: '[Connections] Remove tenant from selected row tenants',
  DeleteOneConnection: '[Connections] Delete a connection',
  ActivateOneConnection: '[Connections] Activate a connection',
  DeactivateOneConnection: '[Connections] Deactivate a connection',
};

const initialState = {
  ...tableInitialState,
  selectedRowTenants: [],
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.InitConnectionState: {
      return { ...initialState };
    }
    case actionTypes.SetSelectedRow: {
      return {
        ...state,
        selectedRow: action.selectedRow,
      };
    }
    case actionTypes.DeleteOneConnection: {
      return { ...state };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initConnectionPage: () => ({
    type: actionTypes.InitConnectionState,
  }),
  deleteOneConnection: () => {
    return {
      type: actionTypes.DeleteOneConnection,
    };
  },
  activateOneConnection: () => {
    return {
      type: actionTypes.ActivateOneConnection,
    };
  },
  deactivateOneConnection: () => {
    return {
      type: actionTypes.DeactivateOneConnection,
    };
  },
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestEmailsSaga() {
    const currentState = yield select(state => {
      return state.ConnectionsReducer;
    });

    const response = yield getConnections(
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });

  yield takeLatest(actionTypes.DeleteOneConnection, function* deleteOneConnection() {
    const currentState = yield select(state => {
      return state.ConnectionsReducer;
    });
    yield deleteConnection(currentState.selectedRow.connectionId);
    yield put(actions.requestData());
  });

  yield takeLatest(actionTypes.ActivateOneConnection, function* activateOneConnection() {
    const currentState = yield select(state => {
      return state.ConnectionsReducer;
    });
    yield activateConnection(currentState.selectedRow.connectionId);
    yield put(actions.requestData());
  });

  yield takeLatest(actionTypes.DeactivateOneConnection, function* deactivateOneConnection() {
    const currentState = yield select(state => {
      return state.ConnectionsReducer;
    });
    yield deactivateConnection(currentState.selectedRow.connectionId);
    yield put(actions.requestData());
  });
}
