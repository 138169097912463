import React from 'react';
import PropTypes from 'prop-types';
import UpdateConnectionCredentialForm from '../../Forms/UpdateConnectionCredentialForm/UpdateConnectionCredentialForm';

const CredentialStep = ({ formRef, onSubmit, initialValues, credentials, loading }) => {
  return (
    <UpdateConnectionCredentialForm
      formRef={formRef}
      loading={loading}
      initialValues={initialValues}
      packageCredentials={credentials}
      onSubmit={onSubmit}
    />
  );
};

CredentialStep.propTypes = {
  formRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CredentialStep;
