import React from 'react';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloProvider } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { onError } from 'apollo-link-error';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';

const DbApolloProvider = ({ children }) => {
  const { authToken } = useSelector(state => state.AppReducer);
  const dispatch = useDispatch();

  const errorLink = onError(({ networkError }) => {
    if (networkError && networkError.statusCode === 401) {
      dispatch(AppActions.logout());
    }
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
      },
    };
  });

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(errorLink).concat(httpLink),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default DbApolloProvider;
