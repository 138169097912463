import axios from 'axios';
import { merge } from 'lodash';

let getProcessesToken;
export const getProcesses = async (connectionId, page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getProcessesToken !== typeof undefined) {
      getProcessesToken.cancel('Operation canceled due to new request.');
    }

    getProcessesToken = axios.CancelToken.source();

    return axios.get(`/${connectionId}/data/insurance-brydge/batches`, {
      cancelToken: getProcessesToken.token,
      params: merge(
        {
          page,
          perPage,
          sortBy,
          globalFilter,
        },
        filters,
      ),
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const getProcessItem = async processUrl => {
  try {
    return axios.get(processUrl.replace('/api', ''));
  } catch (err) {
    throw err;
  }
};

export const retryProcessRequest = async (connectionId, process) => {
  try {
    return axios.post(`/connection/${connectionId}/module/run`, {
      connectionId,
      moduleType: 'insurance_brydge_process_batch_module',
      parameters: {
        batchId: process.batchId,
      },
    });
  } catch (err) {
    throw err;
  }
};

export const approveProcessRequest = async (connectionId, process) => {
  try {
    return axios.post(`/connection/${connectionId}/module/run`, {
      connectionId,
      moduleType: 'insurance_brydge_approve_batch_module',
      parameters: {
        batchId: process.batchId,
      },
    });
  } catch (err) {
    throw err;
  }
};

export const declineProcessRequest = async (connectionId, process) => {
  try {
    return axios.post(`/connection/${connectionId}/module/run`, {
      connectionId,
      moduleType: 'insurance_brydge_decline_batch_module',
      parameters: {
        batchId: process.batchId,
      },
    });
  } catch (err) {
    throw err;
  }
};

export const downloadProcessResultRequest = async (connectionId, process) => {
  try {
    return axios.get(`/${connectionId}/data/insurance-brydge/batches/${process.mongoId}/download`);
  } catch (err) {
    throw err;
  }
};

export default { getProcesses };
