import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';
import {
  activateUser,
  deactivateUser,
  createUser,
  deleteUser,
  editUser,
  getUsers,
  removeAccessToOrganization,
} from '../Services/UserService';

export const actionTypes = {
  InitializeUsersState: '[Users] Initialize state',
  RequestData: '[Users] Request',
  FulfilledTable: '[Users] Fulfilled',
  SearchTable: '[Users] Search',
  ChangePage: '[Users] change page',
  SetPageSize: '[Users] set page size',
  SortTable: '[Users] Sort',
  ApplyFilter: '[Users] Apply filter',
  RemoveFilter: '[Users] Remove filter',
  ToggleUser: '[Users] Toggle user',
  ToggleAllUsers: '[Users] Toggle all users',
  SetSelectedOrganizations: '[Users] Set selected organizations',
  SetUserOrganizations: '[Users] Set user organizations',
  SetSelectedUser: '[Users] Set selected user',
  RemoveAccessToOrganizations: '[Users] Remove access to organizations',
  ClearAll: '[Users] Clear all',
  CreateUser: '[Users] Create new user',
  DeleteUser: '[Users] Delete a user',
  EditUser: '[Users] Edit new user',
  ActivateUser: '[Users] Activate user',
  DeactivateUser: '[Users] Deactivate user',
};

const initialState = {
  ...tableInitialState,
  selectedOrganizations: [],
  userOrganizations: [],
  selectedUser: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.InitializeUsersState: {
      return { ...initialState };
    }
    case actionTypes.ToggleUser: {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.userId === action.event.userId) {
            item.selectedRow = !item.selectedRow;
          }
          return item;
        }),
      };
    }
    case actionTypes.ToggleAllUsers: {
      const isSelectAll = action.events.filter(item => !item.selectedRow).length;
      return {
        ...state,
        items: action.events.map(item => {
          item.selectedRow = !!isSelectAll;
          return item;
        }),
      };
    }
    case actionTypes.SetUserOrganizations: {
      return {
        ...state,
        userOrganizations: action.userOrganizations,
      };
    }
    case actionTypes.SetSelectedOrganizations: {
      return {
        ...state,
        selectedOrganizations: action.selectedOrganizations,
      };
    }
    case actionTypes.SetSelectedUser: {
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    }
    case actionTypes.RemoveAccessToOrganizations: {
      return {
        ...state,
      };
    }
    case actionTypes.CreateUser: {
      return {
        ...state,
      };
    }
    case actionTypes.DeleteUser: {
      return {
        ...state,
      };
    }
    case actionTypes.EditUser: {
      return {
        ...state,
      };
    }
    case actionTypes.ActivateUser: {
      return {
        ...state,
      };
    }
    case actionTypes.DeactivateUser: {
      return {
        ...state,
      };
    }
    case actionTypes.ClearAll: {
      return {
        ...state,
        userOrganizations: [],
        selectedOrganizations: [],
        selectedUser: null,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initializeUsersState: () => ({
    type: actionTypes.InitializeUsersState,
  }),
  toggleUser: event => {
    return {
      type: actionTypes.ToggleUser,
      event,
    };
  },
  toggleAllUsers: events => ({
    type: actionTypes.ToggleAllUsers,
    events,
  }),
  setSelectedOrganizations: selectedOrganizations => ({
    type: actionTypes.SetSelectedOrganizations,
    selectedOrganizations,
  }),
  setUserOrganizations: userOrganizations => ({
    type: actionTypes.SetUserOrganizations,
    userOrganizations,
  }),
  setSelectedUser: selectedUser => ({
    type: actionTypes.SetSelectedUser,
    selectedUser,
  }),
  removeAccessToOrganizations: (organizationsIds, userId) => ({
    type: actionTypes.RemoveAccessToOrganizations,
    organizationsIds,
    userId,
  }),
  clearAll: () => ({
    type: actionTypes.ClearAll,
  }),
  createUser: userValues => ({
    type: actionTypes.CreateUser,
    userValues,
  }),
  deleteUser: userId => ({
    type: actionTypes.DeleteUser,
    userId,
  }),
  editUser: userValues => ({
    type: actionTypes.EditUser,
    userValues,
  }),
  activateUser: () => ({
    type: actionTypes.ActivateUser,
  }),
  deactivateUser: () => ({
    type: actionTypes.DeactivateUser,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RemoveAccessToOrganizations, function* removeAccessToOrganizationsSaga(action) {
    yield removeAccessToOrganization(action.organizationsIds, action.userId);
    yield put(actions.requestData());
  });

  yield takeLatest(actionTypes.CreateUser, function* createUserSaga(action) {
    yield createUser(action.userValues);
    yield reloadData();
  });

  yield takeLatest(actionTypes.DeleteUser, function* deleteUserSaga() {
    const currentState = yield select(state => {
      return state.UsersReducer;
    });
    yield deleteUser(currentState.selectedUser.userId);
    yield reloadData();
  });

  yield takeLatest(actionTypes.EditUser, function* editUserSaga(action) {
    yield editUser(action.userValues);
    yield reloadData();
  });

  yield takeLatest(actionTypes.ActivateUser, function* activateUserSaga() {
    const currentState = yield select(state => {
      return state.UsersReducer;
    });
    yield activateUser(currentState.selectedUser.userId);
    yield reloadData();
  });

  yield takeLatest(actionTypes.DeactivateUser, function* deactivateUserSaga() {
    const currentState = yield select(state => {
      return state.UsersReducer;
    });
    yield deactivateUser(currentState.selectedUser.userId);
    yield reloadData();
  });

  yield takeLatest(actionTypes.RequestData, function* requestEmailsSaga() {
    const currentState = yield select(state => {
      return state.UsersReducer;
    });

    const response = yield getUsers(
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
