import React from 'react';
import { Form, Field, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  saveDetailsButton: {
    margin: theme.spacing(1),
  },
}));

const EditUserProfileForm = () => {
  const classes = useStyles();
  const { isSubmitting, submitForm, isValid } = useFormikContext();

  return (
    <Form>
      <Card>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Field
                required
                color="secondary"
                component={TextField}
                name="firstName"
                label={<FormattedMessage id="users.firstName" />}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                color="secondary"
                component={TextField}
                name="middleName"
                label={<FormattedMessage id="users.middleName" />}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                required
                color="secondary"
                component={TextField}
                name="lastName"
                label={<FormattedMessage id="users.lastName" />}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                required
                type="email"
                color="secondary"
                component={TextField}
                name="emailAddress"
                label={<FormattedMessage id="users.email" />}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            className={classes.saveDetailsButton}
            onClick={submitForm}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || !isValid}
          >
            <FormattedMessage id="button.saveDetails" />
          </Button>
        </Box>
      </Card>
    </Form>
  );
};

export default EditUserProfileForm;
