import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ConnectionManagementPage from './ConnectionManagementPage';
import ConnectionDetailsPage from './ConnectionDetailsPage';
import InsuranceBrydgePages from '../Packages/InsuranceBrydge/Pages';
import PaymentTogglePages from '../Packages/PaymentToggle/Pages';
import ConnectionInsightPage from './ConnectionInsightPage';
import ConnectionCurlPage from './ConnectionsCurlPage';
import ConnectionCurlDetailsPage from './ConnectionsCurlDetailsPage';

const ConnectionPages = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={InsuranceBrydgePages} path={`${path}/insurance-brydge`} />
      <Route component={PaymentTogglePages} path={`${path}/payment-toggle`} />
      <Route component={ConnectionInsightPage} exact path={`${path}/:connectionId/insight`} />
      <Route component={ConnectionCurlPage} exact path={`${path}/:connectionId/curls`} />
      <Route component={ConnectionCurlDetailsPage} exact path={`${path}/:connectionId/curls/:curlId`} />
      <Route component={ConnectionDetailsPage} exact path={`${path}/:connectionId`} />
      <Route component={ConnectionManagementPage} exact path="" />
    </Switch>
  );
};

export default ConnectionPages;
