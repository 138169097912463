import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0,
  },
  center: {
    verticalAlign: 'middle',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  actionTableCell: {
    minWidth: '220px',
  },
  reversalRunActionTableCell: {
    minWidth: '170px',
  },
  actionList: {
    listStyleType: 'none',
  },
}));

const RunsTable = props => {
  const {
    className,
    connectionId,
    runs,
    onCLickDownloadInitialRun,
    onCLickDownloadReversalRun,
    handleClickStartReversalRun,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    disableCard,
    paging,
    ...rest
  } = props;

  const classes = useStyles();
  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="connections.paymentToggle.runReport.id" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.paymentToggle.runReport.runType" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.paymentToggle.runReport.createdAt" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.paymentToggle.runReport.numberOfDebtorsInScope" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.paymentToggle.runReport.numberOfDebtorsIgnored" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.paymentToggle.runReport.numberOfDebtorsChanged" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.paymentToggle.runReport.status" />
            </TableCell>
            <TableCell className={classes.actionTableCell}>
              <FormattedMessage id="connections.paymentToggle.runReport.actions" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.paymentToggle.runReport.reversalRuns" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(rowsPerPage)
              .fill(index => index)
              .map((value, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
          {!loading && runs.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No runs found</TableCell>
            </TableRow>
          )}
          {!loading &&
            runs.length > 0 &&
            runs.map(run => (
              <TableRow key={run.runId}>
                <TableCell>{run.runId}</TableCell>
                <TableCell>
                  <FormattedMessage id={`connections.paymentToggle.runReport.runType.${run.runType}`} />
                </TableCell>
                <TableCell>
                  {run.createdAt && (
                    <>
                      <FormattedDate value={run.createdAt} />
                      &nbsp;
                      <FormattedTime value={run.createdAt} />
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <a
                    href={`/connection/${connectionId}/insight?sync-element-name=initial-run-debtor&sync-element-key=${run.runId}`}
                  >
                    {run.numberOfDebtorsInScope}
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    href={`/connection/${connectionId}/insight?sync-element-name=initial-run-debtor&sync-element-key=${run.runId}&sync-element-status=ignored`}
                  >
                    {run.numberOfDebtorsIgnored}
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    href={`/connection/${connectionId}/insight?sync-element-name=initial-run-debtor&sync-element-key=${run.runId}&sync-element-status=complete`}
                  >
                    {run.numberOfDebtorsChanged}
                  </a>
                </TableCell>
                <TableCell>{run.status}</TableCell>
                <TableCell>
                  <ul className={classes.actionList}>
                    <li>
                      <Button onClick={() => onCLickDownloadInitialRun(run.runId)} size="small">
                        <FormattedMessage id="connections.paymentToggle.runReport.download" />
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={() => handleClickStartReversalRun(run.runId)}
                        disabled={run.status !== 'complete'}
                        size="small"
                      >
                        <FormattedMessage id="connections.paymentToggle.runReport.reverse" />
                      </Button>
                    </li>
                  </ul>
                </TableCell>
                <TableCell>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="connections.paymentToggle.runReport.id" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="connections.paymentToggle.runReport.runType" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="connections.paymentToggle.runReport.createdAt" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="connections.paymentToggle.runReport.numberOfDebtorsInScope" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="connections.paymentToggle.runReport.reversalRun.numberOfDebtorsChangedInMeantime" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="connections.paymentToggle.runReport.reversalRun.numberOfDebtorsChanged" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="connections.paymentToggle.runReport.status" />
                        </TableCell>
                        <TableCell className={classes.reversalRunActionTableCell}>
                          <FormattedMessage id="connections.paymentToggle.runReport.actions" />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {run.reversalRuns.length > 0 &&
                        run.reversalRuns.map(reversalRun => (
                          <TableRow key={reversalRun.id}>
                            <TableCell>{reversalRun.id}</TableCell>
                            <TableCell>
                              <FormattedMessage
                                id={`connections.paymentToggle.runReport.runType.${reversalRun.type}`}
                              />
                            </TableCell>
                            <TableCell>
                              {reversalRun.createdAt && (
                                <>
                                  <FormattedDate value={reversalRun.createdAt} />
                                  &nbsp;
                                  <FormattedTime value={reversalRun.createdAt} />
                                </>
                              )}
                            </TableCell>
                            <TableCell>
                              <a
                                href={`/connection/${connectionId}/insight?sync-element-name=reversal-run-debtor&sync-element-key=${reversalRun.id}`}
                              >
                                {reversalRun.numberOfDebtorsInScope}
                              </a>
                            </TableCell>
                            <TableCell>{reversalRun.numberOfDebtorsChangedInMeantime}</TableCell>
                            <TableCell>
                              <a
                                href={`/connection/${connectionId}/insight?sync-element-name=reversal-run-debtor&sync-element-key=${reversalRun.id}&sync-element-status=complete`}
                              >
                                {reversalRun.numberOfDebtorsChanged}
                              </a>
                            </TableCell>
                            <TableCell>{reversalRun.status}</TableCell>
                            <TableCell>
                              <Button onClick={() => onCLickDownloadReversalRun(reversalRun.id)} size="small">
                                <FormattedMessage id="connections.paymentToggle.runReport.download" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );

  if (disableCard) {
    return table;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.center}>{table}</div>
        </PerfectScrollbar>
      </CardContent>
      {paging && (
        <CardActions className={classes.actions}>
          <TablePagination
            showFirstButton
            showLastButton
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      )}
    </Card>
  );
};

RunsTable.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.bool,
  connectionId: PropTypes.string.isRequired,
  runs: PropTypes.array.isRequired,
};

RunsTable.defaultProps = {
  paging: true,
  className: '',
};

export default RunsTable;
