import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { Select, SelectProps, fieldToSelect } from 'formik-material-ui';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from 'formik-material-ui-pickers';
import { Button, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';
import { useSelector } from 'react-redux';

const ConnectionRunForm = () => {
  const { selectedRow } = useSelector(state => state.ConnectionsReducer);
  const selectedPackage = selectedRow?.packages.find(({ packageType }) => packageType === 'xero');
  const { tenants } = selectedPackage.settings;

  let tagsControl = '';

  function CustomSelect(props: SelectProps) {
    const {
      form: { setFieldValue },
      field: { name },
    } = props;

    const onChange = React.useCallback(
      event => {
        const { value } = event.target;

        console.log(tagsControl, value);
        if (value.indexOf('controls') !== -1) {
          console.log('controlssss');
          tagsControl && tagsControl === 'all' ? setFieldValue(name, tenants) : setFieldValue(name, []);
        } else {
          tagsControl = '';
          setFieldValue(name, value);
        }
      },
      [setFieldValue, name],
    );
    return <MuiSelect {...fieldToSelect(props)} onChange={onChange} />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Field
          required
          color="secondary"
          component={DatePicker}
          name="date"
          format="yyyy-MM-dd"
          label={<FormattedMessage id="connections.date" />}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel shrink htmlFor="tenants">
          Tenants:
        </InputLabel>
        <Field
          required
          color="secondary"
          component={CustomSelect}
          name="tenants"
          multiple
          value={tenants}
          label={<FormattedMessage id="connections.tenants" />}
          fullWidth
        >
          <MenuItem value="controls">
            <Button
              onClick={() => {
                tagsControl = 'all';
              }}
            >
              All
            </Button>
            <Button
              onClick={() => {
                tagsControl = 'reset';
              }}
            >
              Reset
            </Button>
          </MenuItem>
          {Object.keys(tenants)
            .sort((a, b) => tenants[a].localeCompare(tenants[b]))
            .map(option => (
              <MenuItem key={option} value={option}>
                {tenants[option]}
              </MenuItem>
            ))}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <InputLabel shrink htmlFor="extractionType">
          Select Extraction:
        </InputLabel>
        <Field
          required
          color="secondary"
          component={Select}
          name="extractionType"
          label={<FormattedMessage id="connections.extractionType" />}
          fullWidth
        >
          <MenuItem value="xeinadin_group_start_reports_extraction">Trial Balances</MenuItem>
          <MenuItem value="xeinadin_group_start_invoices_extraction">Invoices</MenuItem>
          <MenuItem value="xeinadin_group_start_contacts_extraction">Contacts</MenuItem>
        </Field>
      </Grid>
    </Grid>
  );
};

export default ConnectionRunForm;
