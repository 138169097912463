import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import WizardForm from '../../Wizard/WizardFrom';
import SetupTwoFactorAuthenticationFrom from '../../../Forms/TwoFactorAuthentication/SetupTwoFactorAuthenticationFrom';
import { setupTwoFactorAuthentication } from '../../../Services/TwoFactorAuthenticatioinService';
import { normalizeErrors } from '../../../../../utils/dataAccess';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const SetupTwoFactorAuthenticationStep = ({ onSubmit, setQrCodeImage, user, onCancel }) => {
  const classes = useStyles();

  const [initialValues] = useState({
    userId: user.userId,
    twoFactorMethod: 'totp',
    password: '',
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(),
  });

  const handleSubmit = async (values, form) => {
    try {
      const response = await setupTwoFactorAuthentication(values);
      setQrCodeImage(response.data.qrCodeImage);

      onSubmit(values);
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);
        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <WizardForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onCancel={onCancel}
      >
        <SetupTwoFactorAuthenticationFrom />
      </WizardForm>
    </div>
  );
};

SetupTwoFactorAuthenticationStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setQrCodeImage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
};

SetupTwoFactorAuthenticationStep.defaultProps = {
  onCancel: null,
};

export default SetupTwoFactorAuthenticationStep;
