import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CredentialsManagementPage from './CredentialsManagementPage';
// import CredentialRequestsManagementPage from './CredentialRequestsManagementPage';
import OauthCredentialResponsePage from './OauthCredentialResponsePage/OauthCredentialResponsePage';

const CredentialsPages = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route component={CredentialRequestsManagementPage} exact path={`${path}/requests`} /> */}
      <Route component={OauthCredentialResponsePage} exact path={`${path}/oauth/:status/:credentialId`} />
      <Route component={CredentialsManagementPage} exact path="" />
    </Switch>
  );
};

export default CredentialsPages;
