import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import FormikModal from '../../../../components/FormikModal';
import UpdateEmailForm from '../../Forms/UpdateEmailForm';
import { updateEmail } from '../../Services/AccountService';
import { normalizeErrors } from '../../../../utils/dataAccess';

const UpdateEmail = ({ showUpdateEmailModal, setUpdateEmailModalOpen }) => {
  const { user } = useSelector(state => state.AppReducer);

  const [updateEmailInitialValues] = useState({
    userId: user.userId,
    oldEmailAddress: '',
    newEmailAddress: '',
    newEmailAddressConfirmation: '',
  });

  let validationSchema = Yup.object().shape({
    oldEmailAddress: Yup.string()
      .email()
      .required(),
    newEmailAddress: Yup.string()
      .email()
      .required(),
    newEmailAddressConfirmation: Yup.string()
      .email()
      .required()
      .oneOf([Yup.ref('newEmailAddress'), null], 'Emails must match'),
  });

  if (user.totpAuthenticationEnabled) {
    validationSchema = validationSchema.concat(Yup.object({ code: Yup.string().required() }));

    Object.assign(updateEmailInitialValues, { code: '' });
  }

  const handleUpdateEmail = async (values, form) => {
    try {
      await updateEmail(values);
      setUpdateEmailModalOpen(false);
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }

      throw e;
    }

    form.resetForm();
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={updateEmailInitialValues} onSubmit={handleUpdateEmail}>
      <FormikModal
        show={showUpdateEmailModal}
        onHide={() => setUpdateEmailModalOpen(false)}
        title={<FormattedMessage id="account.updateEmail" />}
      >
        <UpdateEmailForm displayTwoFactorAuthenticationCodeField={user.totpAuthenticationEnabled} />
      </FormikModal>
    </Formik>
  );
};

export default UpdateEmail;
