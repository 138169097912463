import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, LinearProgress } from '@material-ui/core';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import EnableTwoFactorAuthenticationFrom from '../../../Forms/TwoFactorAuthentication/EnableTwoFactorAuthenticationFrom';
import WizardForm from '../../Wizard/WizardFrom';
import { enableTwoFactorAuthentication } from '../../../Services/TwoFactorAuthenticatioinService';
import { normalizeErrors } from '../../../../../utils/dataAccess';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const EnableTwoFactorAuthenticationStep = ({ onSubmit, qrCodeImage, user, onCancel, buttonTitle }) => {
  const classes = useStyles();

  const [initialValues] = useState({
    userId: user.userId,
    code: '',
  });

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(),
  });

  const handleSubmit = async (values, form) => {
    try {
      await enableTwoFactorAuthentication(values);

      onSubmit(values);
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);
        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        {!qrCodeImage && <LinearProgress />}
      </Grid>

      <Grid item xs={12}>
        <FormattedMessage id="account.enableTwoFactorAuthenticationMessage" />
      </Grid>

      <Grid item xs={12}>
        {qrCodeImage && <img src={qrCodeImage} alt="QrCode" />}
      </Grid>

      <WizardForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onCancel={onCancel}
        buttonTitle={buttonTitle}
      >
        <EnableTwoFactorAuthenticationFrom />
      </WizardForm>
    </div>
  );
};

EnableTwoFactorAuthenticationStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  qrCodeImage: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  buttonTitle: PropTypes.string,
};

EnableTwoFactorAuthenticationStep.defaultProps = {
  onCancel: null,
  buttonTitle: 'next',
};

export default EnableTwoFactorAuthenticationStep;
