import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import EventContentTableRow from '../EventContentTableRow';

const EventContentTable = ({ event, ignoreKeys, keyRenderers, collapse }) => {
  const [expanded, setExpanded] = useState(false);

  const content = (
    <Table>
      <TableBody>
        {Object.keys(event)
          .filter(key => !ignoreKeys.includes(key))
          .map(key => (
            <EventContentTableRow key={key} header={key} value={event[key]} renderer={keyRenderers[key]} />
          ))}
      </TableBody>
    </Table>
  );

  if (!collapse) {
    return content;
  }

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} />
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

EventContentTable.propTypes = {
  event: PropTypes.object.isRequired,
  keyRenderers: PropTypes.object,
  collapse: PropTypes.bool,
  ignoreKeys: PropTypes.array,
};

EventContentTable.defaultProps = {
  collapse: false,
  ignoreKeys: ['@id', 'mongoId', 'connectionId'],
  keyRenderers: {
    annualWage: value => Intl.NumberFormat('nl-nl', { style: 'currency', currency: 'EUR' }).format(value),
    hrEmployee: value => <span>{`${value.personName.firstName} ${value.personName.middleName} ${value.personName.lastName} (${value.syncStatus})`}</span>,
    processed: value => (value ? <CheckIcon /> : <ClearIcon />),
    archived: value => (value ? <CheckIcon /> : <ClearIcon />),
    children: value =>
      value.length > 0 &&
      value.map(child => <EventContentTable event={child} ignoreKeys={['@key', '@type', '@id']} collapse />),
    address: value =>
      value && (
        <>
          <Typography component="p">
            <span>{`${value.street} ${value.houseNumber}`}</span>
            {value.houseNumberSuffix && <span>{` ${value.houseNumberSuffix}`}</span>}
          </Typography>
          <Typography component="p">{`${value.zipCode} ${value.city}`}</Typography>
          <Typography>{value.country}</Typography>
        </>
      ),
  },
};

export default EventContentTable;
