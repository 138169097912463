import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { IntlProvider } from 'react-intl';
import axios from 'axios';
import { PersistGate } from 'redux-persist/integration/react';
import { setLocale } from 'yup';
import store, { persistor } from 'store';
import { Provider, useSelector } from 'react-redux';
import enTranslations from 'translations/en.json';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import getValidationMessages from './common/yupDefaultValidationMessages';
import setupAxios from './utils/setupAxios';
import DbApolloProvider from './components/DbApolloProvider';
import palette from './theme/palette';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

setLocale(getValidationMessages(enTranslations));

setupAxios(axios, store);

const PlatformSettingsAvailable = () => {
  const { platformSettings } = useSelector(state => state.AppReducer);

  useEffect(() => {
    document.title = platformSettings.platformName;
    document.getElementById('favicon').href = platformSettings.faviconUrl;
  }, [platformSettings]);

  return (
    <DbApolloProvider>
      <IntlProvider locale="nl" messages={enTranslations}>
        <ThemeProvider theme={theme(platformSettings.themeSettings || palette)}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </ThemeProvider>
      </IntlProvider>
    </DbApolloProvider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <PlatformSettingsAvailable />
      </PersistGate>
    </Provider>
  );
};

export default App;
