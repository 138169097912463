import get from 'lodash/get';
import moment from 'moment';
import { EVENT_TYPES } from '../Domain/Connections/Packages/InsuranceBrydge/Pages/EventsPage/constants';
import { formatName } from './nameFormatter';

const events = EVENT_TYPES.reduce((value, key) => ({ ...value, [key]: key }), {});

export default function eventDetailsExtractor(event) {
  switch (event['@type']) {
    case events.NewEmployeeEvent:
      return get(event, 'employee.employmentStart') ? moment(get(event, 'employee.employmentStart')).format('L') : null;
    case events.ContractUpdatedEvent:
      return get(event, 'employment.startDate') ? moment(get(event, 'employment.startDate')).format('L') : null;
    case events.EmployeeAddressUpdatedEvent:
    case events.EmployeeInformationUpdatedEvent:
      return moment(event.eventDate).format('L');
    // case events.EmployeePartTimePercentageUpdatedEvent:
    //   break;
    case events.EmployeePartnerUpdatedEvent:
      return formatName(event);
    case events.GrossWageUpdatedEvent:
      return Intl.NumberFormat('nl-nl', { style: 'currency', currency: 'EUR' }).format(get(event, 'annualWage'));
    case events.EmployeeLeftCompanyEvent:
      return get(event, 'employment.endDate') ? moment(get(event, 'employment.endDate')).format('L') : null;
    case events.NewPeriodicWageEvent:
      return Intl.NumberFormat('nl-nl', { style: 'currency', currency: 'EUR' }).format(get(event, 'periodWage'));
    default:
      return '';
  }
}
