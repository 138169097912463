import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as App from 'Domain/App/Ducks/App.duck';
import * as Credentials from 'Domain/Credentials/Ducks/Credentials.duck';
import * as Users from 'Domain/Users/Ducks/Users.duck';
import * as Connections from 'Domain/Connections/Ducks/Connections.duck';
import * as Connection from 'Domain/Connections/Ducks/Connection.duck';
import * as LogEntries from 'Domain/Connections/Ducks/LogEntries.duck';
import * as Tasks from 'Domain/Connections/Ducks/Tasks.duck';
import * as SyncStatus from 'Domain/Connections/Ducks/SyncStatus.duck';
import * as Modules from 'Domain/Connections/Ducks/Modules.duck';
import * as Events from 'Domain/Connections/Ducks/Events.duck';
import * as Processes from 'Domain/Connections/Ducks/Processes.duck';
import * as Onboarding from 'Domain/Connections/Ducks/Onboarding.duck';
import * as Onboard from 'Domain/Connections/Ducks/Onboard.duck';
import * as Organizations from 'Domain/Organizations/Ducks/Organizations.duck';
import * as CredentialRequests from 'Domain/Credentials/Ducks/CredentialRequests.duck';
import * as Account from 'Domain/Account/Ducks/Account.duck';

const appReducer = combineReducers({
  AppReducer: App.reducer,
  UsersReducer: Users.reducer,
  OrganizationsReducer: Organizations.reducer,
  ConnectionReducer: Connection.reducer,
  ConnectionsReducer: Connections.reducer,
  LogEntriesReducer: LogEntries.reducer,
  TasksReducer: Tasks.reducer,
  SyncStatusReducer: SyncStatus.reducer,
  OnboardingReducer: Onboarding.reducer,
  EventsReducer: Events.reducer,
  ProcessesReducer: Processes.reducer,
  CredentialsReducer: Credentials.reducer,
  OnboardReducer: Onboard.reducer,
  ModulesReducer: Modules.reducer,
  CredentialRequestsReducer: CredentialRequests.reducer,
  AccountReducer: Account.reducer,
});

export const rootReducer = (state, action) => appReducer(state, action);

export function* rootSaga() {
  yield all([
    Credentials.saga(),
    Onboard.saga(),
    CredentialRequests.saga(),
    Connections.saga(),
    Connection.saga(),
    LogEntries.saga(),
    Tasks.saga(),
    SyncStatus.saga(),
    Events.saga(),
    Processes.saga(),
    Onboarding.saga(),
    Users.saga(),
    Organizations.saga(),
    Modules.saga(),
    Account.saga(),
  ]);
}
