import axios from 'axios';

export const login = async (values, recaptchaToken) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.post(
    '/auth/login',
    { ...values },
    {
      headers: {
        'X-Recaptcha-Token': recaptchaToken,
      },
    },
  );
};

export const verifyToken = async (token, values) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.post(
    `/auth/two-factor-check`,
    { ...values },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getUserWithToken = async (token, userId) =>
  axios.get(`/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default { login, getUserWithToken };
