import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { isEmpty, startCase } from 'lodash';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormikTextField from '../../../../components/FormikTextField';

const UpdateCredentialForm = ({ initialValues, details, selectedPackageType }) => {
  const gridItems = () =>
    selectedPackageType && !isEmpty(details) ? (
      <Grid item xs={12}>
        {details.map(field => (
          <Grid key={field.fieldName} item xs={12}>
            <FormikTextField
              label={<FormattedMessage id={`onboarding.credentials.new.${field.fieldName}`} />}
              name={field.fieldName}
              fieldType={field.fieldType}
              fieldOptions={field.fieldOptions}
              update
            />
          </Grid>
        ))}
      </Grid>
    ) : (
      ''
    );
  return (
    <Grid container>
      <Grid item xs={12}>
        <Field
          disabled
          color="secondary"
          component={TextField}
          select
          name="organizationName"
          label={<FormattedMessage id="credentials.organization" />}
          fullWidth
        >
          <MenuItem key={initialValues.organizationName} value={initialValues.organizationName}>
            {initialValues.organizationName}
          </MenuItem>
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field
          disabled
          color="secondary"
          component={TextField}
          select
          name="packageType"
          label={<FormattedMessage id="credentials.packageType" />}
          fullWidth
        >
          <MenuItem key={initialValues.packageType} value={initialValues.packageType}>
            {startCase(initialValues.packageType.replace('-', ' '))}
          </MenuItem>
        </Field>
      </Grid>
      {gridItems()}
    </Grid>
  );
};

UpdateCredentialForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  details: PropTypes.array.isRequired,
  selectedPackageType: PropTypes.object.isRequired,
};

export default UpdateCredentialForm;
