import { takeLatest } from 'redux-saga/effects';
import { deleteAccount, updatePassword, updateProfile } from '../Services/AccountService';

export const actionTypes = {
  UpdateAccount: '[Account] Update account',
  DeleteAccount: '[Account] delete account',
  UpdatePassword: '[Account] Update password',
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UpdateAccount: {
      return {
        ...state,
      };
    }
    case actionTypes.DeleteAccount: {
      return {
        ...state,
      };
    }
    case actionTypes.UpdatePassword: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  updateAccount: userValues => ({
    type: actionTypes.UpdateAccount,
    userValues,
  }),
  deleteAccount: userId => ({
    type: actionTypes.DeleteAccount,
    userId,
  }),
  updatePassword: userValues => ({
    type: actionTypes.UpdatePassword,
    userValues,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.UpdateAccount, function* updateAccountSaga(action) {
    yield updateProfile(action.userValues);
  });

  yield takeLatest(actionTypes.DeleteAccount, function* deleteAccountSaga(action) {
    yield deleteAccount(action.userId);
  });

  yield takeLatest(actionTypes.UpdatePassword, function* updatePasswordSaga(action) {
    yield updatePassword(action.userValues);
  });
}
