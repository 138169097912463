import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { isEmpty, remove } from 'lodash';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  FormHelperText,
  Checkbox,
  Button,
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const UsersTable = props => {
  const {
    className,
    users,
    isUserSuperAdmin,
    handlePageChange,
    handleRowsPerPageChange,
    handleToggleAllUsers,
    handleToggleUser,
    page,
    rowsPerPage,
    totalCount,
    loading,
    handleClickChange,
    handleClickDelete,
    handleClickRemoveAccess,
    handleClickActivate,
    handleClickDeactivate,
    ...rest
  } = props;

  const classes = useStyles();

  const handleOrganizations = organizations => {
    const organizationMap = organizations.map(access => access.organization.organizationName);
    remove(organizationMap, function(n) {
      return n === '[DELETED]';
    });

    return organizations.map(access => (<li>{access.organization.organizationName}</li>));
  };

  const checkOrganizations = user => {
    let check = true;
    user.accessToOrganizations.forEach(org => {
      if (org.organization.organizationName !== '[DELETED]') {
        check = false;
      }
    });
    return check;
  };

  const renderActivationButtons = user => {
    if (user.active) {
      return (
        <TableCell>
          <Button color="primary" variant="contained" onClick={() => handleClickDeactivate(user)}>
            <FormattedMessage id="button.deactivate" />
          </Button>
        </TableCell>
      );
    }
    return (
      <TableCell>
        <Button color="primary" variant="contained" onClick={() => handleClickActivate(user)}>
          <FormattedMessage id="button.activate" />
        </Button>
      </TableCell>
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormHelperText>
                      <FormattedMessage id="users.selectAll" />
                    </FormHelperText>
                    <Checkbox
                      onClick={() => handleToggleAllUsers(users)}
                      checked={users.filter(item => item.selectedRow).length === users.length && users.length !== 0}
                      indeterminate={
                        users.filter(item => item.selectedRow).length !== users.length &&
                        users.filter(item => item.selectedRow).length !== 0
                      }
                      disabled={users.length === 0}
                      inputProps={{ 'aria-label': 'all items selected' }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="users.name" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="users.email" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="users.roles" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="users.accessToOrganizations" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="users.createdAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="button.change" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="button.activate" />
                  </TableCell>
                  {isUserSuperAdmin && (
                    <TableCell>
                      <FormattedMessage id="button.delete" />
                    </TableCell>
                  )}
                  <TableCell>
                    <FormattedMessage id="button.removeAccess" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow className={classes.tableRow} key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        {isUserSuperAdmin && (
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        )}
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  users.map(user => (
                    <TableRow className={classes.tableRow} hover key={user.userId}>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={!!user.selectedRow}
                          onChange={() => handleToggleUser(user)}
                        />
                      </TableCell>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar className={classes.avatar}>{getInitials(`${user.personName.firstName} ${user.personName.middleName} ${user.personName.lastName}`)}</Avatar>
                          <Typography variant="body1">{`${user.personName.firstName} ${user.personName.middleName} ${user.personName.lastName}`}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{user.emailAddress}</TableCell>
                      <TableCell>
                        {user.roles.map((role, index) => (
                          <span key={role}>
                            <FormattedMessage id={`users.roles.${role}`} />
                            {user.roles.length > index + 1 && ', '}
                          </span>
                        ))}
                      </TableCell>
                      <TableCell>{handleOrganizations(user.accessToOrganizations)}</TableCell>
                      <TableCell>{moment(user.createdAt).format('L')}</TableCell>
                      <TableCell>
                        <Button color="primary" variant="contained" onClick={() => handleClickChange(user)}>
                          <FormattedMessage id="button.change" />
                        </Button>
                      </TableCell>
                      {renderActivationButtons(user)}
                      {isUserSuperAdmin && (
                        <TableCell>
                          <Button color="primary" variant="contained" onClick={() => handleClickDelete(user)}>
                            <FormattedMessage id="button.delete" />
                          </Button>
                        </TableCell>
                      )}
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={isEmpty(user.accessToOrganizations) || checkOrganizations(user)}
                          onClick={() => handleClickRemoveAccess(user)}
                        >
                          <FormattedMessage id="button.removeAccess" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          showFirstButton
          showLastButton
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default UsersTable;
