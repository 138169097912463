import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, Authentication as AuthenticationLayout } from './layouts';

// eslint-disable-next-line no-unused-vars
import { Dashboard as DashboardView, SignIn as SignInView, NotFound as NotFoundView } from './views';

import UserPages from './Domain/Users/Pages';
import OrganizationPages from './Domain/Organizations/Pages';
import CredentialsPages from './Domain/Credentials/Pages';
import ConnectionsPages from './Domain/Connections/Pages';
import AccountPages from './Domain/Account/Pages';
import ResetPasswordPages from './Domain/ResetPassword/Pages';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/connection" />
      <RouteWithLayout
        component={() => {
          window.location.assign(
            `https://dashboard.databrydge.com${window.location.pathname}${window.location.search}`,
          );
          return null;
        }}
        layout={MainLayout}
        path="/authenticate/redirect"
      />
      <RouteWithLayout secure component={UserPages} layout={MainLayout} path="/users" />
      <RouteWithLayout secure component={OrganizationPages} layout={MainLayout} path="/organization" />
      <RouteWithLayout secure component={CredentialsPages} layout={MainLayout} path="/credential" />
      <RouteWithLayout secure component={ConnectionsPages} layout={MainLayout} path="/connection" />
      <RouteWithLayout secure component={AccountPages} exact layout={MainLayout} path="/account" />

      {/* <RouteWithLayout secure component={DashboardView} exact layout={MainLayout} path="/dashboard" /> */}

      {/* <RouteWithLayout secure component={ProductListView} exact layout={MainLayout} path="/products" /> */}
      {/* <RouteWithLayout secure component={TypographyView} exact layout={MainLayout} path="/typography" /> */}
      {/* <RouteWithLayout secure component={IconsView} exact layout={MainLayout} path="/icons" /> */}
      {/* <RouteWithLayout secure component={AccountView} exact layout={MainLayout} path="/account" /> */}
      {/* <RouteWithLayout secure component={SettingsView} exact layout={MainLayout} path="/settings" /> */}
      {/* <RouteWithLayout component={SignUpView} exact layout={MinimalLayout} path="/sign-up" /> */}
      <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path="/sign-in" />
      <RouteWithLayout component={ResetPasswordPages} layout={AuthenticationLayout} path="/reset-password" />
      <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
