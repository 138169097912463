import PropTypes from 'prop-types';

const WizardStep = ({ children }) => {
  return children;
};

export default WizardStep;

WizardStep.propTypes = {
  label: PropTypes.string.isRequired,
  stepperKey: PropTypes.string.isRequired,
};
