import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Button,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { APPROVAL_NEEDED_PROCESS_RESULTS, FAILED_PROCESS_RESULTS } from '../../Pages/ProcessesPage/constants';
import { ConfirmDialog } from '../../../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  buttonsWrapper: {
    display: 'flex',
  },
}));

const ProcessesTable = props => {
  const {
    className,
    connectionId,
    processes,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    handleClickRetry,
    handleClickApprove,
    handleClickDecline,
    handleClickDownload,
    handleErrorMessagesClick,
    handleEventsClick,
    ...rest
  } = props;

  const classes = useStyles();

  const getTranslatedResultId = result => {
    return `connection.processes.result.${result}`;
  };

  const [confirmApproveOpen, setConfirmApproveOpen] = useState(false);
  const [selectedApproveProcess, setSelectedApproveProcess] = useState(null);
  const [confirmDeclineOpen, setConfirmDeclineOpen] = useState(false);
  const [selectedDeclineProcess, setSelectedDeclineProcess] = useState(null);

  const handleApprove = process => {
    setConfirmApproveOpen(true);
    setSelectedApproveProcess(process);
  };

  const approveProcess = () => {
    handleClickApprove(selectedApproveProcess);
  };

  const handleDecline = process => {
    setConfirmDeclineOpen(true);
    setSelectedDeclineProcess(process);
  };

  const declineProcess = () => {
    handleClickDecline(selectedDeclineProcess);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="connection.processes.triggeredAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.processes.finishedAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.processes.triggeredBy" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.processes.eventsNumber" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.processes.errorMessagesNumber" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.processes.result" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.processes.button.retry" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.processes.button.actions" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.processes.button.download" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow className={classes.tableRow} key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  processes.map(process => (
                    <TableRow className={classes.tableRow} hover key={process.batchId}>
                      <TableCell>{moment(process.triggeredAt).format('L')}</TableCell>
                      <TableCell>{moment(process.finishedAt).format('L')}</TableCell>
                      <TableCell>{process.triggeredBy}</TableCell>
                      <TableCell>
                        {process.events.length > 0 ? (
                          <Button color="primary" onClick={() => handleEventsClick(process['@id'])}>
                            {process.events.length}
                          </Button>
                        ) : (
                          process.events.length
                        )}
                      </TableCell>
                      <TableCell>
                        {process.messages.length > 0 ? (
                          <Button color="primary" onClick={() => handleErrorMessagesClick(process['@id'])}>
                            {process.messages.length}
                          </Button>
                        ) : (
                          process.messages.length
                        )}
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id={getTranslatedResultId(process.batchStatus)} />
                      </TableCell>
                      <TableCell>
                        {FAILED_PROCESS_RESULTS.includes(process.batchStatus) && (
                          <Button color="primary" variant="contained" onClick={() => handleClickRetry(process)}>
                            <FormattedMessage id="connection.processes.button.retry" />
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        <Box className={classes.buttonsWrapper}>
                          {APPROVAL_NEEDED_PROCESS_RESULTS.includes(process.batchStatus) && (
                            <>
                              <Box p={0.5}>
                                <Button color="primary" variant="contained" onClick={() => handleApprove(process)}>
                                  <FormattedMessage id="connection.processes.button.approve" />
                                </Button>
                              </Box>
                              <Box p={0.5}>
                                <Button color="primary" variant="contained" onClick={() => handleDecline(process)}>
                                  <FormattedMessage id="connection.processes.button.decline" />
                                </Button>
                              </Box>
                            </>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {APPROVAL_NEEDED_PROCESS_RESULTS.includes(process.batchStatus) && (
                          <Button color="primary" variant="contained" onClick={() => handleClickDownload(process)}>
                            <FormattedMessage id="connection.processes.button.download" />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          showFirstButton
          showLastButton
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </CardActions>

      <ConfirmDialog
        title={<FormattedMessage id="connection.processes.approveConfirmationTitle" />}
        open={confirmApproveOpen}
        setOpen={setConfirmApproveOpen}
        onConfirm={approveProcess}
      >
        <FormattedMessage id="connection.processes.approveConfirmationMessage" />
      </ConfirmDialog>
      <ConfirmDialog
        title={<FormattedMessage id="connection.processes.declineConfirmationTitle" />}
        open={confirmDeclineOpen}
        setOpen={setConfirmDeclineOpen}
        onConfirm={declineProcess}
      >
        <FormattedMessage id="connection.processes.declineConfirmationMessage" />
      </ConfirmDialog>
    </Card>
  );
};

ProcessesTable.propTypes = {
  className: PropTypes.string,
  processes: PropTypes.array.isRequired,
};

export default ProcessesTable;
