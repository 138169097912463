import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getInitials } from '../../../../../../helpers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = props => {
  const { className, ...rest } = props;
  const { user } = useSelector(state => state.AppReducer);

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar className={classes.avatar}>{getInitials(`${user.personName.firstName} ${user.personName.middleName} ${user.personName.lastName}`)}</Avatar>
      <Typography className={classes.name} variant="h4">
        {`${user.personName.firstName} ${user.personName.middleName} ${user.personName.lastName}`}
      </Typography>
      {user.accessToOrganizations.map(access =>
        access.organization.organizationName === '[DELETED]' ? (
          ''
        ) : (
          <Typography key={access.organization.organizationId} variant="body2">
            {access.organization.organizationName}
          </Typography>
        ),
      )}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
