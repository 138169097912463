import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Alert from '@material-ui/lab/Alert';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import { verifyResetPassword } from '../../Services/ResetPasswordService';
import { normalizeErrors } from '../../../../utils/dataAccess';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(3),
  },
  message: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
}));

const VerifyResetPasswordPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [requestPasswordResetSucceed, setRequestPasswordResetSucceed] = useState(null);

  const validation = Yup.object().shape({
    token: Yup.string().required(),
    newPassword: Yup.string().required(),
    newPasswordConfirmation: Yup.string()
      .required()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const handleClickVerifyRequestPassword = async (values, form) => {
    setRequestPasswordResetSucceed(null);
    setErrorMessage(null);

    try {
      await verifyResetPassword(values);

      setRequestPasswordResetSucceed(true);

      await new Promise(resolve => setTimeout(resolve, 15000));

      history.push('/sign-in');
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }

      if (e.response && e.response.status === 400 && e.response.data && e.response.data.message) {
        setErrorMessage(e.response.data.message);

        return;
      }

      throw e;
    }
  };

  return (
    <Formik
      initialValues={{ token, newPassword: '', newPasswordConfirmation: '' }}
      validationSchema={validation}
      onSubmit={handleClickVerifyRequestPassword}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Typography className={classes.title} variant="h2">
            <FormattedMessage id="resetPassword.title" />
          </Typography>
          {errorMessage && (
            <Alert severity="error" className={classes.message}>
              {errorMessage}
            </Alert>
          )}
          {requestPasswordResetSucceed && (
            <Alert severity="success" className={classes.message}>
              <FormattedMessage id="resetPassword.verifyResetPasswordSucceedMessage" />
            </Alert>
          )}
          <Field
            className={classes.textField}
            required
            type="password"
            color="secondary"
            component={TextField}
            name="newPassword"
            variant="outlined"
            label={<FormattedMessage id="resetPassword.newPassword" />}
            fullWidth
          />
          <Field
            className={classes.textField}
            required
            type="password"
            color="secondary"
            component={TextField}
            name="newPasswordConfirmation"
            variant="outlined"
            label={<FormattedMessage id="resetPassword.newPasswordConfirmation" />}
            fullWidth
          />
          <Button
            className={classes.button}
            color="primary"
            disabled={!isValid || isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            <FormattedMessage id="resetPassword.verifyResetPasswordButton" />
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default VerifyResetPasswordPage;
