import axios from 'axios';

let getRunReportsToken;
export const getRunReports = async (connectionId, page, perPage) => {
  try {
    if (typeof getRunReportsToken !== typeof undefined) {
      getRunReportsToken.cancel('Operation canceled due to new request.');
    }

    getRunReportsToken = axios.CancelToken.source();

    return axios.get(`/connections/${connectionId}/payment-toggle/run-report`, {
      cancelToken: getRunReportsToken.token,
      params: {
        page,
        perPage,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const startInitialRun = async connectionId => {
  return axios.post(`/connections/${connectionId}/payment-toggle/initial-run/start`, {
    connectionId,
  });
};

export const reverseInitialRun = async (connectionId, initialRunId) => {
  return axios.post(`/connections/${connectionId}/payment-toggle/reversal-run/start`, {
    connectionId,
    initialRunId,
  });
};

export const downloadInitialRunReport = async (connectionId, initialRunId) => {
  return axios.get(`/payment-toggle/${connectionId}/initial-run/download/${initialRunId}`);
};

export const downloadReversalRunReport = async (connectionId, reversalRunId) => {
  return axios.get(`/payment-toggle/${connectionId}/reversal-run/download/${reversalRunId}`);
};

export default {
  getRunReports,
  startInitialRun,
  reverseInitialRun,
  downloadInitialRun: downloadInitialRunReport,
  downloadReversalRunReport,
};
