import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const ToastComponent = props => {
  const { action, deleteMessage } = props;
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');

  const formattedMessage =
    action !== undefined && action.message !== undefined ? (
      <FormattedMessage id={`${action.message}.${severity}`} />
    ) : (
      <FormattedMessage id={`${severity}-default`} />
    );

  // eslint-disable-next-line
  useEffect(() => {
    if (action !== undefined) {
      setOpen(true);
      setSeverity(action.severity);
      toast(formattedMessage, {
        toastId: 'never-duplicate',
        onClose: () => deleteMessage(),
        type: action.severity ?? 'info',
      });
    }
  });

  return null;
};

export default ToastComponent;
