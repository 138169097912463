import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
} from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import CredentialRequestRedirectUrlGeneratorService from '../../Services/CredentialRequestRedirectUrlGeneratorService';
import getCredentialRequestRedirectUrl from '../../Services/CredentialRequestRedirectUrlGeneratorService';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const CredentialsTable = props => {
  const {
    className,
    credentials,
    handleShowDetails,
    handlePageChange,
    handleRowsPerPageChange,
    handleClickChange,
    handleDelete,
    page,
    rowsPerPage,
    totalCount,
    loading,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="credentials.name" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentials.organization" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentials.type" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentials.packageType" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentials.expires" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentials.createdAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentials.connect" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentials.connections" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="button.change" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="button.delete" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow className={classes.tableRow} key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  credentials.map(credential => (
                    <TableRow className={classes.tableRow} hover key={credential.credentialId}>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Typography variant="body1">{credential.name}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{credential.organization.organizationName}</TableCell>
                      <TableCell>{credential['@type']}</TableCell>
                      <TableCell>
                        <FormattedMessage id={`packages.type.${credential.packageType}`} />
                      </TableCell>
                      <TableCell>
                        {credential.accessTokenExpires && moment(credential.accessTokenExpires).fromNow()}
                      </TableCell>
                      <TableCell>{moment(credential.createdAt).format('L')}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          href={CredentialRequestRedirectUrlGeneratorService(credential.credentialRequest)}
                          target="_blank"
                          rel="noopener noreferrer"
                          disabled={!credential.credentialRequest}
                        >
                          <FormattedMessage id="button.connect" />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.buttonElement}
                          onClick={() => handleShowDetails(credential.credentialId)}
                        >
                          <FormattedMessage id="credentials.connections.show" />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleClickChange(credential)}
                          disabled={false}
                        >
                          <FormattedMessage id="button.change" />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleDelete(credential)}
                          disabled={false}
                        >
                          <FormattedMessage id="button.delete" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          showFirstButton
          showLastButton
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

CredentialsTable.propTypes = {
  className: PropTypes.string,
  credentials: PropTypes.array.isRequired,
};

export default CredentialsTable;
