import axios from 'axios';
import { merge } from 'lodash';

let getEventsToken;
export const getEvents = async (connectionId, page, perPage, sortBy, filters, searchQuery, useToken: true) => {
  try {
    if (useToken && typeof getEventsToken !== typeof undefined) {
      getEventsToken.cancel('Operation canceled due to new request.');
    }

    if (useToken) {
      getEventsToken = axios.CancelToken.source();
    }

    return axios.get(`/${connectionId}/data/insurance-brydge/events`, {
      cancelToken: useToken ? getEventsToken.token : null,
      params: merge(
        {
          page,
          perPage,
          sortBy,
          employee: searchQuery,
        },
        filters,
      ),
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const processEvents = async (connectionId, eventIds, filters, searchQuery, selectAll) => {
  const newFilters = searchQuery && searchQuery.length > 0 ? merge({ employee: searchQuery }, filters) : filters;
  try {
    return axios.post(`/connection/${connectionId}/module/run`, {
      connectionId,
      moduleType: 'insurance_brydge_trigger_processing_of_batch_of_events_module',
      parameters: {
        eventIds,
        archiveOtherEvents: false,
        filters: newFilters,
        selectAll,
      },
    });
  } catch (err) {
    throw err;
  }
};

export const archiveEvents = async (connectionId, eventIds, filters, searchQuery, selectAll) => {
  try {
    return axios.post(`/connection/${connectionId}/module/run`, {
      connectionId,
      moduleType: 'insurance_brydge_trigger_processing_of_batch_of_events_module',
      parameters: {
        archiveEventIds: eventIds,
        archiveOtherEvents: false,
        filters: merge(
          {
            employee: searchQuery,
          },
          filters,
        ),
        selectAll,
      },
    });
  } catch (err) {
    throw err;
  }
};

export const unArchiveEvents = async (connectionId, eventIds, filters, searchQuery, selectAll) => {
  try {
    return axios.post(`/connection/${connectionId}/module/run`, {
      connectionId,
      moduleType: 'insurance_brydge_trigger_processing_of_batch_of_events_module',
      parameters: {
        unarchiveEventIds: eventIds,
        archiveOtherEvents: false,
        filters: merge(
          {
            employee: searchQuery,
          },
          filters,
        ),
        selectAll,
      },
    });
  } catch (err) {
    throw err;
  }
};

export default { getEvents, archiveEvents };
