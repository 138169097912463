import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import EventContentTable from '../EventContentTable';

const ShowEventDetailsDialog = ({ event, onClose }) => {
  return (
    <Dialog onClose={onClose} open="true" fullWidth maxWidth="lg">
      <DialogTitle>{event['@type']}</DialogTitle>
      <DialogContent>
        <EventContentTable event={event} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShowEventDetailsDialog.propTypes = {
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShowEventDetailsDialog;
