export const PROCESS_RESULTS = [
  'pending',
  'awaiting-approval',
  'approved',
  'declined',
  'processing',
  'complete',
  'failed',
];

export const FAILED_PROCESS_RESULTS = ['failed'];
export const APPROVAL_NEEDED_PROCESS_RESULTS = ['awaiting-approval'];
