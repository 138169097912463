import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Alert from '@material-ui/lab/Alert';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { requestResetPassword } from '../../Services/ResetPasswordService';
import { normalizeErrors } from '../../../../utils/dataAccess';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(3),
  },
  message: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
}));

const RequestResetPasswordPage = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(null);
  const [requestPasswordResetSucceed, setRequestPasswordResetSucceed] = useState(null);

  const validation = Yup.object().shape({
    emailAddress: Yup.string()
      .email()
      .required(),
  });

  const handleClickRequestPassword = async (values, form) => {
    setErrorMessage(null);
    setRequestPasswordResetSucceed(null);

    try {
      await requestResetPassword(values);

      setRequestPasswordResetSucceed(true);
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);

        return;
      }

      if (e.response && e.response.status === 400 && e.response.data && e.response.data.message) {
        setErrorMessage(e.response.data.message);

        return;
      }

      throw e;
    }
  };

  return (
    <Formik initialValues={{ emailAddress: '' }} validationSchema={validation} onSubmit={handleClickRequestPassword}>
      {({ isValid, isSubmitting }) => (
        <Form>
          <Typography className={classes.title} variant="h2">
            <FormattedMessage id="resetPassword.title" />
          </Typography>
          {errorMessage && (
            <Alert severity="error" className={classes.message}>
              {errorMessage}
            </Alert>
          )}
          {requestPasswordResetSucceed && (
            <Alert severity="success" className={classes.message}>
              <FormattedMessage id="resetPassword.requestResetPasswordSucceedMessage" />
            </Alert>
          )}
          <Field
            className={classes.textField}
            required
            type="email"
            color="secondary"
            component={TextField}
            name="emailAddress"
            variant="outlined"
            label={<FormattedMessage id="resetPassword.emailAddress" />}
            fullWidth
          />
          <Button
            className={classes.button}
            color="primary"
            disabled={!isValid || isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            <FormattedMessage id="resetPassword.requestResetPasswordButton" />
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RequestResetPasswordPage;
