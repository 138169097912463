export const EVENT_TYPES = [
  'NewEmployeeEvent',
  'ContractUpdatedEvent',
  'EmployeeAddressUpdatedEvent',
  'EmployeeInformationUpdatedEvent',
  'EmployeePartTimePercentageUpdatedEvent',
  'EmployeePartnerUpdatedEvent',
  'GrossWageUpdatedEvent',
  'EmployeeLeftCompanyEvent',
  'NewPeriodicWageEvent',
  'ReEmployedEvent'
];
