import React, { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { NavLink as RouterLink, useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { CurlCollection } from '../../Components';
import { actions as ConnectionActions } from '../../Ducks/Connection.duck';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  syncElement: {
    marginTop: theme.spacing(2),
  },
  buttonElement: {
    marginRight: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={index} {...other}>
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ConnectionCurlPage = () => {
  const classes = useStyles();
  const { connectionId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading: loadingConnection, item: connection } = useSelector(state => state.ConnectionReducer);
  const { user } = useSelector(state => state.AppReducer);

  useEffect(() => {
    if (connection !== undefined && Object.keys(connection).length === 0 && !loadingConnection) {
      history.push('/not-found');
    }
  }, [loadingConnection, connection]);

  useEffect(() => {
    if (!user.roles.includes('ROLE_SUPER_ADMIN')) {
      history.push('/not-found');
    }
  }, [user]);

  useEffect(() => {
    dispatch(ConnectionActions.requestData(connectionId));
  }, [dispatch]);

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ display: 'inline-block' }}>
      <RouterLink {...props} />
    </div>
  ));

  return (
    <div className={classes.root}>
      {!loadingConnection && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">{!loadingConnection && connection.connectionName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonElement}
              component={CustomRouterLink}
              to={`/connection/${connectionId}`}
            >
              <FormattedMessage id="button.overview" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange}>
                  <Tab label="all curls" />
                  <Tab label="failed curls" />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <CurlCollection connectionId={connectionId} defaultCurlStatus="all" />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CurlCollection connectionId={connectionId} defaultCurlStatus="failed" />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ConnectionCurlPage;
